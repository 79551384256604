import React from 'react'
import {
  styled,
  fontSize,
  color,
  borderColor,
  paddingBottom,
  paddingTop,
  gridColumnGap
} from '@fv/design-tokens'
import { NavLink, withRouter } from 'react-router-dom'

export const PathNavLink = props => (
  <NavLink
    isActive={(_, { pathname, search }) => pathname + search === props.to}
    {...props}
  />
)

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  ${gridColumnGap('s')};
`
const StyledNavLink = styled(PathNavLink)`
  ${fontSize('m')};
  ${color('text')};
  ${paddingTop('s')};
  ${paddingBottom('s')};
  border-bottom: 4px solid transparent;

  &.active {
    ${borderColor('highlightBackground')};
  }
`

const HorizontalNavigation = ({ match: { url }, location: { search } }) => (
  <Container>
    {/* <StyledNavLink replace to={`${url}/highlights${search}`}>
      Highlights
    </StyledNavLink> */}
    <StyledNavLink replace to={`${url}/transcript${search}`}>
      Transcript
    </StyledNavLink>
    <StyledNavLink replace to={`${url}/marks${search}`}>
      Marks
    </StyledNavLink>
  </Container>
)

export default withRouter(HorizontalNavigation)
