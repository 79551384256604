import gql from 'graphql-tag'

export const ReelCard_reel = gql`
  fragment ReelCard_reel on VIReels {
    id
    name
    thumbnailUrl
    publishedToPresentationFl
    clipCount
    updateDt
  }
`
