import React, { Component, createContext, createRef } from 'react'

const PLAYER_SEEK_AND_PLAY_MAX_RETRY = 50

const MultiVideoControllerContext = createContext({})
const { Consumer, Provider } = MultiVideoControllerContext

const formatVideoSource = videoUrl => [{ type: 'video/mp4', src: videoUrl }]

const initialSeekAndPlayControl = {
  interval: null,
  retryCount: 0,
  loaded: false
}

class MultiVideoControllerProvider extends Component {
  seekAndPlayControl = initialSeekAndPlayControl

  resetSeekAndPlayControl = () => {
    if (this.seekAndPlayControl.interval) {
      clearInterval(this.seekAndPlayControl.interval)
    }
    this.seekAndPlayControl = initialSeekAndPlayControl
  }

  state = {
    loading: true,
    playing: false,
    ended: false,
    currentTime: 0,
    duration: 0,
    playingClipIndex: -1,
    playlist: []
  }

  constructor(props) {
    super(props)
    this.videoPlayerRef = createRef()
  }

  // VideoJs Event Handlers
  // We trigger a state change on each trigger
  // This could be expensive for the handleTimeUpdate
  // We should refactor with subscriber pattern if we notice
  // performance issues

  handleOnEnded = () =>
    this.setState({
      ended: true,
      playing: false
    })

  handleOnLoadedMetaData = () =>
    this.setState({
      loading: false,
      duration: this.getDuration()
    })

  handleOnPause = () => this.setState({ playing: false })
  handleOnPlay = () => this.setState({ playing: true, loading: false })

  handleTimeUpdate = () => {
    const { playlist, playingClipIndex } = this.state
    const currentTime = this.getCurrentTime()

    if (!playlist.length) {
      return this.setState({ currentTime })
    }

    if (this.insideOfCurrentClip(currentTime)) {
      return this.setState({ currentTime })
    }

    if (this.justFinishedCurrentClip(currentTime)) {
      const nextClipIndex = playingClipIndex + 1
      const nextClip = playlist[nextClipIndex]

      if (nextClip) {
        // Go to next clip
        this.setState(
          {
            playingClipIndex: nextClipIndex,
            currentTime,
            currentVideoSource: formatVideoSource(nextClip.assetUrl),
            loading: true
          },
          () => this.seekAndPlay(nextClip.startTime)
        )
        return
      }

      // We have reached the end of the playlist
      this.pause()
      return this.setState({
        playingClipIndex: -1,
        currentTime
      })
    }

    // User has seeked video
    if (this.outsideOfCurrentClip(currentTime)) {
      // User has seeked outside of a clip
      return this.setState({
        playingClipIndex: -1,
        currentTime
      })
    }

    this.setState({ currentTime })
  }

  getCurrentClip = () => {
    const { playlist, playingClipIndex } = this.state
    return playlist[playingClipIndex]
  }

  insideOfCurrentClip = currentTime => {
    const currentClip = this.getCurrentClip()
    if (!currentClip) {
      return false
    }

    const { startTime, endTime } = currentClip
    return currentTime >= startTime && currentTime <= endTime
  }

  outsideOfCurrentClip = currentTime => !this.insideOfCurrentClip(currentTime)

  justFinishedCurrentClip = currentTime => {
    const currentClip = this.getCurrentClip()
    if (!currentClip) {
      return false
    }

    const { endTime } = currentClip
    return currentTime > endTime && currentTime - endTime < 0.3
  }

  // Player actions

  play = () => this.videoPlayerRef.current.play()

  pause = () => {
    this.setState({ playing: false })
    this.videoPlayerRef.current.pause()
  }

  seek = time => this.videoPlayerRef.current.setTime(time)

  seekAndPlay = time => {
    if (this.seekAndPlayControl.interval) {
      this.resetSeekAndPlayControl()
    }
    this.seek(time)
    this.videoPlayerRef.current.player.muted(true)
    this.seekAndPlayControl = {
      ...this.seekAndPlayControl,
      volume: this.videoPlayerRef.current.player.volume()
    }

    this.seekAndPlayControl.interval = setInterval(() => {
      if (this.seekAndPlayControl.retryCount > PLAYER_SEEK_AND_PLAY_MAX_RETRY) {
        this.resetSeekAndPlayControl()
      }
      this.seekAndPlayControl = {
        ...this.seekAndPlayControl,
        retryCount: this.seekAndPlayControl.retryCount++
      }
      if (
        this.videoPlayerRef.current.player.readyState() === 1 &&
        !this.seekAndPlayControl.loaded
      ) {
        this.videoPlayerRef.current.player.load()
        this.seekAndPlayControl = {
          ...this.seekAndPlayControl,
          loaded: true
        }
      } else if (this.videoPlayerRef.current.player.readyState() > 1) {
        this.videoPlayerRef.current.player.muted(false)
        this.videoPlayerRef.current.player.volume(
          this.seekAndPlayControl.volume
        )
        this.seek(time)
        this.play()
        this.resetSeekAndPlayControl()
      }
    }, 200)
  }

  openClips = (clips = [], autoplay = false) => {
    const [clip] = clips
    const { startTime, assetUrl } = clip

    if (typeof startTime !== 'undefined') {
      this.setState(
        {
          playlist: clips,
          playingClipIndex: 0,
          currentVideoSource: formatVideoSource(assetUrl),
          loading: true
        },
        () => autoplay && this.seekAndPlay(startTime)
      )
    }
  }

  playClips = (clips = []) => this.openClips(clips, true)

  // Helpers

  getDuration = () =>
    this.videoPlayerRef && this.videoPlayerRef.current.duration()
  getCurrentTime = () =>
    this.videoPlayerRef && this.videoPlayerRef.current.currentTime()

  // Wraps children with provider and passes context state

  render() {
    const { children } = this.props
    const {
      loading,
      playing,
      ended,
      currentTime,
      duration,
      playlist,
      playingClipIndex,
      currentVideoSource
    } = this.state
    const {
      handleLoadedMetaData,
      handleOnPause,
      handleOnPlay,
      handleOnEnded,
      handleTimeUpdate,
      videoPlayerRef,
      play,
      playClips,
      openClips,
      pause,
      seek,
      seekAndPlay,
      getCurrentClip
    } = this

    return (
      <Provider
        value={{
          handleLoadedMetaData,
          handleOnPause,
          handleOnPlay,
          handleOnEnded,
          handleTimeUpdate: () => !loading && handleTimeUpdate(),
          videoPlayerRef,
          loading,
          playing,
          ended,
          currentTime,
          duration,
          play,
          playClips,
          openClips,
          pause,
          seek,
          seekAndPlay,
          playlist,
          getCurrentClip,
          playingClipIndex,
          currentVideoSource
        }}
      >
        {children}
      </Provider>
    )
  }
}

export default MultiVideoControllerContext
export {
  Consumer as MultiVideoControllerConsumer,
  MultiVideoControllerProvider
}
