import React, { useContext, forwardRef } from 'react'
import { styled, background, color, borderColor } from '@fv/design-tokens'
import UtilityButton from './UtilityButton'
import ScissorsIcon from 'components/icons/Scissors'
import NewClipContext from 'components/AdvancedEditor/ReelBuilder/NewClipContext'
import withQueryParams from 'components/withQueryParams'

const StyledButton = styled(UtilityButton)`
  ${background('buttonPlayBackground')};
  ${borderColor('buttonPlayBorderColor')};
  ${color('buttonPlayText')};
  &:hover {
    ${background('buttonPlayBackgroundHover')};
    ${borderColor('buttonPlayBorderColorHover')};
    ${color('buttonPlayTextHover')};
  }
  &:active {
    ${background('buttonPlayBackgroundActive')};
    ${borderColor('buttonPlayBorderColorActive')};
    ${color('buttonPlayTextActive')};
  }
`

const CreateClipButton = forwardRef(
  ({ startTime, endTime, history, query, ...rest }, ref) => {
    const { setNewClip } = useContext(NewClipContext)

    const ensureReelBuilderVisible = () => {
      if (query.get('panel') !== 'showreelBuilder') {
        query.set('panel', 'showreelBuilder')
        history.replace({ search: query.toString() })
      }
    }

    return (
      <StyledButton
        ref={ref}
        onClick={() => {
          ensureReelBuilderVisible()
          setNewClip({
            startTime: parseInt(startTime, 10),
            endTime: parseInt(endTime, 10)
          })
        }}
        {...rest}
      >
        <ScissorsIcon />
      </StyledButton>
    )
  }
)

export default withQueryParams(CreateClipButton)
