import React from 'react'
import {
  styled,
  marginBottom,
  marginTop,
  fontWeight,
  padding
} from '@fv/design-tokens'
import Popover from 'components/Popover'
import PopoverDialog from 'components/PopoverDialog'
import Button from 'components/Button'
import InfoButton from 'components/InfoButton'

const Container = styled.div`
  ${marginTop('m')};
  ${marginBottom('m')};

  &:first-of-type {
    border: 0;
    padding-top: 0;
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const Header = styled.div`
  display: flex;
  align-content: center;
  ${marginBottom('xs')};
`

const Summary = styled.div`
  align-self: center;
  ${fontWeight('bold')};
`

const ClearButton = styled(Button)`
  align-self: center;
  font-size: inherit;
  margin-left: auto;
  ${padding('xxs')};
`

const AnalysisDetails = ({
  summary,
  popoverBody,
  children,
  clear,
  showClear
}) => (
  <Container>
    <Header>
      <Summary>{summary}</Summary>
      <Popover
        placement="bottom"
        modifiers={{
          preventOverflow: {
            boundariesElement: 'viewport'
          }
        }}
        render={({ hide }) => (
          <PopoverDialog
            hide={hide}
            popoverTitle={summary}
            popoverBody={popoverBody}
          />
        )}
      >
        {({ toggle, ref }) => (
          <InfoButton ghost square small ref={ref} onClick={toggle} />
        )}
      </Popover>
      {showClear && (
        <ClearButton small ghost onClick={clear}>
          clear
        </ClearButton>
      )}
    </Header>
    <div>{children}</div>
  </Container>
)

export default AnalysisDetails
