import React from 'react'
import ItemLink from './ItemLink'
import ItemAction from './ItemAction'
import { css, styled } from '@fv/design-tokens'

const ifDisabled = ({ disabled }) =>
  disabled &&
  css`
    cursor: not-allowed;
    opacity: 0.65;

    > * {
      pointer-events: none;
    }
  `

const DisableableListItem = styled.li`
  ${ifDisabled}
`

const Item = props => (
  <DisableableListItem disabled={props.disabled}>
    {props.to ? <ItemLink {...props} /> : <ItemAction {...props} />}
  </DisableableListItem>
)

Item.displayName = 'DropdownMenu.Item'

export default Item
