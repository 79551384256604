import gql from 'graphql-tag'
import { Mark_mark } from './Mark.fragments'

const VI_MARKS_QUERY = gql`
  query VIMarksQuery($responseId: String!) {
    marks(responseId: $responseId)
      @jsonapi(
        path: "/marks?filter[response]={args.responseId}&page[size]=50&sort=seconds_after_start"
        includeJsonapi: true
      ) {
      graphql {
        ... on VIMarks {
          id
          ...Mark_mark
        }
      }
      jsonapi {
        links {
          next
        }
      }
    }
  }

  ${Mark_mark}
`

export const VI_DYNAMIC_MARKS_QUERY = gql`
  query VIMarksQuery($path: String!) {
    marks(path: $path) @jsonapi(path: "{args.path}", includeJsonapi: true) {
      graphql {
        ... on VIMarks {
          id
          ...Mark_mark
        }
      }
      jsonapi {
        links {
          next
        }
      }
    }
  }

  ${Mark_mark}
`

export default VI_MARKS_QUERY
