import React from 'react'
import Loader from 'components/Loader'
import { styled, background } from '@fv/design-tokens'
import { flexCenterContent, flexFill } from 'styles/positioning'

const LoadingContainer = styled.div`
  ${flexFill};
  ${flexCenterContent};
  ${background('background')};
  height: 100vh;
`

const PageLoader = () => (
  <LoadingContainer data-testid="loading-container">
    <Loader />
  </LoadingContainer>
)

export default PageLoader
