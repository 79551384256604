import { styled } from '@fv/design-tokens'

const Container = styled.div`
  position: relative;
  width: ${({ width }) => width};
`

Container.displayName = 'Dropdown.Container'

Container.defaultProps = {
  width: 'auto'
}

export default Container
