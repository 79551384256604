import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Theme } from 'stylist'
import 'normalize.css'
import 'video.js/dist/video-js.css'
import 'styles/global.css'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-components'
import { ApolloProvider as ApolloHookProvider } from '@apollo/react-hooks'
import apolloClient from './config/apollo'
import './config/sentry'
import { DesignTokenProvider } from '@fv/design-tokens'

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <ApolloHookProvider client={apolloClient}>
        <DesignTokenProvider>
          <Theme>
            <App />
          </Theme>
        </DesignTokenProvider>
      </ApolloHookProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
