import gql from 'graphql-tag'

export const ReelVideoClip_reelVideoClip = gql`
  fragment ReelVideoClip_reelVideoClip on VIReelVideoClips {
    id
    name
    startTime
    endTime
    assetUrl
    videoLength
    content {
      id
      video {
        response {
          id
        }
      }
    }
  }
`
