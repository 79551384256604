import React from 'react'

const PauseSmIcon = ({ width = 14, height = 14, className }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" className={className}>
    <title>insights_icons</title>
    <rect
      x="8.25"
      y="3"
      width="3"
      height="7.95"
      transform="translate(19.5 13.95) rotate(180)"
    />
    <rect
      x="3.25"
      y="3.05"
      width="3"
      height="7.95"
      transform="translate(9.5 14.05) rotate(180)"
    />
  </svg>
)

export default PauseSmIcon
