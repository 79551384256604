import { compose, filter } from 'utils/fp'

export const transcriptToSentences = (transcript, timestamps) => {
  let wordsProcessed = 0
  return splitTranscriptToSentences(transcript).map(sentenceText => {
    const wordCount = sentenceText.split(/\s+/).length
    const sentence = {
      startTime: timestamps[wordsProcessed],
      endTime: timestamps[wordsProcessed + wordCount],
      text: sentenceText
    }
    wordsProcessed += wordCount
    return sentence
  })
}

const splitByNewlines = transcript => transcript.split(/\s*\n+\s*/)
const addNewlinesAfterSentences = transcript =>
  transcript.replace(/([.!?]"?)(\s+)/g, '$1\n')

export const splitTranscriptToSentences = compose(
  filter(sentence => sentence.length),
  splitByNewlines,
  addNewlinesAfterSentences
)

export const insightsItemsToSentences = items => {
  let sentences = []
  const reducer = (accumulator, currentValue) => {
    if (!currentValue.alternatives.length) {
      return accumulator
    }

    if (!accumulator.startTime) {
      accumulator.startTime = currentValue.startTime
    }

    if (accumulator.text && currentValue.type === 'pronunciation') {
      accumulator.text = accumulator.text + ' '
      accumulator.endTime = currentValue.endTime
    }

    accumulator.text = accumulator.text + currentValue.alternatives[0].content

    if (currentValue.alternatives[0].content.match(/[.?]/)) {
      if (!accumulator.endTime) {
        accumulator.endTime = accumulator.startTime
      }
      sentences.push({
        text: accumulator.text,
        startTime: accumulator.startTime,
        endTime: accumulator.endTime
      })
      return { text: '', startTime: '', endTime: '' }
    } else {
      return accumulator
    }
  }

  items.reduce(reducer, { text: '', startTime: '', endTime: '' })

  return sentences
}
