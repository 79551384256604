import React from 'react'
import { darkGrey } from 'styles/color'
import { paddingBottom, paddingRight, styled } from '@fv/design-tokens'
import { ChevronIcon } from 'stylist'

const BreadCrumbContainer = styled.div`
  align-items: center;
  display: flex;
  ${paddingBottom('xs')};
`

const BreadCrumbItem = styled.div`
  ${paddingRight('xs')};
  max-width: 228px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.navigate {
    cursor: pointer;
    color: ${darkGrey};
    max-width: 100px;
  }
`

const BreadCrumb = ({
  projectName = '',
  filteredByYourShowreels,
  onProjectsNav,
  onProjectNameNav
}) => {
  return (
    <BreadCrumbContainer>
      <BreadCrumbItem
        className={projectName && 'navigate'}
        onClick={onProjectsNav}
      >
        Projects
      </BreadCrumbItem>
      {projectName && (
        <>
          <BreadCrumbItem>
            <ChevronIcon color="grey" size="s" direction="right" />
          </BreadCrumbItem>
          <BreadCrumbItem
            className={filteredByYourShowreels && 'navigate'}
            onClick={onProjectNameNav}
          >
            {projectName}
          </BreadCrumbItem>
        </>
      )}
      {filteredByYourShowreels && (
        <>
          <BreadCrumbItem>
            <ChevronIcon color="grey" size="s" direction="right" />
          </BreadCrumbItem>
          <BreadCrumbItem>Your Showreels</BreadCrumbItem>
        </>
      )}
    </BreadCrumbContainer>
  )
}

export default BreadCrumb
