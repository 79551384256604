import { styled } from '@fv/design-tokens'
import { flexCenterContent } from 'styles/positioning'
import Loader from 'components/Loader'

const TabLoader = styled(Loader)`
  height: 100%;
  ${flexCenterContent};
`

export default TabLoader
