import React from 'react'

const NeutralIcon = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <title>neutral</title>
    <path d="M16 32c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zM16 3c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13zM8 10c0 1.105 0.895 2 2 2s2-0.895 2-2-0.895-2-2-2-2 0.895-2 2zM20 10c0 1.105 0.895 2 2 2s2-0.895 2-2-0.895-2-2-2-2 0.895-2 2zM12 22h8v2h-8v-2z" />
  </svg>
)

export default NeutralIcon
