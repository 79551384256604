import React from 'react'
import { styled, marginLeft, marginRight } from '@fv/design-tokens'
import CloseIcon from 'components/icons/Close'
import Button from 'components/Button'

const CloseButton = props => (
  <Button {...props} round square>
    <CloseIcon />
  </Button>
)

const Close = styled(CloseButton)`
  ${marginLeft('xxs')};
  ${marginRight('xxs')};
`

Close.displayName = 'Notification.CloseButton'

export default Close
