import React, { useContext } from 'react'
import moment from 'moment'
import {
  styled,
  css,
  background,
  borderRadius,
  color,
  fontSize,
  fontWeight,
  marginRight,
  marginBottom,
  padding
} from '@fv/design-tokens'
import useQueryParams from 'hooks/useQueryParams'
import { electricBlue } from 'styles/color'
import CurrentUserContext from 'components/CurrentUser/Context'
import Tag from 'components/Tag'

const selectedCss = ({ selected }) =>
  selected &&
  css`
    border: 1px solid ${electricBlue};
  `

const Container = styled.div`
  display: grid;
  grid-template-areas:
    'thumbnail title'
    'thumbnail lastModified'
    'thumbnail published';
  grid-template-columns: auto 1fr;
  cursor: pointer;
  ${background('background')};
  ${borderRadius('m')};
  ${marginBottom('s')};
  ${padding('s')};
  ${selectedCss};
`

const Thumbnail = styled.img`
  grid-area: thumbnail;
  width: 80px;
  height: 60px;
  ${background('backgroundShaded')};
  ${color('backgroundShaded')};
  ${borderRadius('s')};
  ${marginRight('s')};
`

const Title = styled.h1`
  grid-area: title;
  ${fontWeight('regular')};
  ${fontSize('m')};
  margin: 0;
  ${marginBottom('xs')};
  line-height: 1.25rem;
  overflow-wrap: break-word;
  overflow: hidden;
`

const LastModified = styled.p`
  grid-area: lastModified;
  ${fontSize('s')};
  ${color('buttonTextDisabled')};
  margin: 0;
  ${marginBottom('xs')};
  line-height: 1;
`

const Published = styled.div`
  grid-area: published;
`

const ReelCard = ({
  reel: { id, name, thumbnailUrl, publishedToPresentationFl, updateDt },
  reel,
  handleClick,
  selected
}) => {
  const { hasFeature } = useContext(CurrentUserContext)
  const [, setQueryParams] = useQueryParams()

  return (
    <Container
      selected={selected}
      onClick={() =>
        handleClick
          ? handleClick(reel)
          : setQueryParams({
              reel: id,
              reelBuilderPanel: undefined,
              reelsIndexTab: undefined
            })
      }
    >
      <Thumbnail src={thumbnailUrl} alt="Reel Thumbnail" />
      <Title>{name}</Title>
      <LastModified>
        Last modified on {moment(updateDt).format('M/D/YYYY [at] h:mma')}
      </LastModified>
      {hasFeature('20210426_presentation_space_phase_2') &&
        publishedToPresentationFl && (
          <Published>
            <Tag uppercase>Published</Tag>
          </Published>
        )}
    </Container>
  )
}

export default ReelCard
