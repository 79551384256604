import React from 'react'
import Svg from 'components/Svg'

const ChevronDownSmIcon = props => (
  <Svg viewBox="0 0 14 14" {...props}>
    <polygon points="12.03 5.41 10.62 4 7 7.62 3.38 4 1.97 5.41 6.98 10.43 7 10.41 7.02 10.43 12.03 5.41" />
  </Svg>
)

export default ChevronDownSmIcon
