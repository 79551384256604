import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from '@apollo/react-components'

export const CREATE_VIDEO_TRANSCRIPT = gql`
  mutation VICreateVideoTranscriptMutation(
    $input: CreateVideoTranscriptInput!
  ) {
    createVideoTranscript(input: $input)
      @jsonapi(path: "/video_transcripts", method: "POST")
  }
`

const CreateVideoTranscript = ({ children }) => (
  <Mutation mutation={CREATE_VIDEO_TRANSCRIPT}>
    {(mutation, props) =>
      children({
        ...props,
        createVideoTranscript: ({ videoId, transcriptType = 'human' } = {}) =>
          mutation({
            variables: {
              input: {
                data: {
                  type: 'video_transcripts',
                  attributes: {
                    videoId,
                    transcriptType: 'human'
                  }
                }
              }
            }
          })
      })
    }
  </Mutation>
)

export default CreateVideoTranscript
