import React from 'react'
import DEFAULT_VARIANTS from './lib/defaultVariants'
import isReverse from './lib/isReverse'
import { motion } from 'framer-motion'
import { usePrevious } from 'utils/hooks'

const StateTransition = ({
  currentState,
  stateMap,
  infinite = false,
  children
}) => {
  const variants = DEFAULT_VARIANTS
  const prevState = usePrevious(currentState)

  return (
    <motion.div
      key={currentState}
      variants={variants}
      custom={isReverse(currentState, prevState, stateMap, infinite)}
      initial="enter"
      animate="center"
      exit="exit"
    >
      {children}
    </motion.div>
  )
}

export default StateTransition
