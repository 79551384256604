import gql from 'graphql-tag'

const VI_CURRENT_USER_QUERY = gql`
  query VICurrentUserQuery($userId: String!) {
    currentUser(userId: $userId)
      @jsonapi(path: "/users/{args.userId}?include=companies") {
      id
      firstName
      lastName
      companies {
        id
        name
      }
      features
    }
  }
`

export default VI_CURRENT_USER_QUERY
