import React from 'react'
import Svg from 'components/Svg'

const AlertIcon = props => (
  <Svg viewBox="0 0 16 16" {...props}>
    <title>{props.title || 'Alert'}</title>
    <path d="M14.7,13.4l-6-11.9C8.6,1.2,8.3,1,8,1h0C7.7,1,7.4,1.2,7.3,1.5L1.4,13.4c-0.2,0.3-0.2,0.6,0,0.9c0.2,0.3,0.4,0.4,0.7,0.4c0,0,0.1,0,0.1,0l11.7,0c0.3,0.1,0.7-0.1,0.8-0.4C14.8,14,14.8,13.7,14.7,13.4z M2,13.7C2,13.7,2,13.7,2,13.7C2,13.7,2.1,13.7,2,13.7L2,13.7z M2.3,13.7L8,2.3l5.7,11.4L2.3,13.7z" />
    <path d="M7.5,6.2v4.1c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V6.2c0-0.3-0.2-0.5-0.5-0.5S7.5,5.9,7.5,6.2z" />
    <path d="M8,11C8,11,8,11,8,11L8,11C8,11,8,11,8,11C8,11,8,11,8,11C8,11,8,11,8,11c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.3,0.3,0.6,0.7,0.6c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5C8.6,11.3,8.4,11.1,8,11z" />
  </Svg>
)

export default AlertIcon
