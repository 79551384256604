import gql from 'graphql-tag'

export const Transcript_videoAnalysis = gql`
  fragment Transcript_videoAnalysis on VideoAnalysis {
    transcript {
      rawTranscript {
        results {
          items {
            startTime
            endTime
            type
            alternatives {
              content
            }
          }
        }
      }
    }
  }
`
