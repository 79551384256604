import { styled, css, borderRadius, fontSize, padding } from '@fv/design-tokens'
import {
  black,
  electricBlue,
  offWhite,
  paleGrey,
  red,
  white
} from 'styles/color'

const size = ({ size, theme }) => {
  switch (size) {
    case 'lg':
      return css`
        ${padding('s', { theme })};
        ${fontSize('l', { theme })};
      `
    case 's':
      return css`
        ${padding('xxs', { theme })};
        ${fontSize('s', { theme })};
      `
    default:
      return css`
        ${padding('xs', { theme })};
        ${fontSize('m', { theme })};
      `
  }
}

const Input = styled.input`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  width: auto;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: ${black};
  background-color: ${white};

  ${borderRadius('m')};
  ${size};

  &:disabled {
    border-color: ${paleGrey};
    background-color: ${offWhite};
    cursor: not-allowed;
  }

  ${({ valid, theme }) => css`
    border: 1px solid ${valid ? paleGrey : red};

    &:focus {
      outline: 0;
      border: 1px solid ${valid ? electricBlue : red};
    }
  `};
`

Input.defaultProps = {
  valid: true,
  inline: false
}

export default Input
