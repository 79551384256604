import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { ReelVideoClip_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/ReelVideoClip.fragments'
import { CopyClipModal_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/CopyClipModal/CopyClipModal.fragments'
import { ReelBuilderHeader_reel } from 'components/AdvancedEditor/ReelBuilder/Header.fragments'
import VI_REEL_QUERY from 'components/AdvancedEditor/ReelBuilder/reel.query.js'

export const VI_UPDATE_REEL_VIDEO_CLIP = gql`
  mutation UpdateReelVideoClipMutation($input: UpdateVideoClipInput!) {
    updateReelVideoClip(input: $input)
      @jsonapi(
        path: "/reel_video_clips/{args.input.data.id}?include=content,content.video,content.video.response,reel"
        method: "PATCH"
      ) {
      id
      ...ReelVideoClip_reelVideoClip
      ...CopyClipModal_reelVideoClip

      reel {
        ...ReelBuilderHeader_reel
      }
    }
  }

  ${ReelVideoClip_reelVideoClip}
  ${CopyClipModal_reelVideoClip}
  ${ReelBuilderHeader_reel}
`

const useUpdateReelVideoClipMutation = () => {
  const [mutate, result] = useMutation(VI_UPDATE_REEL_VIDEO_CLIP)

  const updateReelVideoClip = ({
    id,
    name,
    startTime,
    endTime,
    reelId,
    ...rest
  }) =>
    mutate({
      variables: {
        input: {
          data: {
            id,
            type: 'reel_video_clips',
            attributes: {
              name,
              startTime,
              endTime
            }
          }
        }
      },
      optimisticResponse: {
        updateReelVideoClip: {
          __typename: 'VIReelVideoClips',
          type: 'reel_video_clips',
          id,
          name,
          startTime,
          endTime,
          ...rest
        }
      },
      update: (cache, result) => {
        const {
          data: { updateReelVideoClip: updatedReelVideoClip }
        } = result

        const { reel } = cache.readQuery({
          query: VI_REEL_QUERY,
          variables: { reelId: reelId }
        })

        cache.writeQuery({
          query: VI_REEL_QUERY,
          variables: { reelId: reelId },
          data: {
            reel: {
              ...reel,
              reelVideoClips: reel.reelVideoClips.map(reelVideoClip =>
                reelVideoClip.id === updatedReelVideoClip.id
                  ? updatedReelVideoClip
                  : reelVideoClip
              )
            }
          }
        })
      }
    })

  return [updateReelVideoClip, result]
}

export default useUpdateReelVideoClipMutation
