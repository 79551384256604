import React, { Component } from 'react'
import Container from './Container'
import Item from './Item'
import Menu from './Menu'
import Section from './Section'

class DropdownMenu extends Component {
  static Item = Item
  static Section = Section

  state = {
    open: false
  }

  componentDidUpdate() {
    if (this.state.open) {
      document.addEventListener('click', this.close)
    } else {
      document.removeEventListener('click', this.close)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.close)
  }

  close = e => {
    if (!this.dropdown.contains(e.target)) {
      this.setState({ open: false })
    }
  }

  hide = () => this.setState(state => ({ open: false }))

  toggle = () => this.setState(state => ({ open: !state.open }))

  render() {
    const { align, children, maxHeight, trigger, width } = this.props
    const { open } = this.state

    return (
      <Container>
        {trigger(this.toggle, open)}
        <Menu
          align={align}
          width={width}
          open={open}
          maxHeight={maxHeight}
          ref={ref => (this.dropdown = ref)}
        >
          {typeof children === 'function' ? children(this.hide) : children}
        </Menu>
      </Container>
    )
  }
}

export default DropdownMenu
