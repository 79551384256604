import { withRouter } from 'react-router-dom'
import { isFunction } from 'utils'

const QueryParams = ({
  param,
  children,
  location: { search },
  location,
  match,
  history
}) => {
  const searchParams = new URLSearchParams(search)

  return isFunction(children)
    ? children({
        query: searchParams.get(param),
        searchParams,
        location,
        match,
        history
      })
    : children
}

export default withRouter(QueryParams)
