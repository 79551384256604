import React from 'react'
import { styled, color } from '@fv/design-tokens'

const Version = styled.pre`
  ${color('text')};
  text-align: center;
`

const RouteVersion = () => (
  <Version>{process.env.REACT_APP_GIT_REVISION || 'development'}</Version>
)

export default RouteVersion
