import React from 'react'
import { withRouter } from 'react-router-dom'
import { styled, color, marginTop } from '@fv/design-tokens'
import { chooseProcessingStatus, PROCESSING_STATUSES } from 'utils/analysis'
import { valuesFromLocation } from 'utils/params'
import { timeSince } from 'utils/time'
import Popover from 'components/Popover'
import PopoverDialog from 'components/PopoverDialog'
import HighlightButton from 'components/HighlightButton'

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  > button {
    ${marginTop('m')};
  }
`

const LinkText = styled.span`
  cursor: pointer;
  ${color('textLink')};
`

const NotProcessed = ({ video }) => (
  <MessageContainer data-testid="not-processed">
    No highlights yet for this video.
    <HighlightButton video={video} />
  </MessageContainer>
)

const Processing = ({ video }) => (
  <MessageContainer data-testid="processing">
    Still generating highlights...
    <Popover
      render={({ hide }) => (
        <PopoverDialog
          hide={hide}
          popoverTitle="What highlights?"
          popoverBody={`
                FV Insights uses state-of-the-art machine learning to
                automatically suggest top highlights for market researchers.

                Processing may take up to four hours. This video has been
                processing for ${timeSince(video.sentToInsightsAt)}.
              `}
        />
      )}
    >
      {({ toggle, ref }) => (
        <LinkText onClick={toggle} ref={ref}>
          What is this?
        </LinkText>
      )}
    </Popover>
  </MessageContainer>
)

const Error = ({ video }) => (
  <MessageContainer data-testid="highlight-clips-error">
    Something went wrong generating highlights
    <HighlightButton video={video} />
  </MessageContainer>
)

const NoResultsWithFilterCriteria = () => (
  <MessageContainer data-testid="no-results">
    There are no highlights that meet your filter criteria.
  </MessageContainer>
)

const HighlightClipsGuard = ({ clips, video, location, children }) => {
  const status = chooseProcessingStatus(video)
  const isFilterApplied = Boolean(
    valuesFromLocation(location, 'sentiment')
      .concat(valuesFromLocation(location, 'entity'))
      .concat(valuesFromLocation(location, 'keyphrase')).length
  )

  if (clips.length) {
    return children
  }

  if (isFilterApplied && !clips.length) {
    return <NoResultsWithFilterCriteria />
  }

  if (status === PROCESSING_STATUSES.NOT_PROCESSED) {
    return <NotProcessed video={video} />
  }

  if (status === PROCESSING_STATUSES.PROCESSING) {
    return <Processing video={video} />
  }

  return <Error />
}

export default withRouter(HighlightClipsGuard)
