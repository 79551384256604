import React from 'react'
import { styled, background, color } from '@fv/design-tokens'
import noop from 'utils/noop'
import QueryParamRoute from 'components/QueryParams/QueryParamRoute'
import { Header } from './Header'
import { LeftSidebar, LeftSidebarContainer } from './LeftSidebar'
import { Main, PlayerContainer, ContentContainer } from './Main'
import RightSidebar from './RightSidebar'
import { SubNavigation } from './SubNavigation'

const Container = styled.div`
  display: grid;
  height: 100vh;
  ${color('text')};
  ${background('background')};
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'videoHeader videoHeader videoHeader'
    'videoLeftSidebar videoMain videoRightSidebar';
`

const Layout = ({
  renderHeader = noop,
  // renderLeftSidebar = noop,
  renderPlayer = noop,
  renderSubNavigation = noop,
  renderContent = noop,
  renderReelBuilder = noop,
  renderTranscriptSettings = noop,
  renderVideoSources = noop
}) => (
  <Container>
    <Header>{renderHeader()}</Header>
    {/* <LeftSidebar>
      <LeftSidebarContainer>{renderLeftSidebar()}</LeftSidebarContainer>
    </LeftSidebar> */}
    <Main>
      <PlayerContainer>{renderPlayer()}</PlayerContainer>
      <SubNavigation>{renderSubNavigation()}</SubNavigation>
      <ContentContainer>{renderContent()}</ContentContainer>
    </Main>
    <RightSidebar>
      <QueryParamRoute
        param="panel"
        query="showreelBuilder"
        render={renderReelBuilder}
      />
      <QueryParamRoute
        param="panel"
        query="transcription"
        render={renderTranscriptSettings}
      />
      <QueryParamRoute
        param="panel"
        query="videoSources"
        render={renderVideoSources}
      />
    </RightSidebar>
  </Container>
)

export default Layout
