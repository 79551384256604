import React from 'react'
import {
  backgroundColor,
  fontSize,
  fontWeight,
  padding,
  paddingLeft,
  paddingRight,
  paddingTop,
  styled
} from '@fv/design-tokens'
import VideoSource from './VideoSource'
import Loader from 'components/Loader'

const VideoSourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 70px);
`

const Title = styled.h1`
  box-shadow: 0 4px 4px -4px #0006;
  line-height: 1.25rem;
  margin: 0;
  z-index: 2;

  ${fontSize('m')};
  ${fontWeight('bold')};
  ${padding('m')};
`

const VideoSourcesList = styled.div`
  flex: 1;
  overflow-y: auto;

  ${backgroundColor('backgroundShaded')};
  ${paddingLeft('s')};
  ${paddingRight('s')};
  ${paddingTop('s')};
`

const VideoSources = ({ responses, loading, error, fetchMore }) => (
  <VideoSourcesContainer
    onScroll={e => {
      const { clientHeight, scrollHeight, scrollTop } = e.target

      if (scrollTop + clientHeight + 150 > scrollHeight) {
        fetchMore()
      }
    }}
  >
    <Title>Video Sources</Title>
    <VideoSourcesList>
      {responses.map(response => (
        <VideoSource response={response} key={response.id} />
      ))}
      {loading && <Loader />}
    </VideoSourcesList>
  </VideoSourcesContainer>
)

export default VideoSources
