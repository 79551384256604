import { styled, background } from '@fv/design-tokens'

export const Main = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-area: videoMain;
  overflow: hidden;
`

export const PlayerContainer = styled.div`
  ${background('backgroundDk')};
  height: 30vh;

  .video-js,
  .video-js .vjs-tech {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
  }

  [class*='PlayerContainer'] {
    height: 100%;
    padding-bottom: 0;
  }
`

export const ContentContainer = styled.div`
  box-shadow: inset 0 4px 4px -4px #0006;
  overflow: hidden;
  ${background('backgroundShaded')};
`
