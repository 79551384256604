import React from 'react'
import Svg from 'components/Svg'

/*
 * TODO: This icon is pulled from the Design Language `playlist` icon.
 * We want to land on an actual icon for "videos" and replace this.
 */
const VideoSources = props => (
  <Svg viewBox="0 0 18 18" {...props}>
    <title>video sources</title>
    <g>
      <path d="M2.625 3.375h-1.5a.75.75 0 00-.75.75v12.75c0 .414.336.75.75.75h12.75a.75.75 0 00.75-.75v-1.5" />
      <path d="M17.625 1.125a.75.75 0 00-.75-.75h-13.5a.75.75 0 00-.75.75v13.5c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-13.5z" />
      <path d="M8.475 10.704a.405.405 0 01-.6-.354V5.277a.405.405 0 01.605-.351l4.442 2.538a.404.404 0 010 .702l-4.447 2.538z" />
    </g>
  </Svg>
)

export default VideoSources
