import {
  styled,
  css,
  backgroundColor,
  color,
  fontSize,
  fontWeight,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft
} from '@fv/design-tokens'

const background = ({ backgroundColorName, theme }) => css`
  ${backgroundColor(backgroundColorName, { theme })};
`

const ifCircle = ({ circle, theme }) =>
  circle &&
  css`
    border-radius: 50%;
    ${padding('xxs', { theme })};
  `

const ifTextColor = ({ textColorName, theme }) =>
  textColorName &&
  css`
    ${color(textColorName, { theme })};
  `

const Badge = styled.span`
  display: flex;
  border-radius: 10rem;
  align-items: center;

  ${paddingTop('xxxs')};
  ${paddingBottom('xxxs')};
  ${paddingRight('s')};
  ${paddingLeft('s')};
  ${fontSize('s')};
  ${fontWeight('bold')};
  ${color('textReverse')};
  ${background};

  ${ifTextColor};
  ${ifCircle};
`

Badge.defaultProps = { backgroundColorName: 'primary' }

export default Badge
