export const calcTop = ({
  scrollContainerClientRect,
  containerClientRect,
  selectionRangeClientRect,
  adjustment = 0
}) => {
  if (!containerClientRect) return -1000

  const { top, height } = containerClientRect
  const placement = getPlacement({
    scrollContainerClientRect,
    elementOrRangeClientRect: selectionRangeClientRect || containerClientRect
  })

  if (placement === 'innerTop') {
    return scrollContainerClientRect.top - top
  }

  if (selectionRangeClientRect) {
    const {
      top: selectionTop,
      bottom: selectionBottom
    } = selectionRangeClientRect

    return placement === 'top' ? selectionTop - top : selectionBottom - top
  }

  return placement === 'top' ? 0 : height
}

export const calcLeft = ({ selectionRangeClientRect, containerClientRect }) => {
  if (!containerClientRect) return -1000

  const { width, left: textContainerLeft } = containerClientRect

  if (selectionRangeClientRect) {
    const {
      width: selectionWidth,
      left: selectionLeft
    } = selectionRangeClientRect

    return selectionLeft - textContainerLeft + selectionWidth / 2
  }

  return width / 2
}

export const getPlacement = ({
  scrollContainerClientRect,
  elementOrRangeClientRect
}) => {
  if (!elementOrRangeClientRect) return 'top'

  const {
    top: scrollContainerTop,
    bottom: scrollContainerBottom
  } = scrollContainerClientRect
  const { top, bottom } = elementOrRangeClientRect

  return top < scrollContainerTop && bottom > scrollContainerBottom
    ? 'innerTop'
    : scrollContainerTop < top - 30
    ? 'top'
    : 'bottom'
}
