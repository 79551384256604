import React from 'react'
import Content from './Content'
import Header from './Header'
import Title from './Title'
import Body from './Body'
import Footer from './Footer'

const Dialog = props => (
  <Content onClick={e => e.stopPropagation()} {...props} />
)
Dialog.Body = Body
Dialog.Header = Header
Dialog.Title = Title
Dialog.Footer = Footer

export default Dialog
