import React, { Fragment } from 'react'
import FeatherSvg from 'components/FeatherSvg'

const DIRECTIONS = {
  horizontal: (
    <Fragment>
      <circle cx="12" cy="12" r="1" />
      <circle cx="19" cy="12" r="1" />
      <circle cx="5" cy="12" r="1" />
    </Fragment>
  ),
  vertical: (
    <Fragment>
      <circle cx="12" cy="12" r="1" />
      <circle cx="12" cy="5" r="1" />
      <circle cx="12" cy="19" r="1" />
    </Fragment>
  )
}

const More = ({ direction, ...props }) => (
  <FeatherSvg {...props}>
    <title>{props.title}</title>
    {DIRECTIONS[direction]}
  </FeatherSvg>
)

More.defaultProps = {
  direction: 'horizontal'
}

export default More
