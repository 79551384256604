import React, { Fragment, useEffect, useRef } from 'react'
import {
  styled,
  background,
  borderColor,
  borderRadius,
  marginLeft,
  marginBottom,
  padding,
  paddingRight,
  paddingBottom,
  paddingLeft
} from '@fv/design-tokens'
import * as yup from 'yup'
import { formatTime } from 'utils/time'
import Form from 'components/Form'
import Button from 'components/Button'
import CloseIcon from 'components/icons/Close'
import StartPointIcon from 'components/icons/StartPoint'
import FormError from 'components/AdvancedEditor/FormError'
import TimestampInput from 'components/TimestampInput'
import { VideoControllerConsumer } from 'components/VideoPlayer/VideoControllerContext'
import { floor } from 'utils/math'
import Tooltip from 'components/Tooltip'

const schema = (videoLength = 3600 * 2) =>
  yup.object().shape({
    text: yup
      .string()
      .trim()
      .required("Text can't be blank"),
    secondsAfterStart: yup
      .number()
      .lessThan(
        videoLength,
        `Timestamp must be lesser than ${formatTime(videoLength)}`
      )
      .required("Timestamp can't be blank")
  })

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  ${padding('m')};
  width: 100%;

  [kind='error'] {
    margin: 0;
    ${marginBottom('xs')};
  }

  border-style: solid;
  ${borderRadius('m')};
  ${borderColor('primary')};
`

const Header = styled.div`
  display: flex;
  ${paddingBottom('xxs')};
`

const Title = styled.p`
  flex-grow: 1;
  margin: auto;
`

const SaveButton = styled(Button)`
  ${paddingLeft('xs')};
  ${paddingRight('xs')};
`

const HeaderControl = styled.div`
  display: flex;

  ${Button} {
    ${marginLeft('xxs')};
  }
`

const InputContainer = styled.div`
  display: flex;

  > input[name='text'] {
    flex-grow: 1;
    ${marginLeft('xxs')};
  }

  ${Button} {
    height: auto;
  }
`

const StyledTimestampInput = styled(TimestampInput)`
  ${padding('xs')};
  ${background('backgroundShaded')};
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-right: 0px;
  display: flex;
`

const TimestampInputGroup = styled.div`
  display: flex;
`

const SetTimeButton = styled(Button)`
  height: 100%;
  border: none;
  ${background('buttonBackground')};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  ${padding('xs')};
`

const MarkForm = ({
  isNew,
  text,
  secondsAfterStart,
  videoLength,
  handleSubmit,
  handleCancel
}) => {
  const nameInput = useRef(null)
  useEffect(() => {
    nameInput.current.focus()
  }, [])

  const handleOnKeyDown = e => {
    if (e.key === 'Escape') {
      e.preventDefault()
      handleCancel()
    }
  }

  return (
    <StyledForm
      initialValues={{ text, secondsAfterStart }}
      validationSchema={schema(videoLength)}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Fragment>
        <Header>
          <Title>{isNew ? 'New Mark' : 'Edit Mark'}</Title>
          <HeaderControl>
            <SaveButton primary>Save</SaveButton>
            <Tooltip placement="top" render={() => 'Cancel'}>
              {({ show, hide, ref }) => (
                <Button
                  square
                  type="button"
                  onClick={handleCancel}
                  onMouseOver={show}
                  onMouseOut={hide}
                  ref={ref}
                >
                  <CloseIcon />
                </Button>
              )}
            </Tooltip>
          </HeaderControl>
        </Header>
        <FormError name="text" />
        <FormError name="secondsAfterStart" />
        <InputContainer onKeyDown={handleOnKeyDown}>
          <Form.Field
            name="secondsAfterStart"
            render={({ setValue, value, ...rest }) => (
              <VideoControllerConsumer>
                {({ currentTime }) => (
                  <TimestampInputGroup>
                    <StyledTimestampInput setValue={setValue} value={value} />
                    <Tooltip placement="top" render={() => 'Set mark time'}>
                      {({ show, hide, ref }) => (
                        <SetTimeButton
                          type="button"
                          onMouseOver={show}
                          onMouseOut={hide}
                          ref={ref}
                          onClick={() => setValue(floor(currentTime))}
                          aria-label="Set mark time"
                        >
                          <StartPointIcon />
                        </SetTimeButton>
                      )}
                    </Tooltip>
                  </TimestampInputGroup>
                )}
              </VideoControllerConsumer>
            )}
          />
          <Form.Input ref={nameInput} type="text" name="text" />
        </InputContainer>
      </Fragment>
    </StyledForm>
  )
}

export default MarkForm
