import gql from 'graphql-tag'

export const OrderHumanTranscriptionModal_response = gql`
  fragment OrderHumanTranscriptionModal_response on VIVideoResponses {
    id
    title
    project {
      id
      sourceId
      name
    }
  }
`

export const OrderHumanTranscriptionModal_video = gql`
  fragment OrderHumanTranscriptionModal_video on VIVideos {
    id
    length
  }
`

export const OrderHumanTranscriptionModal_insightsVideo = gql`
  fragment OrderHumanTranscriptionModal_insightsVideo on Video {
    id
  }
`
