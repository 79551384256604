import React, { useContext, Fragment } from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import VideoControllerContext from 'components/VideoPlayer/VideoControllerContext'
import VideoPlayer from 'components/VideoPlayer'
import AnalysisLeftSidebar from './AnalysisLeftSidebar'
import ReelBuilder from './ReelBuilder'
import Layout from './Layout/index'
import Header from './Header'
import SubNavigation from './SubNavigation'
import Transcript from './Transcript/index'
import TranscriptSettings from './TranscriptSettings'
import Marks from './Marks/index'
import Highlights from './Highlights/index'
import TabLoader from './TabLoader'
import PollData from './PollData'
import OrderHumanTranscriptionModal from './OrderHumanTranscriptionModal'
import VideoSources from './VideoSources'
import Loader from 'components/Loader'
import { styled, marginTop } from '@fv/design-tokens'
import { TranscriptProvider } from './Transcript/Context'
import { MultiVideoControllerProvider } from 'components/MultiVideoPlayer/MultiVideoControllerContext'
import dig from 'utils/dig'
import useQueryParams from 'hooks/useQueryParams'

const StyledLoader = styled(Loader)`
  ${marginTop('s')};
`

const selectActiveVideoAnalysis = ({ video = {} } = {}) =>
  video.humanAnalysis && video.humanAnalysis.highlightAnalysisCompletedAt
    ? video.humanAnalysis
    : video.machineAnalysis

const handleClose = (companyId, projectId) => {
  const backRoute = `/r/companies/${companyId}/projects/${projectId}`

  if (
    document.referrer.startsWith(`https://${window.location.host}${backRoute}`)
  ) {
    // Attempt to preserve query params from the previous VI route by
    // going back in history.
    // If `window.history.back()` doesn't navigate anywhere,
    // execution will continue.
    window.history.back()
  }

  window.location = backRoute
}

const buildLanguageSources = videos =>
  videos.map(({ language, languageDescription, sourceUrl }) => ({
    languageCode: language,
    languageName: languageDescription,
    sources: [{ type: 'video/mp4', src: sourceUrl }]
  }))

const RedirectToVideoSources = () => {
  const [, setQueryParams] = useQueryParams()
  setQueryParams({ panel: 'videoSources' })
  return null
}

const AdvancedEditor = ({
  data,
  loading,
  response,
  response: {
    id: responseId,
    assetUrl,
    title,
    transcriptionStatus,
    transcriptionType,
    project: {
      id: projectId,
      permissions: { editAnalyze: canEdit }
    },
    videos
  } = {},
  video,
  insightsVideoQueryVariables,
  match: {
    path,
    url,
    params: { companyId }
  },
  location,
  refetch,
  hasStreamlineFeature
}) => {
  const { clips } = useContext(VideoControllerContext)
  const activeVideoAnalysis = selectActiveVideoAnalysis(data)

  return (
    <TranscriptProvider video={video} videoAnalysis={activeVideoAnalysis}>
      <Layout
        renderHeader={() => (
          <Header
            responseId={responseId}
            canEdit={canEdit}
            title={title}
            video={video}
            data={data}
            close={() => handleClose(companyId, projectId)}
          />
        )}
        renderLeftSidebar={() => (
          <AnalysisLeftSidebar
            video={video}
            videoAnalysis={activeVideoAnalysis}
            location={location}
            loading={loading}
          />
        )}
        renderPlayer={() => (
          <VideoPlayer
            sources={[
              {
                src: assetUrl,
                type: 'video/mp4'
              }
            ]}
            languageSources={buildLanguageSources(videos)}
            plugins={{
              clipPlugin: {
                clips,
                color: '#0c3f7d'
              }
            }}
          />
        )}
        renderSubNavigation={() => <SubNavigation />}
        renderContent={() => (
          <Fragment>
            <Switch>
              <Route
                path={`${path}/transcript`}
                render={() => (
                  <Transcript
                    videoAnalysis={activeVideoAnalysis}
                    viTranscriptionStatus={transcriptionStatus}
                    viTranscriptionType={transcriptionType}
                    video={video}
                    loading={loading}
                    canEdit={canEdit}
                  />
                )}
              />
              <Route
                path={`${path}/marks`}
                render={() => (
                  <Marks
                    responseId={responseId}
                    videoLength={video.length}
                    canEdit={canEdit}
                  />
                )}
              />
              <Route
                path={`${path}/highlights`}
                render={() =>
                  loading ? (
                    <TabLoader />
                  ) : (
                    <Highlights
                      video={video}
                      videoAnalysis={activeVideoAnalysis}
                      title={title}
                      canEdit={canEdit}
                    />
                  )
                }
              />
              <Redirect
                from={`${path}*`}
                to={{ pathname: `${url}/transcript`, search: location.search }}
                replace
              />
            </Switch>
            {data && (
              <PollData
                insightsVideo={data.video}
                highlightReelJwt={video.highlightReelJwt}
                onAnalysisStageCompleted={({ id }) =>
                  id === activeVideoAnalysis.id ? refetch() : null
                }
              />
            )}
          </Fragment>
        )}
        renderReelBuilder={() =>
          canEdit ? (
            <MultiVideoControllerProvider>
              <ReelBuilder
                videoId={video.id}
                videoLength={video && video.length}
                projectId={projectId}
                companyId={companyId}
              />
            </MultiVideoControllerProvider>
          ) : (
            <RedirectToVideoSources />
          )
        }
        renderTranscriptSettings={() => (
          <OrderHumanTranscriptionModal
            video={video}
            insightsVideoQueryVariables={insightsVideoQueryVariables}
            insightsVideo={data && data.video}
            response={response}
          >
            {({ show }) =>
              loading ? (
                <StyledLoader />
              ) : (
                <TranscriptSettings
                  machineAnalysis={dig(data, 'video', 'machineAnalysis')}
                  humanAnalysis={dig(data, 'video', 'humanAnalysis')}
                  showOrderHumanTranscriptionModal={show}
                  canRequestTranscription={canEdit}
                />
              )
            }
          </OrderHumanTranscriptionModal>
        )}
        renderVideoSources={() => <VideoSources projectId={projectId} />}
      />
    </TranscriptProvider>
  )
}

export default withRouter(AdvancedEditor)
