import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router'
import { styled, fontSize } from '@fv/design-tokens'
import Button, { ExternalButtonLink } from 'components/Button'
import Loader from 'components/Loader'
import { FINALIZING_STATUSES, PUBLISH_STATUSES } from '../utils'
import Tooltip from 'components/Tooltip'
import MultiVideoPlayer from 'components/MultiVideoPlayer'
import { debounce } from 'debounce'
import VI_COMPANY_QUERY from './vi_company.query'

const Container = styled.div`
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 4px 3px #0006;
  z-index: 2;
  ${fontSize('m')};
`

const Controls = styled.div`
  display: flex;
  grid-template-columns: 1fr auto;
`

const Footer = ({
  publishStatus,
  handlePreview,
  previewHidden,
  handleClosePreview,
  getCurrentClip,
  handleFinalize,
  isPublishable,
  downloadUrl
}) => {
  const { companyId } = useParams()
  const {
    data: { company: { restrictDownloadVideosFl } = {} } = {},
    loading
  } = useQuery(VI_COMPANY_QUERY, {
    variables: { companyId }
  })
  const isFinalizing = FINALIZING_STATUSES.includes(publishStatus)

  return (
    <Container>
      <MultiVideoPlayer
        hidden={previewHidden}
        handleClose={handleClosePreview}
        plugins={{
          clipPlugin: {
            clips: getCurrentClip() ? [getCurrentClip()] : [],
            color: '#0c3f7d'
          }
        }}
      />
      {publishStatus && (
        <Controls>
          <Button big ghost onClick={debounce(handlePreview, 500, true)}>
            Preview
          </Button>
          {publishStatus === PUBLISH_STATUSES.COMPLETE && !isPublishable ? (
            !restrictDownloadVideosFl && (
              <ExternalButtonLink big ghost href={loading ? '' : downloadUrl}>
                {loading ? <Loader /> : 'Download'}
              </ExternalButtonLink>
            )
          ) : isFinalizing ? (
            <Tooltip
              placement="top"
              render={() =>
                'Your showreel is finalizing and will be available momentarily'
              }
              key="finalizing"
            >
              {({ show, hide, ref }) => (
                <Button
                  big
                  ghost
                  disabled
                  onMouseOver={show}
                  onMouseOut={hide}
                  ref={ref}
                >
                  Download
                </Button>
              )}
            </Tooltip>
          ) : (
            <Tooltip
              placement="top"
              render={() => 'Export to MP4'}
              key="finalize"
            >
              {({ show, hide, ref }) => (
                <Button
                  big
                  ghost
                  onMouseOver={show}
                  onMouseOut={hide}
                  ref={ref}
                  onClick={() => handleFinalize()}
                >
                  Finalize
                </Button>
              )}
            </Tooltip>
          )}
        </Controls>
      )}
    </Container>
  )
}

export default Footer
