import React, { Component, Fragment } from 'react'
import { createPortal } from 'react-dom'
import Container from './Container'
import invariant from 'invariant'
import { Manager, Reference, Popper } from 'react-popper'
import noop from 'utils/noop'

class Popover extends Component {
  static defaultProps = {
    placement: 'top',
    render: noop
  }

  state = {
    open: false
  }

  popoverRoot = document.getElementById('popover-root')

  constructor(props) {
    super(props)

    if (process.env.NODE_ENV !== 'production') {
      invariant(this.popoverRoot, 'This app does not contain a popover root.')
    }
  }

  show = () => this.setState({ open: true })
  hide = () => this.setState({ open: false })
  toggle = () => this.setState({ open: !this.state.open })

  render() {
    const { placement, children, ...rest } = this.props
    const { open } = this.state

    const popover = (
      <Popper placement={placement} {...rest}>
        {({ ref, placement, style }) => (
          <Container
            ref={ref}
            style={style}
            data-placement={placement}
            open={open}
          >
            {this.props.render({ hide: this.hide })}
          </Container>
        )}
      </Popper>
    )

    const reference = (
      <Reference>
        {({ ref }) =>
          children({
            ref,
            hide: this.hide,
            show: this.show,
            toggle: this.toggle
          })
        }
      </Reference>
    )

    return (
      <Manager>
        <Fragment>
          {reference}
          {createPortal(popover, this.popoverRoot)}
        </Fragment>
      </Manager>
    )
  }
}

export default Popover
