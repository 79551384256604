import React from 'react'
import AnalysisToggle from 'components/AnalysisToggle'

const KeyPhraseOccurrences = ({ text, ...props }) => (
  <AnalysisToggle paramKey={'keyphrase'} paramValue={text} {...props}>
    {text}
  </AnalysisToggle>
)

export default KeyPhraseOccurrences
