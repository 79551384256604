import React from 'react'
import { styled, padding } from '@fv/design-tokens'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import CloseIcon from 'components/icons/Close'

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: top;

  > :first-child {
    flex: 1;
    margin-bottom: 0;
  }

  ${padding('m')};
  padding-bottom: 0;
`

const CloseButton = props => (
  <Button {...props} ghost square round>
    <CloseIcon />
  </Button>
)

Container.displayName = 'Dialog.Header.Container'

const Header = ({ children, onClose, ...props }) => (
  <Container {...props}>
    {children}
    {onClose && <CloseButton onClick={onClose} />}
  </Container>
)

Header.propTypes = { onClose: PropTypes.func }
Header.displayName = 'Dialog.Header'

export default Header
