import React, { useContext, useEffect, useState } from 'react'
import { styled, background, padding } from '@fv/design-tokens'
import VideoControllerContext from 'components/VideoPlayer/VideoControllerContext'
import MultiVideoControllerContext from 'components/MultiVideoPlayer/MultiVideoControllerContext'
import CurrentUserContext from 'components/CurrentUser/Context'
import Loader from 'components/Loader'
import withNotifier from 'components/withNotifier'
import Header from './Header'
import Footer from './Footer'
import ReelVideoClips from './ReelVideoClips'
import EmptyReelPlaceholder from './EmptyReelPlaceholder'
import NewClipContext from './NewClipContext'
import ClipsPreviewContext from './ClipsPreviewProvider/Context'
import UnpublishReelSpeedBump from './UnpublishReelSpeedBump'
import useRenameReelMutation from 'hooks/mutations/useRenameReel'
import useCreateReelMutation from 'hooks/mutations/useCreateReel'
import useDeleteReelMutation from 'hooks/mutations/useDeleteReel'
import useFinalizeReelMutation from 'hooks/mutations/useFinalizeReel'
import useQueryParams from 'hooks/useQueryParams'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 70px);
`

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  ${background('backgroundShaded')};
  ${padding('xs')};
`

const ReelBuilder = ({
  history,
  location,
  pathname,
  projectId,
  reel,
  videoId,
  videoLength,
  workspace,
  notify
}) => {
  const { newClip, setNewClip, unsetNewClip } = useContext(NewClipContext)
  const { pause: pausePreview, getCurrentClip, playClips } = useContext(
    MultiVideoControllerContext
  )
  const { viewer } = useContext(CurrentUserContext)
  const { currentTime } = useContext(VideoControllerContext)
  const [createReel] = useCreateReelMutation()
  const [renameReel] = useRenameReelMutation()
  const [finalizeReel] = useFinalizeReelMutation({
    onCompleted: () => {
      notify(
        'success',
        <>
          Your showreel is being finalized. It will be available here
          momentarily!
        </>,
        { sticky: true }
      )
    }
  })
  const [showReelsIndex, setShowReelsIndex] = useState(false)
  const {
    hidden: previewHidden,
    show: showPreview,
    hide: hidePreview
  } = useContext(ClipsPreviewContext)
  const [, setQueryParams] = useQueryParams()

  const [deleteReel, { loading: reelIsDeleting }] = useDeleteReelMutation()

  const handleNewClip = () => {
    if (!previewHidden) {
      handleClosePreview()
    }
    setQueryParams({
      clip: undefined
    })
    setNewClip({ startTime: currentTime, endTime: currentTime + 5 })
  }

  const handleNewReel = () => {
    if (!previewHidden) {
      handleClosePreview()
    }
    setQueryParams({ reelBuilderPanel: 'builder', reel: undefined })
  }

  const handleOpenReel = () => {
    if (!previewHidden) {
      handleClosePreview()
    }
    setQueryParams({ reelBuilderPanel: 'reels', reel: undefined })
  }

  const handleDeleteReel = async () => {
    if (!previewHidden) {
      handleClosePreview()
    }
    await deleteReel({ reelId: reel.id, userId: viewer.id, projectId })
    clearReelParam()
  }

  const clearReelParam = () =>
    setQueryParams({
      reel: undefined,
      clip: undefined
    })

  const showReel = reelId =>
    setQueryParams({
      reel: reelId,
      clip: undefined
    })

  const handleRenameReel = async name => {
    if (reel) {
      if (reel.name !== name) {
        renameReel({ ...reel, name, userId: viewer.id, projectId })
      }
    } else {
      const { data } = await createReel({
        name,
        videoClips: [],
        workspaceId: workspace.id,
        userId: viewer.id,
        projectId
      })
      showReel(data.reel.id)
    }
  }

  const handlePreviewClip = reelVideoClip => {
    showPreview()
    playClips([reelVideoClip])
  }

  const handlePreviewReel = () => {
    showPreview()
    playClips(reel.reelVideoClips)
  }

  const handleClosePreview = () => {
    pausePreview()
    hidePreview()
  }

  useEffect(() => {
    if (!reel) {
      setQueryParams({ reel: undefined })
    }
    unsetNewClip()
  }, [reel])

  return (
    <Container>
      {reelIsDeleting ? (
        <Loader marginTop={10} />
      ) : (
        <UnpublishReelSpeedBump reel={reel}>
          <Header
            reel={reel}
            handleNewClip={handleNewClip}
            handleNewReel={handleNewReel}
            handleOpenReel={handleOpenReel}
            handleRenameReel={handleRenameReel}
            handleDeleteReel={handleDeleteReel}
            deleteReelDisabled={!reel}
            showReelsIndex={showReelsIndex}
            setShowReelsIndex={setShowReelsIndex}
          />
          <Content>
            {(reel && reel.reelVideoClips.length) || newClip ? (
              <ReelVideoClips
                workspace={workspace}
                projectId={projectId}
                reel={reel}
                videoLength={videoLength}
                videoId={videoId}
                previewClip={handlePreviewClip}
              />
            ) : (
              <EmptyReelPlaceholder handleNew={handleNewClip} />
            )}
          </Content>
          {reel &&
            Boolean(reel.reelVideoClips) &&
            Boolean(reel.reelVideoClips.length) && (
              <Footer
                handlePreview={handlePreviewReel}
                previewHidden={previewHidden}
                getCurrentClip={getCurrentClip}
                handleClosePreview={handleClosePreview}
                handleFinalize={() => finalizeReel({ reel })}
                isPublishable={reel.publishDirtyFl}
                publishStatus={reel.publishStatus}
                downloadUrl={reel.publishVideoUrl}
              />
            )}
        </UnpublishReelSpeedBump>
      )}
    </Container>
  )
}

export default withNotifier(ReelBuilder)
