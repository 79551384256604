import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { ReelBuilder_reel } from 'components/AdvancedEditor/ReelBuilder/ReelBuilder.fragments'
import { ReelVideoClip_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/ReelVideoClip.fragments'
import { CopyClipModal_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/CopyClipModal/CopyClipModal.fragments'
import { ReelVideoClips_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/ReelVideoClips.fragments'
import VI_CURRENT_USER_REELS_QUERY from 'components/AdvancedEditor/ReelBuilder/ReelsIndex/CurrentUserReels.query'
import VI_PROJECT_REELS_QUERY from 'components/AdvancedEditor/ReelBuilder/ReelsIndex/ProjectReels.query'
import VI_REEL_QUERY, {
  INCLUDE,
  SPARSE_FIELDS
} from 'components/AdvancedEditor/ReelBuilder/reel.query.js'

export const VI_UPDATE_REEL_MUTATION = gql`
  mutation VIUpdateReel($input: UpdateReelInput!) {
    updateReel(input: $input)
      @jsonapi(
        path: "/reels/{args.input.data.id}?${INCLUDE}&${SPARSE_FIELDS}"
        method: "PATCH"
      ) {
      id
      ...ReelBuilder_reel

      reelVideoClips {
        id
        ...ReelVideoClip_reelVideoClip
        ...CopyClipModal_reelVideoClip
        ...ReelVideoClips_reelVideoClip
      }
    }
  }

  ${ReelBuilder_reel}
  ${ReelVideoClip_reelVideoClip}
  ${CopyClipModal_reelVideoClip}
  ${ReelVideoClips_reelVideoClip}
`

const useUpdateReelMutation = () => {
  const [mutate, ...statusParams] = useMutation(VI_UPDATE_REEL_MUTATION)

  const updateReel = ({
    id,
    name,
    reelVideoClips,
    userId,
    projectId,
    ...attributes
  }) =>
    mutate({
      variables: {
        input: {
          data: {
            type: 'reels',
            id,
            attributes: {
              name
            },
            relationships: {
              video_clips: {
                data: reelVideoClips.map(({ content: { id } }) => ({
                  type: 'video_clips',
                  id
                }))
              }
            }
          }
        }
      },
      optimisticResponse: {
        updateReel: {
          __typename: 'VIReels',
          type: 'reels',
          id,
          name,
          reelVideoClips: reelVideoClips.map((rvc, idx) => ({
            ...rvc,
            id: (new Date().getTime() + idx).toString(),
            __typename: 'VIReelVideoClips',
            videoLength: rvc.videoLength || 0
          })),
          ...attributes
        }
      },
      update: (cache, { data: { updateReel: updatedReel } }) => {
        try {
          const { reels: currentUserReels } = cache.readQuery({
            query: VI_CURRENT_USER_REELS_QUERY,
            variables: { userId, projectId }
          })

          cache.writeQuery({
            query: VI_CURRENT_USER_REELS_QUERY,
            variables: { userId, projectId },
            data: {
              reels: [updatedReel, ...currentUserReels]
            }
          })
        } catch (e) {}
        try {
          const { reels: projectReels } = cache.readQuery({
            query: VI_PROJECT_REELS_QUERY,
            variables: { projectId }
          })

          cache.writeQuery({
            query: VI_PROJECT_REELS_QUERY,
            variables: { userId, projectId },
            data: {
              reels: [updatedReel, ...projectReels]
            }
          })
        } catch (e) {}

        cache.writeQuery({
          query: VI_REEL_QUERY,
          variables: { reelId: updatedReel.id },
          data: { reel: updatedReel }
        })
      }
    })

  return [updateReel, statusParams]
}

export default useUpdateReelMutation
