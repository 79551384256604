import React, { useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router'
import { Query } from '@apollo/react-components'
import { valuesFromLocation } from 'utils/params'
import PageLoader from 'components/PageLoader'
import { VideoControllerProvider } from 'components/VideoPlayer/VideoControllerContext'
import FeaturesProvider from 'components/FeaturesProvider'
import useQueryParams from 'hooks/useQueryParams'
import ADVANCED_EDITOR_QUERY from './AdvancedEditor.query'
import { NewClipProvider } from './ReelBuilder/NewClipContext'
import AdvancedEditor from './AdvancedEditor'
import { PANEL_PARAMS } from './constants'
import CurrentUserContext from 'components/CurrentUser/Context'
import OnboardingModal from 'components/OnboardingModal'
import { VAW_WALKTHROUGH, VAW_KEY } from 'constants/walkthroughContent'
import { clearParamListOption, paramsForLocation } from 'utils/params'

const buildQueryVariables = ({ videoId, sentiments, entities, keyPhrases }) => {
  const tld = window.location.origin.split('.').pop()
  const applicationId = tld === 'eu' ? 'video_insights_eu' : 'video_insights_us'
  let queryVariables = {
    applicationId,
    sourceType: 'video',
    videoId,
    limit: 10
  }

  if (entities.length) {
    queryVariables = {
      ...queryVariables,
      entities
    }
  }

  if (keyPhrases.length) {
    queryVariables = {
      ...queryVariables,
      keyPhrases
    }
  }

  if (sentiments.length) {
    queryVariables = {
      ...queryVariables,
      sentiment: sentiments.map(s => s.toUpperCase())
    }
  }

  return queryVariables
}

const useSanitizeQueryString = ({ editAnalyze: canEdit }) => {
  const history = useHistory()
  const location = useLocation()
  const params = paramsForLocation(location)

  useEffect(() => {
    if (!canEdit) {
      clearParamListOption(params, 'reel')
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      })
    }
  }, [])
}

const AdvancedEditorContainer = ({
  data: { response, response: { videos = [], project: { permissions } } = {} }
}) => {
  const location = useLocation()
  const sentiments = valuesFromLocation(location, 'sentiment')
  const entityQuery = valuesFromLocation(location, 'entity')
  const keyphraseQuery = valuesFromLocation(location, 'keyphrase')
  const video = videos[0] || {}
  const videoId = video.id
  const insightsVideoQueryVariables = buildQueryVariables({
    videoId,
    sentiments,
    entities: entityQuery,
    keyPhrases: keyphraseQuery
  })
  const currentSearchParams = new URLSearchParams(location.search)
  const [, setQueryParams] = useQueryParams()
  const { hasFeature } = useContext(CurrentUserContext)

  useEffect(() => {
    setQueryParams(
      PANEL_PARAMS.indexOf(currentSearchParams.get('panel')) > -1
        ? {}
        : { panel: 'showreelBuilder' }
    )
  }, [])

  useSanitizeQueryString(permissions)

  return (
    <Query
      query={ADVANCED_EDITOR_QUERY}
      variables={insightsVideoQueryVariables}
      context={{
        headers: {
          Authorization: video && `Bearer ${video.highlightReelJwt}`
        }
      }}
      notifyOnNetworkStatusChange={true}
    >
      {({ loading, data, refetch }) => (
        <FeaturesProvider video={video}>
          <NewClipProvider videoId={video.id} assetUrl={response.assetUrl}>
            <VideoControllerProvider>
              {!data && loading ? (
                <PageLoader />
              ) : (
                <>
                  {hasFeature('20191022_streamline') && (
                    <OnboardingModal
                      content={VAW_WALKTHROUGH}
                      storageKey={VAW_KEY}
                    />
                  )}
                  <AdvancedEditor
                    data={data}
                    response={response}
                    video={video}
                    loading={loading}
                    insightsVideoQueryVariables={insightsVideoQueryVariables}
                    refetch={refetch}
                    hasStreamlineFeature={hasFeature('20191022_streamline')}
                  />
                </>
              )}
            </VideoControllerProvider>
          </NewClipProvider>
        </FeaturesProvider>
      )}
    </Query>
  )
}

export default withRouter(AdvancedEditorContainer)
