import { styled, marginBottom, paddingBottom } from '@fv/design-tokens'
import { paleGrey } from 'styles/color'

const Section = styled.div`
  &:not(:last-child) {
    ${marginBottom('xxs')};
    ${paddingBottom('xxs')};
    border-bottom: 1px solid ${paleGrey};
  }
`

Section.displayName = 'DropdownMenu.Section'

export default Section
