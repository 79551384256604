import { compose, prop, valueOr } from 'utils/fp'
import { connectionNodes } from 'utils/graphql'

const getSuggestedClips = data =>
  compose(
    connectionNodes,
    prop('clips'),
    valueOr({})
  )(data)

export default getSuggestedClips
