import React, { useContext } from 'react'
import { styled, marginBottom, keyframes } from '@fv/design-tokens'
import Context from './Context'
import Container from './Container'
import Notification from './Notification'

const notificationAnimation = keyframes`
  0% {
    min-height: 0;
    opacity: 0;
    transform: translate3d(100%, 0, 0) scaleY(0);
  }
  50% {
    transform: translate3d(100%, 0, 0) scaleY(0);
  }
  100% {
    min-height: 56px;
    opacity: 1;
  }
`

const AnimatedNotification = styled.div`
  ${marginBottom('xxs')};

  animation: 0.3s ease-out ${notificationAnimation};
`

const Notifications = () => {
  const { notifications } = useContext(Context)

  return (
    <Container>
      {notifications.map(notification => (
        <AnimatedNotification key={notification.id}>
          <Notification notification={notification} />
        </AnimatedNotification>
      ))}
    </Container>
  )
}

export default Notifications
