import React from 'react'
import Svg from 'components/Svg'

const CircleCheckIcon = props => (
  <Svg viewBox="0 0 16 16" {...props}>
    <title>{props.title || 'OK'}</title>
    <path d="M11.1,4.8L7.3,9.9l-2.5-2C4.6,7.7,4.3,7.8,4.1,8C3.9,8.2,4,8.5,4.2,8.7L7.1,11c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0.1,0c0.1,0,0.3-0.1,0.3-0.2l4.1-5.5c0.2-0.2,0.1-0.5-0.1-0.7C11.6,4.5,11.3,4.6,11.1,4.8z" />
    <path d="M8,0.8C4,0.8,0.8,4,0.8,8c0,4,3.2,7.2,7.2,7.2S15.2,12,15.2,8C15.2,4,12,0.8,8,0.8z M8,14.2c-3.4,0-6.2-2.8-6.2-6.2S4.6,1.8,8,1.8c3.4,0,6.2,2.8,6.2,6.2S11.4,14.2,8,14.2z" />
  </Svg>
)

export default CircleCheckIcon
