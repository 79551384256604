import React, { useEffect } from 'react'
import {
  styled,
  background,
  borderRadius,
  marginRight
} from '@fv/design-tokens'
import { isNumber } from 'utils'

const getHours = sec => Math.floor(sec / 3600)
const getMinutes = sec => Math.floor((sec - getHours(sec) * 3600) / 60)
const getSeconds = sec => sec - getHours(sec) * 3600 - getMinutes(sec) * 60

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  align-items: center;
  justify-content: center;
  ${background('buttonBackground')};
  ${marginRight('xxs')};
  ${borderRadius('m')};

  input {
    background: transparent;
    border: 0;
    text-align: center;
    width: 2.5ch;
    justify-self: center;
  }
`

const TimestampInput = ({
  name,
  'aria-label': ariaLabel,
  value,
  setValue,
  handleChange,
  className
}) => {
  useEffect(() => setValue(value), [value])

  const ariaLabelPrefix = ariaLabel || name

  return (
    <Container className={className}>
      <input
        type="text"
        aria-label={`${ariaLabelPrefix} hours`}
        value={String(getHours(value)).padStart(2, 0)}
        onChange={({ target: { value: _value } }) => {
          handleChange && handleChange()
          isNumber(_value) && parseInt(_value, 10) < 60
            ? setValue(
                parseInt(_value, 10) * 3600 +
                  getMinutes(value) * 60 +
                  getSeconds(value)
              )
            : setValue(getMinutes(value) * 60 + getSeconds(value))
        }}
      />
      :
      <input
        type="text"
        aria-label={`${ariaLabelPrefix} minutes`}
        value={String(getMinutes(value)).padStart(2, 0)}
        onChange={({ target: { value: _value } }) => {
          handleChange && handleChange()
          isNumber(_value) && parseInt(_value, 10) < 60
            ? setValue(
                getHours(value) * 3600 +
                  parseInt(_value, 10) * 60 +
                  getSeconds(value)
              )
            : setValue(getHours(value) * 3600 + getSeconds(value))
        }}
      />
      :
      <input
        type="text"
        aria-label={`${ariaLabelPrefix} seconds`}
        value={String(getSeconds(value)).padStart(2, 0)}
        onChange={({ target: { value: _value } }) => {
          handleChange && handleChange()
          isNumber(_value) && parseInt(_value, 10) < 60
            ? setValue(
                getHours(value) * 3600 +
                  getMinutes(value) * 60 +
                  parseInt(_value, 10)
              )
            : setValue(getHours(value) * 3600 + getMinutes(value) * 60)
        }}
      />
    </Container>
  )
}

export default TimestampInput
