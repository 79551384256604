import React from 'react'
import { styled, css, background } from '@fv/design-tokens'

const SummaryDuration = styled.div`
  width: 100%;
  ${background('buttonBackground')};
  position: relative;
  height: 5px;
`

const dynamicWidth = ({ width = 0, left = 0 }) => css`
  width: ${width};
  left: ${left};
`

const SummaryBar = styled.div`
  height: 100%;
  position: absolute;
  min-width: 1px;
  ${background('buttonPrimaryBackground')};
  ${dynamicWidth};
`

const percentAtTime = (time, duration) => (time * 100) / duration + '%'

const clipDuration = (clip, duration) =>
  percentAtTime(clip.endTime - clip.startTime, duration)

const ClipDurationBar = ({ clip, videoLength }) => (
  <SummaryDuration>
    <SummaryBar
      width={clipDuration(clip, videoLength)}
      left={percentAtTime(clip.startTime, videoLength)}
    />
  </SummaryDuration>
)

ClipDurationBar.defaultProps = {
  videoLength: 0,
  clip: { startTime: 0, endTime: 0 }
}

export default ClipDurationBar
