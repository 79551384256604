import React from 'react'

const CloseIcon = ({ width = 18, height = 18, title, ...rest }) => (
  <svg viewBox="0 0 18 18" width={width} height={height} {...rest}>
    <title>{title || 'close'}</title>
    <rect
      x="1"
      y="8"
      width="16"
      height="2"
      transform="translate(-3.73 9) rotate(-45)"
    />
    <rect
      x="8"
      y="1"
      width="2"
      height="16"
      transform="translate(-3.73 9) rotate(-45)"
    />
  </svg>
)

export default CloseIcon
