export const replacePunctuations = (string, replaceWith) =>
  string.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, replaceWith)

export const replaceSpaces = (string, replaceWith = ' ') =>
  string.replace(/\s\s+/g, replaceWith)

export const replaceSpacesWithUnicode = string =>
  string.replace(/\s/g, '\u00a0')

export const matchDoubleSpace = str => str.match(/\s\s/g)
