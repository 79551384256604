import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from '@apollo/react-components'
import VI_MARKS_QUERY from 'components/AdvancedEditor/Marks/marks.query.js'
import { Mark_mark } from 'components/AdvancedEditor/Marks/Mark.fragments.js'

export const VI_DELETE_MARK = gql`
  mutation VIDeleteMark($markId: String!) {
    deleteMark(markId: $markId)
      @jsonapi(path: "/marks/{args.markId}", method: "DELETE")
  }

  ${Mark_mark}
`

const updateCache = ({ cache, markId, responseId }) => {
  const {
    marks,
    marks: { graphql }
  } = cache.readQuery({
    query: VI_MARKS_QUERY,
    variables: { responseId }
  })

  cache.writeQuery({
    query: VI_MARKS_QUERY,
    variables: { responseId },
    data: {
      marks: {
        ...marks,
        graphql: graphql.filter(mark => mark.id !== markId)
      }
    }
  })
}

const DeleteMark = ({ responseId, children }) => (
  <Mutation mutation={VI_DELETE_MARK}>
    {(mutation, props) =>
      children({
        ...props,
        deleteMark: markId =>
          mutation({
            variables: {
              markId
            },
            optimisticResponse: {
              deleteMark: null
            },
            update: cache => {
              updateCache({ cache, markId, responseId })
            }
          })
      })
    }
  </Mutation>
)

export default DeleteMark
