import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, ModalV2 as Modal, Dialog } from 'stylist'
import {
  styled,
  css,
  borderRadius,
  fontSize,
  marginBottom,
  marginTop,
  padding,
  paddingBottom,
  paddingTop,
  paddingLeft
} from '@fv/design-tokens'
import {
  electricBlue,
  ink,
  paleGrey,
  offWhite,
  offWhite2,
  cadet
} from 'styles/color'
import { compose } from 'utils/fp'
import { formatTime } from 'utils/time'
import withNotifier from 'components/withNotifier'
import Loader from 'components/Loader'
import HumanTranscriptIcon from 'components/icons/HumanTranscript'
import withMutations from './withMutations'
import { HUMAN_TRANSCRIPTION_PRICE_PER_HOUR } from './constants'
import QueryParamLink from 'components/QueryParams/QueryParamLink'
import Checkbox from 'components/Checkbox'

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${ink};
  color: ${offWhite};
  ${padding('xl')};
`

const Title = styled.h2`
  ${fontSize('l')};
  margin-bottom: 0;
`

const CostDetails = styled.div`
  background: ${offWhite2};
  ${padding('m')};
  ${marginBottom('s')};
  ${borderRadius('m')};
`

const CostDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;

  h5 {
    ${fontSize('m')};
    ${marginBottom('xxs')};
  }

  h3 {
    text-align: right;
    ${fontSize('xl')};
    ${marginBottom('xxs')};
  }
`

const DetailsTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${paleGrey};
  ${paddingBottom('xs')};
  ${paddingTop('xs')};

  span:first-child {
    font-weight: bold;
  }
`

const showDetailsTableStyle = ({ show, theme }) =>
  show
    ? css`
        max-height: 500px;
        ${marginTop('l', { theme })};
      `
    : null

const DetailsTable = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease 0s;
  ${showDetailsTableStyle};

  ${DetailsTableRow}:last-child {
    ${marginBottom('s')};
  }
`

const CostDisclaimer = styled.p`
  color: ${cadet};
  ${marginTop('s')};
`

const externalLinkStyle = ({ inline, theme }) =>
  !inline
    ? css`
        display: block;
        ${marginBottom('xs', { theme })};
        ${marginTop('xs', { theme })};
      `
    : null

const LearnMoreParagraph = styled.p`
  ${marginBottom('s')};
`

const ExternalLink = styled.a`
  color: ${electricBlue};
  ${externalLinkStyle};
`

const TermsAndConditionsCheckboxLabel = () => (
  <span>
    By placing this order, I agree to purchase this transcript under the terms and details, noted above, and my contractual agreement with Forsta.
  </span>
)

const OpenTranscriptsPanelLink = styled(Link)`
  color: ${electricBlue};
  cursor: pointer;
`

const SuccessMessage = () => (
  <div>
    You have successfully ordered a human transcript for your video.
  </div>
)

const FailureMessageContainer = styled.p`
  ${paddingTop('xs')};
  ${paddingBottom('xs')};
  ${paddingLeft('xs')};
`

const FailureMessage = (message) => (
  <FailureMessageContainer>
    {message || "Something went wrong when trying to order your transcript. Please reach out to support for assistance."}
  </FailureMessageContainer>
)

const OrderHumanTranscriptionModal = ({
  insightsVideo,
  response: { title, project },
  video,
  createVideoTranscript,
  createVideoAnalysis,
  notify,
  children
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    false
  )

  const tryCreateVideoTranscript = async () => {
    let result
    try {
      result = await createVideoTranscript.mutation({
        videoId: video.id
      })
    } catch (e) {
      if (e.message.includes('409')) {
        result = { error: "Your human transcript was already requested automatically, as per the study settings." }
      } else if (e.message.includes('422')) {
        result = { error: "You can’t order a human transcript for non-English projects." }
      } else {
        result = { error: "Something went wrong when trying to order your transcript. Please reach out to support for assistance." }
      }
    }
    return result
  }

  return (
    <Modal
      render={({ hide }) => (
        <Dialog width="600px">
          <Header>
            <HumanTranscriptIcon viewBox="0 0 70 75" size="3rem" />
            <Title>Order a human transcript for '{title}'</Title>
          </Header>
          <Dialog.Body>
            <CostDetails>
              <CostDetailsHeader>
                <div>
                  <h5>Human Transcription</h5>
                  <p>Estimated Delivery: 2 business days</p>
                </div>
                <div>
                  <h3>${HUMAN_TRANSCRIPTION_PRICE_PER_HOUR}/hour</h3>
                </div>
              </CostDetailsHeader>
              <DetailsTable show={showDetails}>
                <DetailsTableRow>
                  <span>Video Title</span>
                  <span>{title}.mp4</span>
                </DetailsTableRow>
                <DetailsTableRow>
                  <span>Video ID</span>
                  <span>{video.id}</span>
                </DetailsTableRow>
                <DetailsTableRow>
                  <span>Video Length</span>
                  <span>{formatTime(video.length)}</span>
                </DetailsTableRow>
                <DetailsTableRow>
                  <span>Project</span>
                  <span>{project.name}</span>
                </DetailsTableRow>
                <DetailsTableRow>
                  <span>Project ID</span>
                  <span>{project.id}</span>
                </DetailsTableRow>
                {project.sourceId && (
                  <DetailsTableRow>
                    <span>Source ID</span>
                    <span>{project.sourceId}</span>
                  </DetailsTableRow>
                )}
              </DetailsTable>
              <Button
                kind="utility"
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails ? 'Hide Details' : 'Show Details'}
              </Button>
              <CostDisclaimer>
                Actual cost may vary dependent on your contract rates.
                <br />
                Delivery date is based on an average 48 hour turn around.
              </CostDisclaimer>
            </CostDetails>
            <LearnMoreParagraph>
              <ExternalLink
                href="https://forstavideoinsights.zendesk.com/hc/en-us/articles/5873809336475-Transcribing-Videos"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about our transcription services
              </ExternalLink>
            </LearnMoreParagraph>
            <Checkbox
              id="terms-and-conditions-checkbox"
              checked={termsAndConditionsAccepted}
              onChange={e => setTermsAndConditionsAccepted(e.target.checked)}
              label={<TermsAndConditionsCheckboxLabel />}
            />
          </Dialog.Body>
          <Dialog.Footer>
            <Button kind="ghost" ghost onClick={hide}>
              Cancel
            </Button>
            <Button
              kind="primary"
              disabled={
                createVideoTranscript.loading ||
                createVideoAnalysis.loading ||
                !termsAndConditionsAccepted
              }
              onClick={async () => {
                const response = await tryCreateVideoTranscript()
                if (response.error) {
                  return notify('danger', FailureMessage(response.error), { sticky: true })
                }

                if (!response.data.createVideoTranscript) {
                  return notify('danger', FailureMessage(), { sticky: true })
                }

                if (insightsVideo) {
                  try {
                    await createVideoAnalysis.mutation({
                      videoId: insightsVideo.id,
                      transcriptType: 'HUMAN'
                    })
                  } catch (e) {
                    return notify('danger', FailureMessage(), { sticky: true })
                  }
                }

                notify('success', <SuccessMessage />, { sticky: true })
                hide()
              }}
            >
              {createVideoTranscript.loading || createVideoAnalysis.loading ? (
                <Loader />
              ) : (
                'Place order'
              )}
            </Button>
          </Dialog.Footer>
        </Dialog>
      )}
    >
      {children}
    </Modal>
  )
}

export default compose(
  withNotifier,
  withMutations
)(OrderHumanTranscriptionModal)
