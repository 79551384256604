import React, { Component, createContext, createRef } from 'react'

const VideoControllerContext = createContext({})
const { Consumer, Provider } = VideoControllerContext

const initialClipPlaybackState = {
  playingClipIndex: -1,
  restrictPlaybackToClips: false,
  clips: []
}

class VideoControllerProvider extends Component {
  state = {
    loading: true,
    playing: false,
    ended: false,
    currentTime: 0,
    duration: 0,
    ...initialClipPlaybackState
  }

  constructor(props) {
    super(props)
    this.videoPlayerRef = createRef()
  }

  // VideoJs Event Handlers
  // We trigger a state change on each trigger
  // This could be expensive for the handleTimeUpdate
  // We should refactor with subscriber pattern if we notice
  // performance issues

  handleOnEnded = () =>
    this.setState({
      ended: true,
      playing: false
    })
  handleOnLoadedMetaData = () =>
    this.setState({
      loading: false,
      duration: this.getDuration()
    })
  handleOnPause = () => this.setState({ playing: false })
  handleOnPlay = () => this.setState({ playing: true })
  handleTimeUpdate = () => {
    const { clips, playingClipIndex, restrictPlaybackToClips } = this.state
    const currentTime = this.getCurrentTime()
    const playingClip = clips[playingClipIndex]

    if (!clips.length) {
      // No clips are provided, exit early
      return this.setState({ currentTime })
    }
    if (!restrictPlaybackToClips) {
      // When `restrictPlaybackToClips` is not enabled, the playhead controls `playingClipIndex`
      return this.setState({
        currentTime,
        playingClipIndex: this.findClipIndex(currentTime)
      })
    }
    if (
      playingClip.endTime > currentTime &&
      playingClip.startTime <= currentTime
    ) {
      // We are playing a clip, do nothing
      return this.setState({ currentTime })
    }
    if (
      playingClip.endTime <= currentTime &&
      currentTime - playingClip.endTime < 0.3
    ) {
      // Player has reached end of current clip
      const nextClipIndex = playingClipIndex + 1
      const nextClip = clips[nextClipIndex]

      if (nextClip) {
        // Go to next clip
        this.seek(nextClip.startTime)
        this.play()
        return this.setState({ playingClipIndex: nextClipIndex, currentTime })
      }

      // We have reached the end of the last clip
      this.pause()
      return this.setState({
        playingClipIndex: -1,
        currentTime,
        restrictPlaybackToClips: false
      })
    }

    // User has seeked video
    const newClipIndex = this.findClipIndex(currentTime)
    if (newClipIndex === -1) {
      // User has seeked outside of a clip
      return this.setState({
        playingClipIndex: -1,
        restrictPlaybackToClips: false,
        currentTime
      })
    }

    this.setState({
      currentTime,
      playingClipIndex: newClipIndex
    })
  }

  findClipIndex = time =>
    this.state.clips.findIndex(
      ({ startTime, endTime }) => startTime <= time && endTime >= time
    )

  // Player actions

  play = () => this.videoPlayerRef.current.play()
  pause = () => this.videoPlayerRef.current.pause()
  seek = time => this.videoPlayerRef.current.setTime(time)
  seekAndPlay = time => {
    this.seek(time)
    this.play()
  }
  playClips = (clips = []) => {
    const [{ startTime }] = clips

    if (typeof startTime !== 'undefined') {
      this.seek(startTime)
      this.play()
      this.setState({
        ...initialClipPlaybackState,
        restrictPlaybackToClips: true,
        playingClipIndex: 0,
        clips
      })
    }
  }

  // Helpers

  getDuration = () =>
    this.videoPlayerRef && this.videoPlayerRef.current.duration()
  getCurrentTime = () =>
    this.videoPlayerRef && this.videoPlayerRef.current.currentTime()

  // Wraps children with provider and passes context state

  render() {
    const { children } = this.props
    const {
      loading,
      playing,
      ended,
      currentTime,
      duration,
      clips,
      playingClipIndex
    } = this.state
    const {
      handleLoadedMetaData,
      handleOnPause,
      handleOnPlay,
      handleOnEnded,
      handleTimeUpdate,
      videoPlayerRef,
      play,
      playClips,
      pause,
      seek,
      seekAndPlay
    } = this

    return (
      <Provider
        value={{
          handleLoadedMetaData,
          handleOnPause,
          handleOnPlay,
          handleOnEnded,
          handleTimeUpdate,
          videoPlayerRef,
          loading,
          playing,
          ended,
          currentTime,
          duration,
          play,
          playClips,
          pause,
          seek,
          seekAndPlay,
          clips,
          playingClipIndex
        }}
      >
        {children}
      </Provider>
    )
  }
}

export default VideoControllerContext
export { Consumer as VideoControllerConsumer, VideoControllerProvider }
