import React from 'react'
import Svg from 'components/Svg'

const TranscriptsIcon = props => (
  <Svg viewBox="0 0 18 18" {...props}>
    <title>transcripts</title>
    <g>
      <path d="M14.61 6V1.875a1.5 1.5 0 00-1.5-1.5h-9a1.5 1.5 0 00-1.5 1.5v12a1.5 1.5 0 001.5 1.5h6l2.25 2.25v-2.25h.75a1.5 1.5 0 001.29-.736" />
      <path d="M1.114 2.823a1.5 1.5 0 00-.755 1.302v12a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.284-.725M4.11 2.625h3M5.61 6V2.625M11.775 5.416l-3.15-.9.9 3.15 5.384 5.384a1.591 1.591 0 002.25-2.25l-5.384-5.384zM14.234 12.375l2.25-2.25M9.525 7.666l2.25-2.25M9.36 2.625h3M4.86 8.625h3M4.86 10.875h4.874M4.86 13.125h6.75" />
    </g>
  </Svg>
)

export default TranscriptsIcon
