import { replacePunctuations } from 'utils/regex'

export const findTimestampIndex = (
  items,
  timestampKey,
  timestamp,
  accumulateIndex = 0
) => {
  if (items.length === 1) {
    return items[0][timestampKey] === timestamp ? accumulateIndex : -1
  }

  const breakPoint = Math.floor(items.length / 2)
  const breakPointItem = items[breakPoint]
  const diff = parseFloat(breakPointItem[timestampKey]) - parseFloat(timestamp)

  if (diff === 0) {
    return breakPoint + accumulateIndex
  } else if (diff > 0) {
    return findTimestampIndex(
      items.slice(0, breakPoint),
      timestampKey,
      timestamp,
      accumulateIndex
    )
  } else {
    return findTimestampIndex(
      items.slice(breakPoint, items.length),
      timestampKey,
      timestamp,
      accumulateIndex + breakPoint
    )
  }
}

export const findTimestamps = ({
  items,
  text,
  startTime,
  endTime,
  skip = 0
}) => {
  const startIndex = startTime
    ? findTimestampIndex(items, 'startTime', startTime)
    : 0
  const endIndex = endTime
    ? findTimestampIndex(items, 'endTime', endTime)
    : items.length - 1
  const curatedItems = items
    .slice(startIndex, endIndex + 1)
    .filter(({ type }) => type === 'pronunciation')
    .map(({ startTime, endTime, alternatives: [{ content }] }) => ({
      text: replacePunctuations(content, ''),
      startTime,
      endTime
    }))
  const tokens = text
    .trim()
    .split(' ')
    .filter(token => token)
    .map(token => replacePunctuations(token, ''))

  let result = []
  let curIdx = 0 + skip

  while (curIdx <= curatedItems.length - 1) {
    let tokensIdx = 0

    while (tokens[tokensIdx]) {
      const item = curatedItems[curIdx + tokensIdx]

      if (!item || item.text !== tokens[tokensIdx]) {
        break
      }

      if (item.text === tokens[tokensIdx] && tokensIdx === tokens.length - 1) {
        result = [curatedItems[curIdx].startTime, item.endTime]
        break
      }
      tokensIdx++
    }

    if (result.length > 0) {
      break
    }

    curIdx++
  }

  return result.length ? result : [startTime, endTime]
}

export const precendentOccurrence = ({ tokenCatalog, start, ordinal }) => {
  if (!tokenCatalog || !start) return 0

  const list = tokenCatalog[ordinal]
  const idx = list.findIndex(([key]) => key === start)
  const [, text] = list[idx]
  const precedent = list.slice(0, idx)

  let count = 0
  for (let i = 0; i < precedent.length; i++) {
    if (precedent[i][1] === text) {
      count++
    }
  }

  return count
}
