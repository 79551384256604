export const paramsForLocation = ({ search }) => new URLSearchParams(search)
export const valuesFromLocation = (location, paramName) => {
  const params = paramsForLocation(location)
  return valuesFromParams(params, paramName)
}
export const valuesFromParams = (params, paramName) => {
  const value = params.get(paramName)
  if (!value) {
    return []
  }
  return value.split(',')
}

const valuesAfterToggle = (existingValues, value) =>
  existingValues.includes(value)
    ? existingValues.filter(v => v !== value)
    : [...existingValues, value]

export const toggleParamListOption = (params, paramName, value) => {
  const existingValues = valuesFromParams(params, paramName)
  const newValues = valuesAfterToggle(existingValues, value)
  if (newValues.length) {
    params.set(paramName, newValues.join(','))
  } else {
    params.delete(paramName)
  }
}

export const clearParamListOption = (params, paramName) => {
  params.delete(paramName)
}
