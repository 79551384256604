import { compose, join, map, mapProp, prop, valueOr } from 'utils/fp'

export const parseMessages = compose(
  join(', '),
  map(prop('message')),
  valueOr([])
)

export const connectionNodes = compose(
  mapProp('node'),
  prop('edges'),
  valueOr({ edges: [] })
)

// `hasSubscription` function is copied from
// https://github.com/jumpn/utils-graphql/blob/master/src/hasSubscription.js
const isSubscription = definition =>
  definition.kind === 'OperationDefinition' &&
  definition.operation === 'subscription'

export const hasSubscription = documentNode =>
  documentNode.definitions.some(isSubscription)
