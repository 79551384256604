import { replaceSpaces } from 'utils/regex'

export const getSelectionRangeClientRect = () => {
  const selection = window.getSelection()
  const { rangeCount } = selection
  const {
    x,
    y,
    width,
    height: heightX,
    top,
    right,
    left
  } = selection.getRangeAt(0).getBoundingClientRect()
  const { height: heightY, bottom } = selection
    .getRangeAt(rangeCount - 1)
    .getBoundingClientRect()

  return {
    x,
    y,
    width,
    height: heightX + heightY,
    top,
    right,
    bottom,
    left
  }
}

export const getSelectedText = () => {
  const selection = window.getSelection()
  let rangeIndex = 0
  let selectedText = ''

  while (rangeIndex < selection.rangeCount) {
    const range = selection.getRangeAt(rangeIndex).cloneRange()

    if (range.startContainer.nodeType === Node.TEXT_NODE) {
      while (!range.toString().startsWith(' ') && range.startOffset > 0) {
        range.setStart(range.startContainer, range.startOffset - 1)
      }

      if (
        range.toString().startsWith(' ') &&
        range.startContainer.length > range.startOffset
      ) {
        range.setStart(range.startContainer, range.startOffset + 1)
      }
    }

    if (range.endContainer.nodeType === Node.TEXT_NODE) {
      while (
        !range.toString().endsWith(' ') &&
        range.endOffset < range.endContainer.length &&
        range.endContainer.nodeType === Node.TEXT_NODE
      ) {
        range.setEnd(range.endContainer, range.endOffset + 1)
      }

      if (range.toString().endsWith(' ') && range.endOffset > 0) {
        range.setEnd(range.endContainer, range.endOffset - 1)
      }
    }

    selectedText = selectedText + range.toString()

    rangeIndex++
  }

  return replaceSpaces(selectedText)
}
