import React from 'react'
import withQueryParams from 'components/withQueryParams'
import { paramsForLocation } from 'utils/params'
import PositiveIcon from 'components/icons/Positive'
import NegativeIcon from 'components/icons/Negative'
import NeutralIcon from 'components/icons/Neutral'
import MixedIcon from 'components/icons/Mixed'
import AnalysisToggle from 'components/AnalysisToggle'
import AnalysisContainer from 'components/AdvancedEditor/AnalysisLeftSidebar/AnalysisContainer'
import { styled, marginRight } from '@fv/design-tokens'

const SentimentDescription = styled.span`
  display: flex;
  align-items: center;

  svg:first-of-type {
    width: 1.5em;
    height: 1.5em;
    ${marginRight('xs')};
  }
`

const SentimentOptions = ({ history, location }) => {
  const params = paramsForLocation(location)
  return (
    <AnalysisContainer>
      <AnalysisToggle paramKey="sentiment" paramValue="positive" query={params}>
        <SentimentDescription>
          <PositiveIcon />
          Positive
        </SentimentDescription>
      </AnalysisToggle>
      <AnalysisToggle paramKey="sentiment" paramValue="negative" query={params}>
        <SentimentDescription>
          <NegativeIcon />
          Negative
        </SentimentDescription>
      </AnalysisToggle>
      <AnalysisToggle paramKey="sentiment" paramValue="neutral" query={params}>
        <SentimentDescription>
          <NeutralIcon />
          Neutral
        </SentimentDescription>
      </AnalysisToggle>
      <AnalysisToggle paramKey="sentiment" paramValue="mixed" query={params}>
        <SentimentDescription>
          <MixedIcon />
          Mixed
        </SentimentDescription>
      </AnalysisToggle>
    </AnalysisContainer>
  )
}

export default withQueryParams(SentimentOptions)
