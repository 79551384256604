import React, { useState } from 'react'
import { Button } from 'stylist'
import StateTransition from './StateTransition/index'
import Panel from './StateTransition/components/Panel'
import Tabs from './StateTransition/components/index'

const A_YEAR_IN_MILLISECOND = 1000 * 60 * 60 * 24 * 365

const Walkthrough = ({ content = [], stateMap = [], storageKey, hide }) => {
  const [current, setCurrent] = useState(1)

  return (
    <Tabs>
      <StateTransition currentState={current} stateMap={stateMap}>
        <Panel
          title={content[current].title}
          imgSrc={content[current].imgSrc}
          text={content[current].text}
        />
      </StateTransition>
      <Tabs.Controls>
        {current === 1 ? (
          <Button
            kind="ghost"
            type="button"
            onClick={() => {
              localStorage.setItem(
                storageKey,
                new Date().getTime() + A_YEAR_IN_MILLISECOND
              )
              hide()
            }}
          >
            No thanks
          </Button>
        ) : (
          <Button
            type="button"
            kind="ghost"
            onClick={() => setCurrent(current - 1)}
          >
            Back
          </Button>
        )}
        <Tabs.Links
          currentState={current}
          stateMap={stateMap}
          onSelect={tabState => setCurrent(tabState)}
        />
        {current < stateMap.length ? (
          <Button
            kind="primary"
            type="button"
            onClick={() => setCurrent(current + 1)}
          >
            {current === 1 ? 'Take the tour' : 'Next'}
          </Button>
        ) : (
          <Button
            type="button"
            kind="primary"
            onClick={() => {
              localStorage.setItem(
                storageKey,
                new Date().getTime() + A_YEAR_IN_MILLISECOND
              )
              hide()
            }}
          >
            Got it!
          </Button>
        )}
      </Tabs.Controls>
    </Tabs>
  )
}

export default Walkthrough
