import gql from 'graphql-tag'
import { ProjectSelectorOption_project } from './ProjectSelectorOption.fragments'

const path =
  '/projects' +
  '?filter[company]={args.companyId}' +
  '&filter[user_roles]=default' +
  '&filter[name]={args.name}' +
  '&fields[projects]=name'

const VI_PROJECTS_QUERY = gql`
  query VICopyClipModalProjectsQuery($companyId: String!, $name: String) {
    projects(companyId: $companyId, name: $name)
      @jsonapi(
        path: "${path}"
        includeJsonapi: true
      ) {
      graphql {
        ... on VIProjects {
          id
          ...ProjectSelectorOption_project
        }
      }
      jsonapi {
        links {
          next
        }
      }
    }
  }

  ${ProjectSelectorOption_project}
`

export default VI_PROJECTS_QUERY
