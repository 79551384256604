import gql from 'graphql-tag'

export const HighlightClip_clip = gql`
  fragment HighlightClip_clip on Clip {
    id
    startTime
    endTime
    text
    sentiment
    highlightScore
  }
`
