import React, { Component, forwardRef } from 'react'
import { withRouter, Link } from 'react-router-dom'
import hasAttr, { notHasAttr } from 'utils/hasAttr'

class QueryParamLink extends Component {
  static defaultProps = {
    component: Link,
    toggle: false,
    toParams: {},
    activeWhen: {}
  }

  state = {
    active: undefined,
    prevSearch: ''
  }

  static getDerivedStateFromProps = (nextProps, state) => {
    const {
      toParams,
      activeWhen,
      location: { search: nextSearch },
      toggle
    } = nextProps

    if (state.prevSearch && state.prevSearch === nextSearch) {
      return null
    }

    // Create param interface
    const searchParams = new URLSearchParams(nextSearch)

    // Determine if active
    const [activeParam, activeQuery] = Object.entries(activeWhen)[0]
    const active = searchParams.get(activeParam) === activeQuery || undefined

    // Store previous search before mutating params
    const prevSearch = searchParams.toString()

    // Set or delete params
    if (active && toggle) {
      Object.keys(toParams).forEach(param => searchParams.delete(param))
    } else {
      Object.entries(toParams).forEach(([key, value]) =>
        key && value !== undefined
          ? searchParams.set(key, value)
          : searchParams.delete(key)
      )
    }

    // Set next search for render
    const search = searchParams.toString()

    return { active, prevSearch, search }
  }

  render() {
    const {
      activeWhen,
      children,
      className,
      component: Component,
      round,
      staticContext,
      square,
      toggle,
      toParams,
      forwardRef,
      ...rest
    } = this.props
    const { active, search } = this.state

    return (
      <Component
        ghost={notHasAttr(active)}
        active={hasAttr(active)}
        round={hasAttr(round)}
        square={hasAttr(square)}
        toggle={hasAttr(toggle)}
        to={{ search }}
        className={className}
        children={children}
        ref={forwardRef}
        {...rest}
      />
    )
  }
}

const WithRouter = withRouter(QueryParamLink)

export default forwardRef((props, ref) => (
  <WithRouter {...props} forwardRef={ref} />
))
