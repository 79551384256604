import gql from 'graphql-tag'

export const TranscriptSettings_machineAnalysis = gql`
  fragment TranscriptSettings_machineAnalysis on VideoAnalysis {
    __typename
    id
    transcriptType
    updatedAt
    transcriptionCompletedAt
    transcriptionErroredAt
    transcriptionStartedAt
    entitiesCompletedAt
    entitiesErroredAt
    entitiesStartedAt
    keyPhrasesCompletedAt
    keyPhrasesErroredAt
    keyPhrasesStartedAt
    highlightAnalysisCompletedAt
    highlightAnalysisErroredAt
    highlightAnalysisStartedAt
  }
`

export const TranscriptSettings_humanAnalysis = gql`
  fragment TranscriptSettings_humanAnalysis on VideoAnalysis {
    __typename
    id
    transcriptType
    updatedAt
    transcriptionCompletedAt
    transcriptionErroredAt
    transcriptionStartedAt
    entitiesCompletedAt
    entitiesErroredAt
    entitiesStartedAt
    keyPhrasesCompletedAt
    keyPhrasesErroredAt
    keyPhrasesStartedAt
    highlightAnalysisCompletedAt
    highlightAnalysisErroredAt
    highlightAnalysisStartedAt
  }
`
