import React from 'react'
import { styled, borderRadius, marginBottom, padding } from '@fv/design-tokens'
import { lightRed } from 'styles/color'
import Form from 'components/Form'

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  background: ${lightRed};
  ${padding('xs')};
  ${marginBottom('xxs')};
  ${borderRadius('s')};
`

const FormError = ({ name }) => (
  <Form.Error name={name}>
    {({ message }) => <ErrorMessage>{message}</ErrorMessage>}
  </Form.Error>
)

export default FormError
