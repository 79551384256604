import React from 'react'
import FolderIcon from 'components/icons/Folder'
import { ChevronIcon, spacer } from 'stylist'
import styled from '@emotion/styled'
import { offWhite2 } from 'styles/color'

const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${spacer(3)};

  & + & {
    border-top: 1px solid ${offWhite2};
  }

  svg {
    stroke-width: 2.2;
  }
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 390px;
  max-width: 390px;

  span {
    margin-left: ${spacer(2)};
    max-width: 356px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const YourShowreelsFilter = ({ onFilterClick }) => {
  return (
    <Item onClick={onFilterClick}>
      <ItemContainer>
        <FolderIcon />
        <span>Your Showreels</span>
      </ItemContainer>
      <ChevronIcon size="m" direction="right" />
    </Item>
  )
}

export default YourShowreelsFilter
