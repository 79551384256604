import React, { createContext, useEffect, useState } from 'react'
import {
  insightsItemsToSentences,
  transcriptToSentences
} from 'utils/transcripts'
import dig from 'utils/dig'
import noop from 'utils/noop'
import { findTimestamps } from './utils'

const TranscriptContext = createContext({
  sentences: [],
  dispatch: noop,
  findTimestamps: noop
})

export const TranscriptProvider = ({ video, videoAnalysis = {}, children }) => {
  const transcript =
    dig(videoAnalysis, 'transcript') ||
    dig(video, 'videoTranscript', 'transcript')
  const timestamps = dig(video, 'videoTranscript', 'timestamps') || []
  const [sentences, setSentences] = useState([])

  const videoAnalysisId = videoAnalysis ? videoAnalysis.id : -1

  useEffect(() => {
    const insightsTranscriptItems = dig(
      videoAnalysis,
      'transcript',
      'rawTranscript',
      'results',
      'items'
    )

    setSentences(
      insightsTranscriptItems
        ? insightsItemsToSentences(insightsTranscriptItems)
        : transcript
        ? transcriptToSentences(transcript, timestamps)
        : []
    )
  }, [transcript, videoAnalysisId])

  return (
    <TranscriptContext.Provider
      value={{
        sentences,
        transcript,
        findTimestamps: ({ text, startTime, endTime, skip }) => {
          if (!transcript || !transcript.rawTranscript) {
            return []
          }

          const {
            rawTranscript: {
              results: { items }
            }
          } = transcript

          return findTimestamps({
            items,
            text,
            startTime,
            endTime,
            skip
          })
        }
      }}
    >
      {children}
    </TranscriptContext.Provider>
  )
}

export default TranscriptContext
