import React, { useContext, useEffect, useRef, useState } from 'react'
import { borderRadius, boxShadow, css, styled } from '@fv/design-tokens'
import { offWhite2 } from 'styles/color'
import NewCreateClipButton from 'components/AdvancedEditor/NewCreateClipButton'
import NewPlaybackToggle from 'components/AdvancedEditor/NewPlaybackToggle'
import VideoControllerContext from 'components/VideoPlayer/VideoControllerContext'
import Tooltip from 'components/Tooltip/SimpleTooltip'

const controlPanelPositionCss = ({ top = 0, left = 0, theme }) => css`
  top: ${top}px;
  left: ${left}px;
`

const controlPanelShowCss = ({ show, theme }) =>
  show &&
  css`
    opacity: 1;
    z-index: 5;
  `

const ControlBar = styled.div`
  display: flex;
  margin: auto;
  position: absolute;
  opacity: 0;
  background: ${offWhite2};
  transition: opacity 0.15s;
  overflow: hidden;
  z-index: -1;
  ${borderRadius('m')};
  ${boxShadow('distant')};
  ${controlPanelPositionCss};
  ${controlPanelShowCss};
`

const getControlBarHeight = barEl =>
  barEl ? barEl.getBoundingClientRect().height : 0
const getControlBarWidth = barEl =>
  barEl ? barEl.getBoundingClientRect().width : 0

const ClipControlBar = ({
  canCreateClip,
  startTime,
  endTime,
  show,
  top,
  left,
  center,
  placement
}) => {
  const controlBarRef = useRef(null)
  const { playClips, pause, playing, currentTime } = useContext(
    VideoControllerContext
  )
  const [controlBarHeight, setControlBarHeight] = useState(0)
  const [controlBarWidth, setControlBarWidth] = useState(0)
  const isPlaying = currentTime >= startTime && currentTime <= endTime

  useEffect(() => {
    if (controlBarRef.current) {
      setControlBarHeight(getControlBarHeight(controlBarRef.current))
      setControlBarWidth(getControlBarWidth(controlBarRef.current))
    }
  }, [controlBarRef.current])

  return (
    <ControlBar
      top={placement === 'top' ? top - controlBarHeight : top}
      left={center ? left - controlBarWidth / 2 : left}
      show={controlBarHeight && controlBarWidth}
      ref={controlBarRef}
    >
      <Tooltip
        placement="left"
        render={() => (playing && isPlaying ? 'Pause' : 'Play')}
      >
        {({ show, hide, ref }) => (
          <NewPlaybackToggle
            ghost
            square
            onClick={() =>
              playing && isPlaying
                ? pause()
                : playClips([{ startTime, endTime }])
            }
            onMouseOver={show}
            onMouseOut={hide}
            ref={ref}
            isActivePlayer={isPlaying}
            playing={playing}
          />
        )}
      </Tooltip>
      {canCreateClip && (
        <Tooltip placement="right" render={() => 'Create clip'}>
          {({ show, hide, ref }) => (
            <NewCreateClipButton
              ghost
              square
              startTime={startTime}
              endTime={endTime}
              isCurrentText={isPlaying}
              onMouseOver={show}
              onMouseOut={hide}
              ref={ref}
            />
          )}
        </Tooltip>
      )}
    </ControlBar>
  )
}

export default ClipControlBar
