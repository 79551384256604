import React from 'react'
import Svg from 'components/Svg'

const CaretDownIcon = props => (
  <Svg viewBox="0 0 16 16" {...props}>
    <title>{props.title || 'Caret Down'}</title>
    <path
      transform="translate(1.000000, 4.000000)"
      d="M7,8.3C6.8,8.3,6.6,8.2,6.4,8l-6.5-7.3C-0.2,0.5-0.2,0.2,0,0c0.2-0.2,0.5-0.2,0.7,0L7,7.2L13.4,0c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7L7.6,8C7.5,8.2,7.2,8.3,7,8.3z"
    />
  </Svg>
)

export default CaretDownIcon
