import gql from 'graphql-tag'

const FEATURES_QUERY = gql`
  query FeaturesQuery {
    viewer {
      features
    }
  }
`

export default FEATURES_QUERY
