import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import Loader from 'components/Loader'
import CurrentUserReels from 'components/AdvancedEditor/ReelBuilder/ReelsIndex/CurrentUserReels'
import ProjectReels from 'components/AdvancedEditor/ReelBuilder/ReelsIndex/ProjectReels'
import VI_REELS_QUERY from './ReelSelector.query'

const ReelSelector = ({
  onReelSelect,
  reelId,
  projectId,
  selectedReelId,
  activeTab
}) => {
  const { data: { reels = [] } = {}, loading } = useQuery(VI_REELS_QUERY, {
    variables: { projectId },
    fetchPolicy: 'cache-and-network'
  })

  return loading && !reels.length ? (
    <Loader />
  ) : activeTab === 'allReels' ? (
    <ProjectReels
      projectId={projectId}
      onReelSelect={onReelSelect}
      excludeReelIds={[reelId]}
      selectedReelId={selectedReelId}
    />
  ) : (
    <CurrentUserReels
      projectId={projectId}
      onReelSelect={onReelSelect}
      excludeReelIds={[reelId]}
      selectedReelId={selectedReelId}
    />
  )
}

export default ReelSelector
