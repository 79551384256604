import React, { useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { ModalV2 as Modal, Dialog } from 'stylist'
import Walkthrough from './Walkthrough'
import storage from 'utils/storage'
import noop from 'utils/noop'

const StyledDialog = styled(Dialog)`
  overflow: hidden;
  width: 720px;
  header {
    padding-bottom: 0;
    min-width: auto;
    h4 {
      margin-top: 0;
    }
  }
`

const StyledDialogBody = styled(Dialog.Body)`
  padding: 0 2.25rem 1.25rem 2.25rem;
`

const ONE_SECOND_IN_MILLISECOND = 1000

const A_DAY_IN_MILLISECOND = ONE_SECOND_IN_MILLISECOND * 60 * 60 * 24

const shouldShowModal = storageValue => {
  if (storageValue === 'hide') {
    return false
  }

  if (!Number.isInteger(parseInt(storageValue))) {
    return true
  }

  const now = new Date().getTime()

  return now - storageValue > A_DAY_IN_MILLISECOND
}

const OnboardingModal = ({ content, storageKey }) => {
  const modalRef = useRef(null)
  const { storageValue } = storage(storageKey)

  useEffect(() => {
    if (shouldShowModal(storageValue)) {
      modalRef.current.show()
    }
  }, [])

  return (
    <Modal
      ref={modalRef}
      storageValue={storageValue}
      onDismiss={() => localStorage.setItem(storageKey, new Date().getTime())}
      render={({ hide }) => (
        <StyledDialog>
          <Dialog.Header
            onClose={() => {
              hide()
              localStorage.setItem(storageKey, new Date().getTime())
            }}
          >
            <Dialog.Title />
          </Dialog.Header>
          <StyledDialogBody>
            <Walkthrough
              content={content}
              stateMap={Object.keys(content).map(key => parseInt(key))}
              storageKey={storageKey}
              hide={hide}
            />
          </StyledDialogBody>
        </StyledDialog>
      )}
    >
      {noop}
    </Modal>
  )
}

export default OnboardingModal
