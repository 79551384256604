import React from 'react'
import { styled, background, color, borderColor } from '@fv/design-tokens'
import MoreIcon from 'components/icons/More'
import UtilityButton from './UtilityButton'

const StyledButton = styled(UtilityButton)`
  ${background('buttonPlayBackground')};
  ${borderColor('buttonPlayBorderColor')};
  ${color('buttonPlayText')};

  &:hover {
    ${background('buttonPlayBackgroundHover')};
    ${borderColor('buttonPlayBorderColorHover')};
    ${color('buttonPlayTextHover')};
  }

  &:active {
    ${background('buttonPlayBackgroundActive')};
    ${borderColor('buttonPlayBorderColorActive')};
    ${color('buttonPlayTextActive')};
  }
`

const MoreButton = ({ ...rest }) => (
  <StyledButton {...rest}>
    <MoreIcon direction="horizontal" />
  </StyledButton>
)

export default MoreButton
