import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const VI_PUBLISH_REEL_TO_PRESENTATION = gql`
  mutation VIPublishReelToPresentation($id: String!) {
    publishReelToPresentation(input: $input)
      @jsonapi(path: "/reels/{args.input.data.id}", method: "PATCH") {
      id
      publishedToPresentationFl
    }
  }
`

const usePublishReelToPresentationMutation = options => {
  const [mutate, result] = useMutation(VI_PUBLISH_REEL_TO_PRESENTATION, options)

  const publishReelToPresentation = (reel, publishedToPresentation = true) =>
    mutate({
      variables: {
        input: {
          data: {
            type: 'reels',
            id: reel.id,
            attributes: {
              published_to_presentation_fl: publishedToPresentation
            }
          }
        }
      },
      optimisticResponse: {
        publishReelToPresentation: {
          ...reel,
          publishedToPresentationFl: publishedToPresentation
        }
      }
    })

  return [publishReelToPresentation, result]
}

export default usePublishReelToPresentationMutation
