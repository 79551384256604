import React from 'react'
import FeatherSvg from 'components/FeatherSvg'

const Check = props => (
  <FeatherSvg {...props}>
    <title>check</title>
    <polyline points="20 6 9 17 4 12" />
  </FeatherSvg>
)

export default Check
