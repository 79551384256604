import React from 'react'
import moment from 'moment'
import dig from 'utils/dig'
import Button from 'components/Button'
import SendVideoToHighlightReel from 'components/mutations/SendVideoToHighlightReel'

const isProcessing = time => time && moment() < moment(time).add(4, 'hours')

const chooseMessage = sentToInsightsAt => {
  if (!sentToInsightsAt) {
    return 'Analyze'
  }
  if (isProcessing(sentToInsightsAt)) {
    return 'Processing'
  }
  return 'Reanalyze'
}

const HighlightButton = ({ video, sendVideoToHighlightReel, ...rest }) => {
  const sentToInsightsAt = dig(video, 'sentToInsightsAt')
  return (
    <SendVideoToHighlightReel video={video}>
      {({ sendVideoToHighlightReel }) => (
        <Button
          disabled={!video || isProcessing(sentToInsightsAt)}
          onClick={() => sendVideoToHighlightReel()}
          {...rest}
        >
          {chooseMessage(sentToInsightsAt)}
        </Button>
      )}
    </SendVideoToHighlightReel>
  )
}

export default HighlightButton
