import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { PUBLISH_STATUSES } from 'components/AdvancedEditor/ReelBuilder/utils'

export const VI_FINALIZE_REEL = gql`
  mutation VIFinalizeReel($id: String!) {
    finalizeReel(id: $id)
      @jsonapi(path: "/reels/{args.id}/finalize", method: "POST") {
      id
      publishStatus
    }
  }
`

const useFinalizeReelMutation = options => {
  const [mutate, result] = useMutation(VI_FINALIZE_REEL, options)

  const finalizeReel = ({ reel }) =>
    mutate({
      variables: {
        id: reel.id
      },
      optimisticResponse: {
        finalizeReel: {
          ...reel,
          publishStatus: PUBLISH_STATUSES.REQUESTED
        }
      }
    })

  return [finalizeReel, result]
}

export default useFinalizeReelMutation
