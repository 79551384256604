import React from 'react'
import { useLocation } from 'react-router'
import { styled, color } from '@fv/design-tokens'

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Message = styled.h3`
  ${color('systemWarningLt')};
`

const NotFound = () => {
  const { state: { message = 'Page not found.' } = {} } = useLocation()

  return (
    <Container>
      <Message>{message}</Message>
    </Container>
  )
}

export default NotFound
