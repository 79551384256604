import { styled } from '@fv/design-tokens'
import { withProps } from 'recompose'

const CheckboxHidden = withProps({
  type: 'checkbox'
})(styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`)

export default CheckboxHidden
