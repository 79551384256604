const storage = (storageKey, defaultStorageValue = '') => {
  if (!localStorage.getItem(storageKey)) {
    localStorage.setItem(storageKey, defaultStorageValue)
  }

  const storageValue = localStorage.getItem(storageKey)

  return { storageKey, storageValue }
}

export default storage
