import { css } from '@fv/design-tokens'

export const absoluteFill = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const absoluteVerticalAlignCenter = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const absoluteHorizontalAlignCenter = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

export const flexCenterContent = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const flexFill = css`
  display: flex;
  flex: 1;
`

export const fixFill = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
