import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import Container from './Container'

const tooltipRoot = document.getElementById('simple-tooltip-root')

const getTop = ({ refClientRect, tooltipContainerClientRect, placement }) => {
  switch (placement) {
    case 'right-start':
    case 'left-start':
      return refClientRect.top

    case 'right':
    case 'left':
      return (
        refClientRect.top +
        refClientRect.height / 2 -
        tooltipContainerClientRect.height / 2
      )

    case 'right-end':
    case 'left-end':
      return refClientRect.bottom - refClientRect.height

    case 'bottom':
    case 'bottom-start':
    case 'bottom-end':
      return refClientRect.bottom + refClientRect.height

    case 'top':
    case 'top-start':
    case 'top-end':
      return refClientRect.top - tooltipContainerClientRect.height

    default:
      return refClientRect.top
  }
}

const getLeft = ({ refClientRect, tooltipContainerClientRect, placement }) => {
  switch (placement) {
    case 'right':
    case 'right-start':
    case 'right-end':
      return refClientRect.right

    case 'left':
    case 'left-start':
    case 'left-end':
      return refClientRect.left - tooltipContainerClientRect.width

    case 'bottom-start':
    case 'top-start':
      return refClientRect.left

    case 'bottom-end':
    case 'top-end':
      return refClientRect.right - tooltipContainerClientRect.width

    case 'top':
    case 'bottom':
      return (
        refClientRect.left +
        refClientRect.width / 2 -
        tooltipContainerClientRect.width / 2
      )

    default:
      return refClientRect.left
  }
}

const CLOSED = 'closed'
const OPENING = 'opening'
const OPEN = 'open'
const CLOSING = 'closing'

const SimpleTooltip = ({
  placement,
  children,
  transitionTime = 250,
  render
}) => {
  const ref = useRef(null)
  const tooltipContainerRef = useRef(null)
  const [state, setState] = useState(CLOSED)
  const [refClientRect, setRefClientRect] = useState({})
  const [tooltipContainerClientRect, setTooltipContainerClientRect] = useState(
    {}
  )
  const show = () => setState(() => OPENING)
  const hide = () => setState(() => CLOSING)

  useEffect(() => {
    if (tooltipContainerRef.current) {
      setTooltipContainerClientRect(
        tooltipContainerRef.current.getBoundingClientRect()
      )
    }
    if (ref.current) {
      setRefClientRect(ref.current.getBoundingClientRect())
    }
  }, [ref.current, tooltipContainerRef.current, state])

  useEffect(() => setState(state => (state === OPENING ? OPEN : state)), [
    state
  ])

  return (
    <>
      {children({ show, hide, ref })}
      {state !== CLOSED
        ? createPortal(
            <Container
              ref={tooltipContainerRef}
              placement={placement}
              top={getTop({
                refClientRect,
                tooltipContainerClientRect,
                placement
              })}
              left={getLeft({
                refClientRect,
                tooltipContainerClientRect,
                placement
              })}
              transitionDelay={state === OPEN ? 500 : 0}
              open={state === OPEN}
              onTransitionEnd={() =>
                setState(state => (state === CLOSING ? CLOSED : state))
              }
              transitionTime={transitionTime}
            >
              {render()}
            </Container>,
            tooltipRoot
          )
        : null}
    </>
  )
}

export default SimpleTooltip
