import gql from 'graphql-tag'
import {
  TranscriptSettings_humanAnalysis,
  TranscriptSettings_machineAnalysis
} from 'components/AdvancedEditor/TranscriptSettings/TranscriptSettings.fragments'

export const PollAnalysis_videoAnalysis = gql`
  fragment PollAnalysis_videoAnalysis on VideoAnalysis {
    __typename
    id
    transcriptionCompletedAt
    transcriptionErroredAt
    transcriptionStartedAt
    entitiesCompletedAt
    entitiesErroredAt
    entitiesStartedAt
    keyPhrasesCompletedAt
    keyPhrasesErroredAt
    keyPhrasesStartedAt
    highlightAnalysisCompletedAt
    highlightAnalysisErroredAt
    highlightAnalysisStartedAt

    ...TranscriptSettings_machineAnalysis
    ...TranscriptSettings_humanAnalysis
  }

  ${TranscriptSettings_machineAnalysis}
  ${TranscriptSettings_humanAnalysis}
`
