import React from 'react'
import { styled, background, padding } from '@fv/design-tokens'
import Loader from 'components/Loader'
import ReelCard from './ReelCard'

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  ${background('backgroundShaded')};
  ${padding('xs')};
`

const filterOutExcludeReels = (reels, excludeReelIds) =>
  reels.filter(reel => excludeReelIds.indexOf(reel.id) === -1)

const ListContainer = ({
  reels,
  loading,
  next,
  fetchMore,
  selectedReelId,
  onReelSelect,
  excludeReelIds = []
}) => (
  <Container
    onScroll={e => {
      const { clientHeight, scrollHeight, scrollTop } = e.target

      if (scrollTop + clientHeight + 150 > scrollHeight) {
        fetchMore()
      }
    }}
  >
    {filterOutExcludeReels(reels, excludeReelIds).map(reel => (
      <ReelCard
        key={reel.id}
        reel={reel}
        handleClick={onReelSelect}
        selected={selectedReelId === reel.id}
      />
    ))}
    {loading && <Loader />}
  </Container>
)

export default ListContainer
