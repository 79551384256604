import React, { useContext, useRef, useState } from 'react'
import { Provider } from './Context'
import Modal from './Modal'
import CurrentUserContext from 'components/CurrentUser/Context'

const UnpublishReelSpeedBump = ({ reel, children }) => {
  const { hasFeature } = useContext(CurrentUserContext)
  const resolve = useRef(null)
  const [loading, setLoading] = useState(false)

  return (
    <Modal
      name={reel && reel.name}
      loading={loading}
      handleSubmit={() => resolve.current(true)}
      handleCancel={() => resolve.current(false)}
    >
      {({ show: showUnpublishReelModal }) => (
        <Provider
          value={{
            setLoading,
            confirmUnpublishReel: () => {
              if (
                (reel && !reel.publishedToPresentationFl) ||
                !hasFeature('20210426_presentation_space_phase_2')
              ) {
                return Promise.resolve(true)
              }

              return new Promise(resolveFn => {
                showUnpublishReelModal()
                resolve.current = resolveFn
              })
            }
          }}
        >
          {children}
        </Provider>
      )}
    </Modal>
  )
}

export default UnpublishReelSpeedBump
