import React from 'react'
import { styled, padding, fontSize, fontWeight } from '@fv/design-tokens'
import Loader from 'components/Loader'
import { compose, prop, valueOr } from 'utils/fp'
import { connectionNodes } from 'utils/graphql'
import { chooseProcessingMessage } from 'utils/analysis'
import KeyPhraseOccurrences from './KeyPhraseOccurrences'
import EntityOccurrences from './EntityOccurrences'
import AnalysisOccurrences from './AnalysisOccurrences'
import AnalysisDetails from './AnalysisDetails'
import SentimentOptions from 'components/SentimentOptions'
import { clearParamListOption } from 'utils/params'
import withQueryParams from 'components/withQueryParams'

const Container = styled.div`
  ${padding('m')};
`

const digAnalysisData = (data, key) =>
  compose(
    connectionNodes,
    prop(key),
    valueOr({})
  )(data)

const Title = styled.h4`
  ${fontSize('m')};
  ${fontWeight('bold')};
`

const AnalysisLeftSidebar = ({
  video,
  loading,
  location,
  videoAnalysis,
  query: params,
  history
}) => {
  const entitiesOccurrences = digAnalysisData(
    videoAnalysis,
    'entitiesOccurrences'
  )
  const keyPhrasesOccurrences = digAnalysisData(
    videoAnalysis,
    'keyPhrasesOccurrences'
  )

  const clearAll = type => () => {
    const { pathname } = location
    clearParamListOption(params, type)
    history.replace({
      pathname,
      search: params.toString()
    })
  }

  return (
    <Container>
      <Title>Filter</Title>
      <AnalysisDetails
        summary="Sentiment"
        popoverBody="The overall tone of the message or opinion expressed within a highlight. Sentiments are automatically applied by Insights and are used to classify highlight content as positive, negative, neutral, or mixed."
        clear={clearAll('sentiment')}
        showClear={!!params.get('sentiment')}
      >
        <SentimentOptions location={location} />
      </AnalysisDetails>
      <AnalysisDetails
        summary="Entities"
        popoverBody="A list of distinct, definable objects found within the video. Entities are automatically identified by Insights and can include the names of people, places, locations, organizations, or brands."
        clear={clearAll('entity')}
        showClear={!!params.get('entity')}
      >
        {!videoAnalysis && loading ? (
          <Loader />
        ) : entitiesOccurrences.length ? (
          <AnalysisOccurrences
            data={entitiesOccurrences}
            component={EntityOccurrences}
            analysisType="entity"
          />
        ) : (
          chooseProcessingMessage(video)
        )}
      </AnalysisDetails>
      <AnalysisDetails
        summary="Keyphrases"
        popoverBody="A list of words or phrases which appear with high frequency within the video. Keyphrases are automatically identified by Insights and can include any number of words or numbers."
        clear={clearAll('keyphrase')}
        showClear={!!params.get('keyphrase')}
      >
        {!videoAnalysis && loading ? (
          <Loader />
        ) : keyPhrasesOccurrences.length ? (
          <AnalysisOccurrences
            data={keyPhrasesOccurrences}
            component={KeyPhraseOccurrences}
            analysisType="keyphrase"
          />
        ) : (
          chooseProcessingMessage(video)
        )}
      </AnalysisDetails>
    </Container>
  )
}

export default withQueryParams(AnalysisLeftSidebar)
