import { useQuery } from '@apollo/react-hooks'
import { usePrevious } from 'utils/hooks'
import { useEffect } from 'react'
import VIDEO_ANALYSIS_QUERY from './PollAnalysis.query'
import { ANALYSIS_EVENTS, getOverallStatus } from 'utils/analysis'

const onEventCompleted = (analysisEvent, videoAnalysis, callback) => {
  if (!videoAnalysis) {
    return
  }

  const completed = videoAnalysis[`${analysisEvent}CompletedAt`]
  const previousCompleted = usePrevious(completed)
  useEffect(() => {
    previousCompleted === null && completed && callback(videoAnalysis)
  }, [completed])
}

const PollAnalysis = ({
  videoAnalysis,
  highlightReelJwt,
  onAnalysisStageCompleted
}) => {
  ANALYSIS_EVENTS.forEach(analysisEvent =>
    onEventCompleted(analysisEvent, videoAnalysis, onAnalysisStageCompleted)
  )
  const skip = getOverallStatus(videoAnalysis) !== 'processing'
  useQuery(VIDEO_ANALYSIS_QUERY, {
    variables: { videoAnalysisId: videoAnalysis.id },
    skip,
    context: {
      headers: {
        Authorization: `Bearer ${highlightReelJwt}`
      }
    },
    // `pollInterval` overrides `skip` prop, so change to 0 for workaround.
    // This should be fixed in React Apollo >= 3.1.0
    // https://github.com/apollographql/react-apollo/issues/2127
    pollInterval: skip ? 0 : 10000
  })

  return null
}

export default PollAnalysis
