const getCookieValue = key =>
  document.cookie.replace(
    new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*=\\s*([^;]*).*$)|^.*$`),
    '$1'
  )

export const getAccessToken = () => getCookieValue('fv_at')

export const getCurrentUserId = () => {
  const token = getAccessToken()
  if (!token) {
    return
  }
  return JSON.parse(window.atob(token.split('.')[1])).user_id + ''
}

export const getHighlightReelAccessToken = () => getCookieValue('fv_hr_jwt')

export const redirectToLogin = () =>
  (window.location = `/r/users/sign_in?next=${encodeURIComponent(
    window.location.pathname + window.location.search
  )}`)

export const redirectToRoot = () => (window.location = '/')

export const getXSRFToken = () =>
  decodeURIComponent(getCookieValue('XSRF-TOKEN'))
