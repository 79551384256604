import moment from 'moment'

export const friendlyDateTime = dateTime => moment(dateTime).calendar()
export const formatTime = seconds =>
  seconds > 3599
    ? moment.utc(seconds * 1000).format('H:mm:ss')
    : moment.utc(seconds * 1000).format('mm:ss')

export const fromNow = dateTime => moment(dateTime).fromNow()
export const timeSince = dateTime =>
  moment.duration(moment().diff(moment(dateTime))).humanize()

export const fullTime = dateTime =>
  moment(dateTime).format('MMMM Do YYYY, h:mm:ss')
