import React from 'react'
import Check from 'components/icons/Check'
import Container from './Container'

const iconSize = size => {
  switch (size) {
    case 'lg':
      return '25px'
    default:
      return '16px'
  }
}

const Option = ({ children, selected, size, ...props }) => (
  <Container selected={selected} size={size} {...props}>
    {children}
    {selected && <Check size={iconSize(size)} />}
  </Container>
)

Option.displayName = 'Dropdown.Option'

export default Option
