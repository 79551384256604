import React from 'react'
import Svg from 'components/Svg'

const StartPoint = props => (
  <Svg viewBox="0 0 14 14" {...props}>
    <title>Start Point</title>
    <polygon points="10 6 10 2 4 2 4 6 7 9 10 6" />
    <path d="M8,10H2v3H12V10ZM7,12H3V11H7Z" />
  </Svg>
)

export default StartPoint
