import AlertIcon from 'components/icons/Alert'
import CircleCheckIcon from 'components/icons/CircleCheck'
import DotIcon from 'components/icons/Dot'
import Empty from 'components/icons/Empty'
import SynchronizeIcon from 'components/icons/Synchronize'

export const formattedAnalysisEvent = analysisEvent => {
  switch (analysisEvent) {
    case 'transcription':
      return 'transcription'
    case 'highlightAnalysis':
      return 'highlights'
    case 'entities':
      return 'entities'
    case 'keyPhrases':
      return 'keyphrases'
    default:
      break
  }
}

export const itemStatusProps = (
  videoAnalysis,
  analysisStatus,
  analysisEvent
) => {
  switch (analysisStatus) {
    case 'completed':
      return {
        badgeBackgroundColor: 'secondary',
        badgeTextColor: null,
        icon: CircleCheckIcon,
        modifiedStatus: 'completed',
        lastModified: videoAnalysis[`${analysisEvent}CompletedAt`]
      }
    case 'errored':
      return {
        badgeBackgroundColor: 'error',
        badgeTextColor: null,
        icon: AlertIcon,
        modifiedStatus: 'errored',
        lastModified: videoAnalysis[`${analysisEvent}ErroredAt`]
      }
    case 'processing':
      return {
        badgeBackgroundColor: 'primary',
        badgeTextColor: null,
        icon: SynchronizeIcon,
        modifiedStatus: 'started',
        lastModified: videoAnalysis[`${analysisEvent}StartedAt`]
      }
    case 'pending':
      return {
        badgeBackgroundColor: 'buttonBackgroundDisabled',
        badgeTextColor: 'buttonTextDisabled',
        icon: DotIcon,
        modifiedStatus: 'pending',
        lastModified: videoAnalysis['updatedAt']
      }
    default:
      return {
        badgeBackgroundColor: 'backgroundShaded',
        badgeTextColor: null,
        icon: Empty,
        modifiedStatus: 'unprocessed',
        lastModified: null
      }
  }
}
