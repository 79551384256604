import React from 'react'
import { styled, background, padding } from '@fv/design-tokens'
import Header from './Header'
import TranscriptionDetails from './TranscriptionDetails'
import { HUMAN_ANALYSIS_SHAPE, MACHINE_ANALYSIS_SHAPE } from 'utils/analysis'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const TranscriptSettingsContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  ${background('backgroundShaded')};
  ${padding('xxs')};
  box-shadow: inset 0 4px 4px -4px #0006;
`

const TranscriptSettings = ({
  machineAnalysis,
  humanAnalysis,
  showOrderHumanTranscriptionModal,
  canRequestTranscription
}) => {
  machineAnalysis = machineAnalysis || MACHINE_ANALYSIS_SHAPE
  humanAnalysis = humanAnalysis || HUMAN_ANALYSIS_SHAPE
  return (
    <Container>
      <Header>Transcription</Header>
      <TranscriptSettingsContainer>
        {[machineAnalysis, humanAnalysis].map(videoAnalysis => {
          const activeAnalysisType = humanAnalysis.highlightAnalysisCompletedAt
            ? humanAnalysis.transcriptType
            : machineAnalysis.highlightAnalysisCompletedAt
            ? machineAnalysis.transcriptType
            : ''

          return (
            <TranscriptionDetails
              key={videoAnalysis.transcriptType}
              isActive={activeAnalysisType === videoAnalysis.transcriptType}
              videoAnalysis={videoAnalysis}
              showOrderHumanTranscriptionModal={
                showOrderHumanTranscriptionModal
              }
              canRequestTranscription={canRequestTranscription}
            />
          )
        })}
      </TranscriptSettingsContainer>
    </Container>
  )
}

export default TranscriptSettings
