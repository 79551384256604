import {
  styled,
  boxShadow,
  borderRadius,
  marginTop,
  paddingLeft,
  paddingRight
} from '@fv/design-tokens'
import { slate, white } from 'styles/color'

const Menu = styled.ul`
  position: absolute;
  top: 100%;
  list-style: none;
  background-color: ${white};
  margin-bottom: 0;
  width: 100%;
  color: ${slate};
  z-index: 500;
  max-height: 280px;
  overflow-y: auto;

  ${marginTop('xxs')};
  ${paddingLeft('xxs')};
  ${paddingRight('xxs')};
  ${boxShadow('distant')};
  ${borderRadius('m')};
`

Menu.displayName = 'Dropdown.Menu'

export default Menu
