import gql from 'graphql-tag'
import {
  TranscriptSettings_machineAnalysis,
  TranscriptSettings_humanAnalysis
} from '../TranscriptSettings/TranscriptSettings.fragments'
import { PollAnalysis_videoAnalysis } from './PollAnalysis.fragments'

export default gql`
  query VideoAnalysisQuery($videoAnalysisId: ID!) {
    videoAnalysis(videoAnalysisId: $videoAnalysisId) {
      id
      ...TranscriptSettings_machineAnalysis
      ...TranscriptSettings_humanAnalysis
      ...PollAnalysis_videoAnalysis
    }
  }

  ${TranscriptSettings_machineAnalysis}
  ${TranscriptSettings_humanAnalysis}
  ${PollAnalysis_videoAnalysis}
`
