import React from 'react'
import { styled, color, marginRight } from '@fv/design-tokens'
import Button from 'components/Button'
import CaretDownIcon from 'components/icons/CaretDown'
import CaretUpIcon from 'components/icons/CaretUp'

const StyledToggle = styled(Button)`
  ${marginRight('xxs')};

  svg {
    ${color('text')};
  }
`

const Toggle = ({ showDetails, toggleDetails }) => (
  <StyledToggle
    aria-pressed={showDetails}
    ghost={!showDetails}
    onClick={() => toggleDetails(!showDetails)}
    square
    type="button"
  >
    {showDetails ? (
      <CaretUpIcon aria-label="Collapse Details" title="Collapse Details" />
    ) : (
      <CaretDownIcon aria-label="Expand Details" title="Expand Details" />
    )}
  </StyledToggle>
)

export default Toggle
