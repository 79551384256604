import React from 'react'
import Input from 'components/Input'
import Field from './Field'

const FormInput = React.forwardRef(
  ({ type, name, onChange, ...props }, ref) => (
    <Field
      name={name}
      render={({ onChange: fieldOnChange, valid, form, ...field }) => (
        <Input
          ref={ref}
          {...props}
          {...field}
          onChange={e => {
            onChange && onChange(e)
            fieldOnChange(e)
          }}
          type={type}
          valid={valid}
        />
      )}
    />
  )
)

export default FormInput
