import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Input, Tooltip, SearchIcon, CancelIcon, color, spacer } from 'stylist'

const RelativeContainer = styled.div`
  position: relative;

  input {
    width: 100%;
    height: 38px;
    padding-left: ${spacer(6)};
    padding-right: ${spacer(5)};

    border-color: ${color('paleGrey')};

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${color('cadet')};
    }
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding: 2px;
    border-radius: 50%;
    color: ${color('white')};
    background-color: ${color('silver')};
    position: absolute;
    right: 8px;
    top: 11px;
    box-sizing: border-box;
    transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: ${color('pewter')};
    }
    &:active {
      background-color: ${color('ink')};
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 16px;
  width: 16px;
  color: ${color('slate')};
  position: absolute;
  left: ${spacer(3)};
  top: 11px;
`

const SearchInput = ({
  value = '',
  placeholder = 'Search',
  hasTooltip = false,
  tooltipText = 'Search',
  onReset = () => {},
  onChange = () => {}
}) => {
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => setInputValue(value), [value])

  const handleChange = event => {
    setInputValue(event.target.value)
    onChange(event)
  }

  const handleReset = () => {
    setInputValue('')
    onReset()
  }

  return (
    <RelativeContainer>
      {hasTooltip ? (
        <Tooltip
          position="top"
          label={tooltipText}
          delay={300}
          render={({ hide, show, ref }) => (
            <IconWrapper innerRef={ref} onMouseOver={show} onMouseOut={hide}>
              <SearchIcon size="s" />
            </IconWrapper>
          )}
        />
      ) : (
        <IconWrapper>
          <SearchIcon size="s" />
        </IconWrapper>
      )}
      <Input
        type="text"
        value={inputValue}
        placeholder={placeholder}
        aria-label={placeholder}
        onChange={handleChange}
      />
      {inputValue ? (
        <span onClick={handleReset}>
          <CancelIcon title="Clear field" />
        </span>
      ) : null}
    </RelativeContainer>
  )
}

export default SearchInput
