import React from 'react'
import PositiveIcon from 'components/icons/Positive'
import NegativeIcon from 'components/icons/Negative'
import NeutralIcon from 'components/icons/Neutral'
import MixedIcon from 'components/icons/Mixed'

const SentimentIcon = ({ sentiment, ...rest }) => {
  switch (sentiment) {
    case 'POSITIVE':
      return <PositiveIcon {...rest} />
    case 'NEGATIVE':
      return <NegativeIcon {...rest} />
    case 'NEUTRAL':
      return <NeutralIcon {...rest} />
    case 'MIXED':
      return <MixedIcon {...rest} />
    default:
      console.error(`"${sentiment}" is an unknown sentiment.`)
      return null
  }
}

export default SentimentIcon
