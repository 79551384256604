import {
  background,
  borderRadius,
  color,
  css,
  fontSize,
  fontWeight,
  padding,
  paddingLeft,
  paddingRight,
  styled
} from '@fv/design-tokens'
import { Link } from 'react-router-dom'

const ifPrimary = ({ primary, theme }) =>
  primary &&
  css`
    ${background('buttonPrimaryBackground', { theme })};
    ${color('buttonPrimaryText', { theme })};

    &:hover {
      ${background('buttonPrimaryBackgroundHover', { theme })};
      ${color('buttonPrimaryTextHover', { theme })}
    }

    &:active,
    &.active {
      ${background('buttonBackgroundActive', { theme })};
      ${color('buttonPrimaryTextActiveHover', { theme })};

      &:hover {
        ${background('buttonPrimaryBackgroundActiveHover', { theme })};
        ${color('buttonPrimaryTextActiveHover', { theme })};
      }
    }

    &:disabled {
      ${background('buttonPrimaryBackgroundDisabled', { theme })};
      ${color('buttonPrimaryTextDisabled', { theme })};
    }
  `

const ifOutline = ({ outline }) =>
  outline &&
  css`
    background: transparent;
  `

const ifSmall = ({ small }) =>
  small &&
  css`
    height: 24px;
    font-size: 0.75rem;

    svg {
      height: 14px;
    }
  `

const ifBig = ({ big, theme }) =>
  big &&
  css`
    width: 100%;
    height: auto;
    border-radius: 0;
    ${padding('m', { theme })};
    ${fontSize('m', { theme })};
  `

const ifRound = ({ round }) =>
  round &&
  css`
    padding-left: 0;
    padding-right: 0;
    border-radius: 50px;
  `

const ifSquare = ({ square, small }) =>
  square &&
  css`
    padding: 0;

    width: ${small ? '24px' : '32px'};
  `

const ifGhost = ({ ghost, theme }) =>
  ghost &&
  css`
    background: none;
    border-color: transparent;

    &:active {
      background: none;
      border-color: transparent;
    }

    &:disabled {
      ${color('textLt', { theme })};
    }
  `

const ifDanger = ({ danger, theme }) =>
  danger &&
  css`
    &,
    &:hover {
      ${color('error', { theme })};
    }
    &:active,
    &:active:hover {
      ${background('error', { theme })};
    }
  `

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  ${fontWeight('normal')};
  text-align: center;

  height: 32px;
  ${paddingLeft('m')};
  ${paddingRight('m')};

  ${borderRadius('m')};

  &:hover:enabled {
    cursor: pointer;
  }

  &:active,
  &:focus {
    outline: none;
  }

  border: 1px solid transparent;
  ${background('buttonBackground')};
  ${color('buttonText')};

  &:hover {
    ${background('buttonBackgroundHover')};
    ${color('buttonTextHover')};
  }

  &:active,
  .active {
    ${background('buttonBackgroundActive')};
    ${color('buttonTextActive')};

    &:hover {
      ${background('buttonBackgroundActiveHover')};
      ${color('buttonTextActiveHover')};
    }
  }

  &:disabled {
    cursor: not-allowed;
    ${background('buttonBackgroundDisabled')};
    ${color('buttonTextDisabled')};

    &:active,
    &:hover {
      ${background('buttonBackgroundDisabled')};
      ${color('buttonTextDisabled')};
    }
  }

  svg {
    height: 18px;
    width: auto;
  }

  svg,
  svg path {
    fill: currentColor;
  }

  ${ifPrimary};
  ${ifSquare};
  ${ifRound};
  ${ifOutline};
  ${ifSmall};
  ${ifBig};
  ${ifGhost};
  ${ifDanger};
`

export const InlineButtonLink = styled(Link)`
  ${color('buttonText')};

  &:hover {
    ${color('buttonTextHover')};
  }
`

export const InlineButtonLinkExternal = styled.a`
  ${color('buttonText')};

  &:hover {
    ${color('buttonTextHover')};
  }
`

export const ButtonLink = Button.withComponent(Link)
export const ExternalButtonLink = Button.withComponent('a')
export default Button
