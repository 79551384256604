import React from 'react'
import FeatherSvg from 'components/FeatherSvg'

const AlertCircle = props => (
  <FeatherSvg {...props}>
    <title>{props.title || 'alert-circle'}</title>
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="12" />
    <line x1="12" y1="16" x2="12" y2="16" />
  </FeatherSvg>
)

export default AlertCircle
