import {
  styled,
  css,
  color,
  borderRadius,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  fontSize,
  borderRightColor,
  borderLeftColor,
  borderBottomColor,
  borderTopColor,
  backgroundColor
} from '@fv/design-tokens'

const borderColorName = 'backgroundDk'
const arrowSize = 5

const tooltipPosition = ({ placement, top, left }) => {
  switch (placement) {
    case 'right':
    case 'right-start':
    case 'right-end':
      return css`
        top: ${top}px;
        left: ${left + arrowSize}px;
      `

    case 'left':
    case 'left-start':
    case 'left-end':
      return css`
        top: ${top}px;
        left: ${left - arrowSize}px;
      `

    case 'bottom':
    case 'bottom-start':
    case 'bottom-end':
      return css`
        top: ${top + arrowSize}px;
        left: ${left}px;
      `

    case 'top':
    case 'top-start':
    case 'top-end':
      return css`
        top: ${top - arrowSize}px;
        left: ${left}px;
      `

    default:
      return css``
  }
}

const arrowPosition = ({ placement, top, left, theme }) => {
  switch (placement) {
    case 'right':
      return css`
        &::after {
          top: 50%;
          left: -${2 * arrowSize}px;
          transform: translateY(-50%);
          ${borderRightColor(borderColorName, { theme })};
        }
      `

    case 'right-start':
      return css`
        &::after {
          top: ${2 * arrowSize}px;
          left: -${2 * arrowSize}px;
          transform: translateY(-50%);
          ${borderRightColor(borderColorName, { theme })};
        }
      `

    case 'right-end':
      return css`
        &::after {
          bottom: 0;
          left: -${2 * arrowSize}px;
          transform: translateY(-50%);
          ${borderRightColor(borderColorName, { theme })};
        }
      `

    case 'left':
      return css`
        &::after {
          top: 50%;
          right: -${2 * arrowSize}px;
          transform: translateY(-50%);
          ${borderLeftColor(borderColorName, { theme })};
        }
      `

    case 'left-start':
      return css`
        &::after {
          top: ${2 * arrowSize}px;
          right: -${2 * arrowSize}px;
          transform: translateY(-50%);
          ${borderLeftColor(borderColorName, { theme })};
        }
      `

    case 'left-end':
      return css`
        &::after {
          bottom: 0;
          right: -${2 * arrowSize}px;
          transform: translateY(-50%);
          ${borderLeftColor(borderColorName, { theme })};
        }
      `

    case 'bottom':
      return css`
        &::after {
          left: 50%;
          bottom: 100%;
          transform: translateX(-50%);
          ${borderBottomColor(borderColorName, { theme })};
        }
      `

    case 'bottom-start':
      return css`
        &::after {
          left: ${2 * arrowSize}px;
          bottom: 100%;
          transform: translateX(-50%);
          ${borderBottomColor(borderColorName, { theme })};
        }
      `

    case 'bottom-end':
      return css`
        &::after {
          right: 0;
          bottom: 100%;
          transform: translateX(-50%);
          ${borderBottomColor(borderColorName, { theme })};
        }
      `

    case 'top':
      return css`
        &::after {
          left: 50%;
          top: 100%;
          transform: translateX(-50%);
          ${borderTopColor(borderColorName, { theme })};
        }
      `

    case 'top-start':
      return css`
        &::after {
          left: ${2 * arrowSize}px;
          top: 100%;
          transform: translateX(-50%);
          ${borderTopColor(borderColorName, { theme })};
        }
      `

    case 'top-end':
      return css`
        &::after {
          right: 0;
          top: 100%;
          transform: translateX(-50%);
          ${borderTopColor(borderColorName, { theme })};
        }
      `

    default:
      return css``
  }
}

const position = props => {
  const { placement } = props

  switch (placement) {
    case 'right':
    case 'right-start':
    case 'right-end':
    case 'left':
    case 'left-start':
    case 'left-end':
    case 'bottom':
    case 'bottom-start':
    case 'bottom-end':
    case 'top':
    case 'top-start':
    case 'top-end':
      return css`
        ${tooltipPosition(props)};
        ${arrowPosition(props)};
      `

    default:
      console.error(`placement ${placement} not supported by Tooltip`)
  }
}

const transitionDelayCss = ({ transitionDelay = 0 }) =>
  css`
    transition-delay: ${transitionDelay}ms;
  `

const Container = styled.div`
  max-width: 325px;
  opacity: ${({ open }) => (open ? 0.95 : 0)};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
  position: absolute;
  text-align: left;
  transition: ${({ transitionTime }) => `opacity ${transitionTime}ms ease-out`};
  z-index: 1020;

  ${backgroundColor('backgroundDk')};
  ${borderRadius('m')};
  ${color('textReverse')};
  ${fontSize('s')};
  ${paddingBottom('xxs')};
  ${paddingLeft('xs')};
  ${paddingRight('xs')};
  ${paddingTop('xxs')};

  &::after {
    border: ${arrowSize}px solid transparent;
    content: '';
    position: absolute;
  }

  ${position};
  ${transitionDelayCss};
`

Container.displayName = 'Tooltip.Container'

export default Container
