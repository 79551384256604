import React, { forwardRef } from 'react'
import { withRouter } from 'react-router'
import { compose } from 'utils/fp'
import { paramsForLocation } from 'utils/params'

// Workaround for forwarding the ref through withRouter, credit to this person:
// https://github.com/ReactTraining/react-router/issues/6056#issuecomment-528871868
const withRouterAndRef = Wrapped => {
  const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
    <Wrapped ref={forwardRef} {...otherProps} />
  ))
  const WithRouterAndRef = forwardRef((props, ref) => (
    <WithRouter {...props} forwardRef={ref} />
  ))
  const name = Wrapped.displayName || Wrapped.name
  WithRouterAndRef.displayName = `withRouterAndRef(${name})`
  return WithRouterAndRef
}

const withQueryParams = Component =>
  forwardRef(({ location, ...rest }, ref) => (
    <Component
      location={location}
      query={paramsForLocation(location)}
      ref={ref}
      {...rest}
    />
  ))

export default compose(
  withRouterAndRef,
  withQueryParams
)
