import { matchDoubleSpace } from './regex'

export const isFunction = functionToCheck =>
  functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'

export const isNumber = value => Number.isInteger(parseInt(value, 10))

export const capitalize = (string = '') =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const mapObject = (obj, fn) =>
  Object.entries(obj)
    .map(fn)
    .reduce((acc, [k, v]) => {
      acc[k] = v
      return acc
    }, {})

export const range = count => [...Array(count).keys()]

export const boolToInt = value => value * 1

export const getDoubleSpaceCount = str => (matchDoubleSpace(str) || []).length

export const getNextPagePath = nextLink => nextLink.split('/r/api')[1]
