import React from 'react'
import { Formik } from 'formik'
import Field from './Field'
import Input from './Input'
import Select from './Select'
import FormError from './Error'

const Form = React.forwardRef(
  ({ children, className, onSubmit, ...props }, ref) => (
    <Formik
      {...props}
      onSubmit={(values, { setSubmitting, setErrors, setStatus }) => {
        Promise.resolve(onSubmit(values))
          .then(() => {
            setSubmitting(false)
          })
          .catch(errors => {
            setSubmitting(false)
            setErrors(errors)
          })
      }}
      render={({ handleSubmit, ...renderProps }) => (
        <form ref={ref} onSubmit={handleSubmit} className={className}>
          {typeof children === 'function' ? children(renderProps) : children}
        </form>
      )}
    />
  )
)

Form.Field = Field
Form.Input = Input
Form.Select = Select
Form.Error = FormError

export default Form
