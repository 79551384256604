import React, { useContext, useEffect } from 'react'
import { color, styled, spacingFor, padding } from '@fv/design-tokens'
import Button from 'components/Button'
import DropdownMenu from 'components/DropdownMenu'
import MoreIcon from 'components/icons/More'
import EditableTitle from 'components/EditableTitle'
import Tag from 'components/Tag'
import CurrentUserContext from 'components/CurrentUser/Context'
import NotificationsContext from 'components/Notifications/Context'
import DeleteReelModal from './DeleteReelModal'
import FinalizeReelWarningModal from './FinalizeReelWarningModal'
import UnpublishReelSpeedBumpContext from './UnpublishReelSpeedBump/Context'
import usePublishReelToPresentationMutation from 'hooks/mutations/usePublishReelToPresentation'
import { PUBLISH_STATUSES } from './utils'

const Container = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: ${spacingFor('m')};
  ${padding('m')};
  box-shadow: 0 4px 4px -4px #0006;
  z-index: 2;
  align-items: center;
`

const TitleWrapper = styled.div`
  h1,
  input {
    max-width: 210px;
  }
`

const Warn = styled.span`
  ${color('error')};
`

const Header = ({
  handleNewClip,
  handleNewReel,
  handleRenameReel,
  handleOpenReel,
  handleDeleteReel,
  deleteReelDisabled,
  reel
}) => {
  const { hasFeature } = useContext(CurrentUserContext)
  const { notify } = useContext(NotificationsContext)
  const { confirmUnpublishReel } = useContext(UnpublishReelSpeedBumpContext)
  const [
    publishReelToPresentation,
    { error }
  ] = usePublishReelToPresentationMutation()
  const canPublishReelToPresentation =
    reel &&
    (reel.publishStatus === PUBLISH_STATUSES.COMPLETE && !reel.publishDirtyFl)

  useEffect(() => {
    if (error) {
      notify('danger', 'Something went wrong')
    }
  }, [error])

  return (
    <Container>
      <TitleWrapper>
        <EditableTitle
          title={reel ? reel.name : 'New Showreel'}
          handleSubmit={handleRenameReel}
          inputLabel="Reel name"
          editButtonLabel="Rename reel"
          saveButtonLabel="Save reel name"
        />
      </TitleWrapper>
      <DropdownMenu
        align="right"
        trigger={(toggle, open) => (
          <Button
            aria-label="Showreel Builder Options"
            aria-pressed={open}
            square
            onClick={toggle}
            type="button"
          >
            <MoreIcon direction="horizontal" />
          </Button>
        )}
      >
        {hideDropdownMenu => (
          <>
            <DropdownMenu.Section>
              <DropdownMenu.Item
                onClick={() => {
                  hideDropdownMenu()
                  handleNewClip()
                }}
              >
                New Clip
              </DropdownMenu.Item>
              {hasFeature('20210426_presentation_space_phase_2') &&
                (reel && reel.publishedToPresentationFl ? (
                  <DropdownMenu.Item
                    onClick={async () => {
                      hideDropdownMenu()
                      const confirmed = await confirmUnpublishReel()

                      if (confirmed) {
                        publishReelToPresentation(reel, false)
                      }
                    }}
                  >
                    Unpublish Reel
                  </DropdownMenu.Item>
                ) : (
                  <FinalizeReelWarningModal name={reel && reel.name}>
                    {({ show: showFinalizeReelWarningModal }) => (
                      <DropdownMenu.Item
                        onClick={() => {
                          if (canPublishReelToPresentation) {
                            publishReelToPresentation(reel)
                            hideDropdownMenu()
                            return
                          }
                          showFinalizeReelWarningModal()
                        }}
                      >
                        Publish Reel
                      </DropdownMenu.Item>
                    )}
                  </FinalizeReelWarningModal>
                ))}
            </DropdownMenu.Section>
            <DropdownMenu.Section>
              <DropdownMenu.Item
                onClick={() => {
                  hideDropdownMenu()
                  handleNewReel()
                }}
              >
                New Reel
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={() => {
                  hideDropdownMenu()
                  handleOpenReel()
                }}
              >
                Open Reel
              </DropdownMenu.Item>
              <DeleteReelModal
                reelId={reel && reel.id}
                handleDelete={() => reel && handleDeleteReel()}
              >
                {({ show: showDeleteReelModal }) => (
                  <DropdownMenu.Item
                    disabled={deleteReelDisabled}
                    onClick={() => {
                      hideDropdownMenu()
                      showDeleteReelModal()
                    }}
                  >
                    <Warn>Delete Reel</Warn>
                  </DropdownMenu.Item>
                )}
              </DeleteReelModal>
            </DropdownMenu.Section>
          </>
        )}
      </DropdownMenu>
      {hasFeature('20210426_presentation_space_phase_2') &&
        reel &&
        reel.publishedToPresentationFl && (
          <div>
            <Tag uppercase>Published</Tag>
          </div>
        )}
    </Container>
  )
}

export default Header
