import { styled, height, width, marginRight } from '@fv/design-tokens'
import { silver, offWhite, green, cadet, red } from 'styles/color'

const checkboxBackgroundColor = ({ checked, disabled }) => {
  if (disabled) {
    return checked ? silver : offWhite
  }
  return checked ? green : 'transparent'
}

const checkboxBorderColor = ({ checked, disabled, valid }) => {
  if (disabled) {
    return silver
  } else if (!valid) {
    return red
  }

  return checked ? 'transparent' : cadet
}

const CheckboxDisplay = styled.div`
  background-color: ${props => checkboxBackgroundColor(props)};
  border: solid 0.0675rem ${props => checkboxBorderColor(props)};
  border-radius: 0.125rem;
  ${height('m')};
  ${width('m')};
  display: inline-block;
  ${marginRight('s')};
  margin-left: -1px;
  vertical-align: middle;
`

export default CheckboxDisplay
