import { Socket } from 'phoenix'
import { getHighlightReelAccessToken } from 'utils/session'

const phoenixSocket = new Socket(
  `${process.env.REACT_APP_HIGHLIGHT_SOCKET_URL}/socket`,
  {
    heartbeatIntervalMs: 10000,
    params: {
      get token() {
        return getHighlightReelAccessToken()
      }
    }
  }
)

export default phoenixSocket
