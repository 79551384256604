import React from 'react'

const Info = props => (
  <svg viewBox="0 0 14 14" {...props}>
    <title>{props.title || 'info'}</title>
    <path d="M7,1.06a6,6,0,1,1-6,6,6,6,0,0,1,6-6m0-1H7a7,7,0,0,0-7,7h0a7,7,0,0,0,7,7H7a7,7,0,0,0,7-7h0a7,7,0,0,0-7-7Z" />
    <rect x="6" y="7" width="2" height="4" />
    <rect x="6" y="3" width="2" height="2" />
  </svg>
)

export default Info
