import React, { useContext, useEffect, forwardRef } from 'react'
import { VideoPlayer as ReactVideoPlayer } from 'react-video-player'
import MultiVideoControllerContext from './MultiVideoControllerContext'
import { css, styled, color, marginTop, marginRight } from '@fv/design-tokens'
import CloseIcon from 'components/icons/Close'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'

const ifHidden = ({ hidden }) =>
  hidden &&
  css`
    display: none;
  `

const Container = styled.div`
  position: relative;

  ${ifHidden};
`

const CloseButton = forwardRef((props, ref) => (
  <Button {...props} ref={ref} square round aria-label="Close preview">
    <CloseIcon />
  </Button>
))

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 0;
  right: 0;

  ${color('textReverse')};
  ${marginTop('xxs')};
  ${marginRight('xxs')};
`

const MultiVideoPlayer = ({ plugins, hidden, handleClose }) => {
  const {
    handleLoadedMetaData,
    handleOnPause,
    handleOnPlay,
    handleOnEnded,
    handleTimeUpdate,
    videoPlayerRef,
    currentVideoSource
  } = useContext(MultiVideoControllerContext)

  useEffect(() => handleClose, [])

  return (
    <Container hidden={hidden}>
      <ReactVideoPlayer
        onLoadedMetaData={handleLoadedMetaData}
        onTimeUpdate={handleTimeUpdate}
        onPause={handleOnPause}
        onPlay={handleOnPlay}
        onEnded={handleOnEnded}
        ref={videoPlayerRef}
        plugins={plugins}
        sources={currentVideoSource ? currentVideoSource : []}
      />
      <Tooltip placement="left" render={() => 'Close preview'}>
        {({ show, hide, ref }) => (
          <StyledCloseButton
            onClick={handleClose}
            onMouseOver={show}
            onMouseOut={hide}
            ref={ref}
          />
        )}
      </Tooltip>
    </Container>
  )
}

export default MultiVideoPlayer
