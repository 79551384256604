import React, { useContext, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { isNumber } from 'utils'
import useQueryParams from 'hooks/useQueryParams'
import CurrentUserContext from 'components/CurrentUser/Context'
import MultiVideoControllerContext from 'components/MultiVideoPlayer/MultiVideoControllerContext'
import useUpdateReelMutation from 'hooks/mutations/useUpdateReel'
import useCreateReelMutation from 'hooks/mutations/useCreateReel'
import useCreateVideoClipMutation from 'hooks/mutations/useCreateVideoClip'
import useUpdateReelVideoClipMutation from 'hooks/mutations/useUpdateReelVideoClip'
import ClipForm from './ClipForm'
import ReelVideoClip from './ReelVideoClip'
import NewClipContext from './NewClipContext'
import UnpublishReelSpeedBumpContext from './UnpublishReelSpeedBump/Context'
import { reorder } from './utils'

const isPlayingClip = ({
  playlist,
  playingClipIndex,
  reelVideoClip,
  playing
}) =>
  playlist[playingClipIndex] &&
  playlist[playingClipIndex].id === reelVideoClip.id &&
  playing

const ReelVideoClips = ({
  reel,
  videoLength,
  workspace: { id: workspaceId },
  projectId,
  videoId,
  previewClip
}) => {
  const [dragAndDropKey, setDragAndDropKey] = useState(0)
  const undoDragAndDrop = () => setDragAndDropKey(new Date().getTime())
  const {
    newClip,
    unsetNewClip,
    videoId: newClipVideoId,
    assetUrl: newClipAssetUrl
  } = useContext(NewClipContext)
  const { playlist, pause, playing, playingClipIndex } = useContext(
    MultiVideoControllerContext
  )
  const { viewer } = useContext(CurrentUserContext)
  const { confirmUnpublishReel } = useContext(UnpublishReelSpeedBumpContext)
  const [createReel] = useCreateReelMutation()
  const [updateReel] = useUpdateReelMutation()
  const [
    createVideoClip,
    { loading: loadingCreateVideoClip }
  ] = useCreateVideoClipMutation()
  const [
    updateReelVideoClip,
    { loading: loadingUpdateReelVideoClip }
  ] = useUpdateReelVideoClipMutation()
  const [, setQueryParams] = useQueryParams()

  return (
    <>
      <DragDropContext
        key={dragAndDropKey}
        onDragEnd={async ({ destination, source, draggableId }) => {
          const from = source && source.index
          const to = destination && destination.index

          if (isNumber(to) && from !== to) {
            const confirmed = await confirmUnpublishReel()

            if (!confirmed) {
              undoDragAndDrop()
              return
            }

            const reorderedClips = reorder(reel.reelVideoClips, from, to)

            updateReel({
              ...reel,
              reelVideoClips: reorderedClips,
              userId: viewer.id,
              projectId
            })
          }
        }}
      >
        <Droppable droppableId="draggable-clips">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {((reel && reel.reelVideoClips) || []).map(
                (reelVideoClip, index) => (
                  <ReelVideoClip
                    key={reelVideoClip.id}
                    reelId={reel.id}
                    projectId={projectId}
                    index={index}
                    loading={loadingUpdateReelVideoClip}
                    reelVideoClip={reelVideoClip}
                    playing={isPlayingClip({
                      playlist,
                      playingClipIndex,
                      reelVideoClip,
                      playing
                    })}
                    handlePlay={() =>
                      isPlayingClip({
                        playlist,
                        playingClipIndex,
                        reelVideoClip,
                        playing
                      })
                        ? pause()
                        : previewClip(reelVideoClip)
                    }
                    handleSubmit={async args => {
                      if (
                        args.startTime !== reelVideoClip.startTime ||
                        args.endTime !== reelVideoClip.endTime
                      ) {
                        const confirmed = await confirmUnpublishReel()

                        if (!confirmed) {
                          return
                        }
                      }

                      updateReelVideoClip({
                        ...reelVideoClip,
                        reelId: reel.id,
                        ...args
                      })
                    }}
                    handleDelete={async () => {
                      const confirmed = await confirmUnpublishReel()

                      if (!confirmed) {
                        return
                      }

                      updateReel({
                        ...reel,
                        reelVideoClips: reel.reelVideoClips.filter(
                          _reelVideoClip =>
                            _reelVideoClip.id !== reelVideoClip.id
                        ),
                        userId: viewer.id,
                        projectId
                      })
                    }}
                  />
                )
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {newClip && (
        <ClipForm
          videoLength={videoLength}
          loading={loadingCreateVideoClip}
          handleSubmit={async args => {
            const confirmed = await confirmUnpublishReel()

            if (!confirmed) {
              return
            }

            const {
              data: { createVideoClip: createdVideoClip }
            } = await createVideoClip({
              ...args,
              videoId: newClipVideoId,
              assetUrl: newClipAssetUrl
            })
            if (reel) {
              updateReel({
                ...reel,
                reelVideoClips: [
                  ...reel.reelVideoClips,
                  {
                    ...createdVideoClip,
                    videoLength,
                    content: createdVideoClip
                  }
                ],
                userId: viewer.id,
                projectId
              })
              unsetNewClip()
            } else {
              const { data } = await createReel({
                videoClips: [createdVideoClip],
                workspaceId,
                userId: viewer.id,
                projectId
              })
              setQueryParams({ reel: data.reel.id })
              unsetNewClip()
            }
          }}
          handleCancel={unsetNewClip}
          {...newClip}
        />
      )}
    </>
  )
}

export default ReelVideoClips
