import React from 'react'
import { compose } from 'utils/fp'
import CreateVideoTranscriptMutation from 'components/mutations/CreateVideoTranscript'
import CreateVideoAnalysisMutation from 'components/mutations/CreateVideoAnalysis'

const withCreateVideoTranscriptMutation = Component => props => (
  <CreateVideoTranscriptMutation video={props.video}>
    {({ createVideoTranscript, ...rest }) => (
      <Component
        createVideoTranscript={{
          mutation: createVideoTranscript,
          ...rest
        }}
        {...props}
      />
    )}
  </CreateVideoTranscriptMutation>
)

const withCreateVideoAnalysisMutation = Component => props => (
  <CreateVideoAnalysisMutation
    video={props.video}
    insightsVideoQueryVariables={props.insightsVideoQueryVariables}
  >
    {({ createVideoAnalysis, ...rest }) => (
      <Component
        createVideoAnalysis={{
          mutation: createVideoAnalysis,
          ...rest
        }}
        {...props}
      />
    )}
  </CreateVideoAnalysisMutation>
)

export default compose(
  withCreateVideoTranscriptMutation,
  withCreateVideoAnalysisMutation
)
