import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import VI_PROJECTS_QUERY from './ProjectSelector.query'
import VI_REELS_QUERY from './ReelSelector.query'
import VI_PROJECT_REELS_QUERY from 'components/AdvancedEditor/ReelBuilder/ReelsIndex/ProjectReels.query'
import VI_CURRENT_USER_REELS_QUERY from 'components/AdvancedEditor/ReelBuilder/ReelsIndex/CurrentUserReels.query'
import useDebounce from 'hooks/useDebounce'
import { spacer } from 'stylist'
import { offWhite2, red } from 'styles/color'
import styled from '@emotion/styled'
import { css } from '@fv/design-tokens'
import Loader from 'components/Loader'
import NoResults from './NoResults'
import ProjectList from './ProjectList'
import ReelList from './ReelList'
import BreadCrumb from './BreadCrumb'
import YourShowreelsFilter from './YourShowreelsFilter'

const ResultsHeader = styled.div`
  padding: ${spacer(3)} ${spacer(4)};
`

const ResultsBody = styled.div`
  padding: 0 ${spacer(4)};
`

const invalidBorderCss = ({ isInvalid }) =>
  isInvalid
    ? css`
        border: 1px solid ${red};
      `
    : css`
        border: 1px solid ${offWhite2};
      `

const ResultsList = styled.div`
  max-height: 246px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 4px;
  ${invalidBorderCss}
`

const ProjectAndReelList = styled.div`
  overflow: hidden;
`

const NavContainer = styled.div`
  display: flex;
`

const CreateShowreelButton = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-left: auto;
  cursor: pointer;
`

const ReelSelectorV2 = ({
  sourceReelId,
  companyId,
  viewerId,
  query = '',
  setSearchQuery = () => {},
  onReelSelection = () => {},
  selectedReel = {},
  setReels = () => {},
  reels = [],
  setProject = () => {},
  selectedProject = [],
  isInvalid = false,
  setNoNameWarning = () => {},
  noNameWarning = false
}) => {
  const [filteredByYourShowreels, setFilteredByYourShowreels] = useState(false)

  const [
    loadProjects,
    {
      called: calledProjects,
      loading: loadingProjects,
      data: { projects: { graphql: projects = [] } = {} } = {}
    }
  ] = useLazyQuery(VI_PROJECTS_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  const removeSourceReel = reels =>
    reels.filter(reel => reel.id != sourceReelId)

  const [loadReelsByName, { loading: loadingReels }] = useLazyQuery(
    VI_REELS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const reels = removeSourceReel(data.reels.graphql)
        setReels(reels)
      }
    }
  )

  const [loadReelsByProject, { loading: loadingProjectReels }] = useLazyQuery(
    VI_PROJECT_REELS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const reels = removeSourceReel(data.reels.graphql)
        setReels(reels)
      }
    }
  )

  const [loadReelsByUser, { loading: loadingUserReels }] = useLazyQuery(
    VI_CURRENT_USER_REELS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const reels = removeSourceReel(data.reels.graphql)
        setReels(reels)
      }
    }
  )

  useDebounce(() => {
    loadProjects({
      variables: {
        companyId: companyId,
        name: query
      }
    })

    if (hasSearched()) {
      setProject({})
      loadReelsByName({
        variables: {
          companyId: companyId,
          name: query
        }
      })
    }

    onReelSelection({})
  }, [query])

  const hasSearched = () => {
    return query !== ''
  }

  const getResultsCount = () => {
    return projects.length + reels.length
  }

  const showYourShowreelFilter = () => {
    return (
      projectSelected() &&
      !filteredByYourShowreels &&
      reels.filter(reel => !reel.new).length > 0
    )
  }

  const handleProjectSelection = project => {
    setSearchQuery('')
    setProject(project)
    loadReelsByProject({ variables: { projectId: project.id } })
    onReelSelection({})
  }

  const clearProject = () => {
    setFilteredByYourShowreels(false)
    setProject({})
  }

  const handleClickOnProjectsNav = () => {
    clearProject()
    onReelSelection({})
  }

  const createNewShowreel = () => {
    if (reels.some(reel => reel.new)) return

    const newReel = { new: true, name: '' }
    setReels([newReel, ...reels])
    onReelSelection(newReel)
  }

  const handleNewReelNameChange = newName => {
    let reelsClone = [...reels]
    let newReelIndex = reelsClone.findIndex(reel => reel.new)
    reelsClone[newReelIndex].name = newName
    setReels(reelsClone)
    onReelSelection(reelsClone[newReelIndex])
    setNoNameWarning(false)
  }

  const clearYourShowreelsFilter = () => {
    setFilteredByYourShowreels(false)
    loadReelsByProject({ variables: { projectId: selectedProject.id } })
  }

  const handleClickOnProjectNameNav = () => {
    clearYourShowreelsFilter()
    onReelSelection({})
  }

  const selectFilteredByYourShowreels = () => {
    setFilteredByYourShowreels(true)
    loadReelsByUser({
      variables: { projectId: selectedProject.id, userId: viewerId }
    })
    onReelSelection({})
  }

  const projectSelected = () => {
    return Object.keys(selectedProject).length > 0
  }

  return (
    <>
      {!calledProjects ||
      loadingProjects ||
      loadingReels ||
      loadingProjectReels ||
      loadingUserReels ? (
        <Loader aria-label="projects and reels loader" />
      ) : (
        <>
          <ResultsHeader>
            {!hasSearched() ? (
              <NavContainer>
                <BreadCrumb
                  projectName={selectedProject.name}
                  filteredByYourShowreels={filteredByYourShowreels}
                  onProjectsNav={handleClickOnProjectsNav}
                  onProjectNameNav={handleClickOnProjectNameNav}
                />
                {projectSelected() && (
                  <CreateShowreelButton onClick={createNewShowreel}>
                    Create new showreel
                  </CreateShowreelButton>
                )}
              </NavContainer>
            ) : (
              <span>{getResultsCount()} results</span>
            )}
          </ResultsHeader>
          <ResultsBody>
            <ResultsList isInvalid={isInvalid}>
              {getResultsCount() === 0 && (
                <NoResults
                  text={
                    "No results found. We can't find any item matching your search."
                  }
                />
              )}
              {projectSelected() && reels.length === 0 && (
                <NoResults text={'No showreels available.'} />
              )}
              {getResultsCount() > 0 && (
                <ProjectAndReelList>
                  {!projectSelected() && (
                    <ProjectList
                      projects={projects}
                      query={query}
                      onProjectSelection={handleProjectSelection}
                    />
                  )}
                  {showYourShowreelFilter() && (
                    <YourShowreelsFilter
                      onFilterClick={selectFilteredByYourShowreels}
                    />
                  )}
                  {(hasSearched() || projectSelected()) && (
                    <ReelList
                      reels={reels}
                      query={query}
                      selectedReel={selectedReel}
                      noNameWarning={noNameWarning}
                      onReelSelection={onReelSelection}
                      onNewReelNameChange={handleNewReelNameChange}
                    />
                  )}
                </ProjectAndReelList>
              )}
            </ResultsList>
          </ResultsBody>
        </>
      )}
    </>
  )
}

export default ReelSelectorV2
