import React, { Component } from 'react'
import { styled, marginRight, marginTop } from '@fv/design-tokens'
import Sentry from 'config/sentry'
import {
  ErrorContainer,
  ErrorContent,
  ErrorFooter,
  ErrorPage
} from 'components/ErrorPage'
import Button, { ButtonLink } from 'components/Button'
import { redirectToRoot } from 'utils/session'

const HomeLink = styled(ButtonLink)`
  ${marginRight('xs')};
`

const ReportButton = styled(Button)`
  ${marginTop('s')};
  margin-left: auto;
  margin-right: auto;
`

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  render() {
    return this.state.error ? (
      <ErrorPage>
        <ErrorContainer>
          <h1>Oops, something went wrong</h1>
          <ErrorContent>
            Our team has been notified. If you would like to fill out an error
            report, please click below.
          </ErrorContent>
          <ErrorFooter>
            <HomeLink
              kind="standard"
              dark
              to="/"
              onClick={() => {
                this.setState({ error: null })
                redirectToRoot()
              }}
            >
              Home
            </HomeLink>
            <ReportButton
              kind="primary"
              onClick={() => Sentry.showReportDialog()}
            >
              Fill out a report
            </ReportButton>
          </ErrorFooter>
        </ErrorContainer>
      </ErrorPage>
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
