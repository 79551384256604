import {
  styled,
  fontSize,
  fontWeight,
  lineHeight,
  padding
} from '@fv/design-tokens'
import { flexCenterContent } from 'styles/positioning'

export const ErrorPage = styled.div`
  height: 100vh;
  color: white;
  text-align: center;

  ${flexCenterContent};
`

export const ErrorContainer = styled.div`
  max-width: 95%;
  width: 600px;
`

export const ErrorContent = styled.div`
  ${padding('xs')};
  ${fontSize('m')};
  ${fontWeight('medium')};
  ${lineHeight('m')};
`

export const ErrorFooter = styled.div`
  ${padding('xs')};
`
