import React, { Component, createContext } from 'react'
import { DEFAULT_CLIP } from './constants'

const ClipContext = createContext({})
const { Consumer, Provider } = ClipContext

class ClipProvider extends Component {
  state = DEFAULT_CLIP

  // Setters

  updateStartTime = startTime =>
    this.setState({ startTime }, this.updateDuration)
  updateEndTime = endTime => this.setState({ endTime }, this.updateDuration)
  updateName = name => this.setState({ name })
  updateIsEditing = isEditing => this.setState({ isEditing })
  updateIsNew = isNew => this.setState({ isNew })

  // Actions

  newClip = ({ name, startTime, endTime }) => {
    this.setState(
      {
        isEditing: false,
        isNew: true,
        name,
        startTime,
        endTime
      },
      () => {
        this.updateDuration()
        console.log(this.state)
      }
    )
  }

  editClip = ({ id, name, startTime, endTime }) => {
    this.setState(
      {
        isEditing: true,
        isNew: false,
        id,
        name,
        startTime,
        endTime
      },
      this.updateDuration
    )
  }

  cancelClip = () => this.setState(DEFAULT_CLIP)

  saveClip = () => {
    if (this.state.isNew) {
      console.log('dispatch new')
    } else {
      console.log('dispatch update')
    }
  }

  // Utils

  updateDuration = () => {
    const { startTime, endTime } = this.state

    return this.setState({ duration: Math.abs(endTime - startTime) })
  }

  render() {
    const { children } = this.props
    const {
      updateStartTime,
      updateEndTime,
      updateIsEditing,
      updateName,
      updateIsNew,
      newClip,
      editClip,
      saveClip,
      cancelClip
    } = this
    const {
      isEditing,
      isNew,
      id,
      name,
      startTime,
      endTime,
      duration
    } = this.state

    return (
      <Provider
        value={{
          updateStartTime,
          updateEndTime,
          updateIsEditing,
          updateName,
          updateIsNew,
          newClip,
          editClip,
          saveClip,
          cancelClip,
          isEditing,
          isNew,
          id,
          name,
          startTime,
          endTime,
          duration
        }}
      >
        {children}
      </Provider>
    )
  }
}

export default ClipContext
export { Consumer as ClipConsumer, ClipProvider }
