import React from 'react'
import AnalysisToggle from 'components/AnalysisToggle'

const EntityOccurrences = ({ text, ...props }) => (
  <AnalysisToggle paramKey={'entity'} paramValue={text} {...props}>
    {text}
  </AnalysisToggle>
)

export default EntityOccurrences
