import React, { useState, useContext, useEffect } from 'react'
import { useParams, useLocation } from 'react-router'
import NotificationsContext from 'components/Notifications/Context'
import CurrentUserContext from 'components/CurrentUser/Context'
import {
  styled as fvStyled,
  marginBottom,
  paddingBottom
} from '@fv/design-tokens'
import styled from '@emotion/styled'
import { Button, Alert, spacer } from 'stylist'
import { InlineButtonLink, InlineButtonLinkExternal } from 'components/Button'
import Dialog from 'components/Dialog'
import SearchInput from 'components/SearchInput'
import ReelSelectorV2 from './ReelSelectorV2'
import Loader from 'components/Loader'
import useCreateVideoClip from 'hooks/mutations/useCreateVideoClip'
import useCreateReelVideoClip from 'hooks/mutations/useCreateReelVideoClip'
import useCreateWorkspaceMutation from 'hooks/mutations/useCreateWorkspace'
import useCreateReelMutation from 'hooks/mutations/useCreateReel'

const DialogHeader = fvStyled(Dialog.Header)`
  display: flex;
  flex-direction: column;
  z-index: 2;
  ${paddingBottom('m')};
`

const DialogTitle = fvStyled(Dialog.Title)`
  ${marginBottom('m')} !important;
`

const DialogDescription = fvStyled.p`
  ${marginBottom('s')};
`

const DialogBody = fvStyled(Dialog.Body)`
  max-height: 480px;
  padding: 0;
`

const DialogFooter = fvStyled(Dialog.Footer)`
  z-index: 2;

  button + button {
    margin-left: 0.5rem;
  }
`

const AlertContainer = styled.div`
  padding: ${spacer(2)} ${spacer(4)};
`

const SuccessNotification = ({ clipName, reel, newReel }) => {
  const { pathname } = useLocation()

  return (
    <span>
      Successfully copied {clipName} clip to{' '}
      {newReel ? (
        <InlineButtonLinkExternal
          href={`${pathname}?panel=showreelBuilder&reel=${reel.id}`}
        >
          {reel.name}
        </InlineButtonLinkExternal>
      ) : (
        <InlineButtonLink
          to={{
            pathname,
            search: `?panel=showreelBuilder&reel=${reel.id}`
          }}
          replace
        >
          {reel.name}
        </InlineButtonLink>
      )}{' '}
      showreel.
    </span>
  )
}

const CopyClipToProjectDialog = ({
  sourceReelId,
  reelVideoClip: {
    name,
    startTime,
    endTime,
    content: {
      video: { id: videoId }
    }
  },
  hide
}) => {
  const { notify } = useContext(NotificationsContext)
  const { viewer } = useContext(CurrentUserContext)
  const [searchQuery, setSearchQuery] = useState('')
  const { companyId } = useParams()
  const [selectedReel, setSelectedReel] = useState({})
  const [reels, setReels] = useState([])
  const [selectedProject, setProject] = useState({})
  const [alertType, setAlertType] = useState('')
  const [noNameWarning, setNoNameWarning] = useState(false)

  useEffect(() => {
    if (selectedReel.publishedToPresentationFl) {
      setAlertType('reel-published')
    } else {
      setAlertType('')
    }
  }, [selectedReel])

  const [
    createVideoClip,
    { loading: loadingCreateVideoClip }
  ] = useCreateVideoClip()

  const [
    createReelVideoClip,
    { loading: loadingCreateReelVideoClip }
  ] = useCreateReelVideoClip()

  const [createReel, { loading: loadingCreateReel }] = useCreateReelMutation()

  const [
    createWorkspace,
    { loading: loadingCreateWorkspace }
  ] = useCreateWorkspaceMutation()

  const handleReelSelection = reel => {
    setSelectedReel(reel)
  }

  const hasReelSelected = () => {
    return Object.keys(selectedReel).length > 0
  }

  const isInvalidNewReel = () => {
    return !selectedReel.name
  }

  const makingRequest = () => {
    return (
      loadingCreateVideoClip ||
      loadingCreateReelVideoClip ||
      loadingCreateReel ||
      loadingCreateWorkspace
    )
  }

  const isInvalidSubmit = () => {
    return alertType === 'no-reels'
  }

  const copyClipToReel = async (reel, options = {}) => {
    try {
      const {
        data: {
          createVideoClip: { id: videoClipId }
        }
      } = await createVideoClip({
        name,
        startTime,
        endTime,
        videoId: videoId
      })
      await createReelVideoClip({
        videoClipId,
        reelId: reel.id,
        name,
        startTime,
        endTime
      })
    } catch (e) {
      notify('danger', 'Something weng wrong.')
      return
    }

    notify(
      'success',
      <SuccessNotification
        clipName={name}
        reel={reel}
        newReel={options.newReel}
      />
    )
    hide()
  }

  const createNewReel = async () => {
    try {
      const {
        data: { createWorkspace: { id: workspaceId } = {} }
      } = await createWorkspace({
        userId: viewer.id,
        projectId: selectedProject.id,
        companyId
      })
      const {
        data: { reel: createdReel = {} }
      } = await createReel({
        name: selectedReel.name,
        videoClips: [],
        workspaceId,
        userId: viewer.id,
        projectId: selectedProject.id
      })
      return createdReel
    } catch (e) {
      notify('danger', 'Something weng wrong.')
    }
  }

  const handleSubmission = async () => {
    if (!hasReelSelected()) return setAlertType('no-reels')

    if (selectedReel.new) {
      if (isInvalidNewReel()) return setNoNameWarning(true)

      const createdReel = await createNewReel()
      copyClipToReel(createdReel, { newReel: true })
    } else {
      copyClipToReel(selectedReel)
    }
  }

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>Copy "{name}" to...</DialogTitle>
        {!makingRequest() && (
          <>
            <DialogDescription>Select a destination showreel</DialogDescription>
            <SearchInput
              value={searchQuery}
              placeholder="Search projects or showreels"
              onChange={event => {
                setSearchQuery(event.target.value)
              }}
              onReset={() => {
                setSearchQuery('')
              }}
            />
          </>
        )}
      </DialogHeader>
      <DialogBody>
        {makingRequest() ? (
          <Loader aria-label="copying clip loader" />
        ) : (
          <ReelSelectorV2
            sourceReelId={sourceReelId}
            companyId={companyId}
            viewerId={viewer.id}
            query={searchQuery}
            setSearchQuery={setSearchQuery}
            onReelSelection={handleReelSelection}
            selectedReel={selectedReel}
            setReels={setReels}
            reels={reels}
            setProject={setProject}
            selectedProject={selectedProject}
            isInvalid={isInvalidSubmit()}
            setNoNameWarning={setNoNameWarning}
            noNameWarning={noNameWarning}
          />
        )}
        {alertType === 'no-reels' && !hasReelSelected() && (
          <AlertContainer>
            <Alert kind="error" message="You must select a showreel" />
          </AlertContainer>
        )}
        {alertType === 'reel-published' && (
          <AlertContainer>
            <Alert
              kind="warning"
              message="You've selected a published showreel. If you continue, this showreel will be unpublished."
            />
          </AlertContainer>
        )}
      </DialogBody>
      <DialogFooter>
        {!makingRequest() && (
          <Button kind="ghost" disabled={makingRequest()} onClick={hide}>
            Cancel
          </Button>
        )}
        <Button
          kind="primary"
          disabled={makingRequest()}
          onClick={handleSubmission}
        >
          {makingRequest() ? 'Copying...' : 'Copy'}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default CopyClipToProjectDialog
