import React from 'react'
import {
  styled,
  background,
  paddingLeft,
  paddingRight,
  marginBottom,
  color,
  colorFor
} from '@fv/design-tokens'
import NewClipIcon from 'components/icons/NewClip'
import Button from 'components/Button'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  ${background('backgroundShaded')};
  ${paddingLeft('m')};
  ${paddingRight('m')};
`

const ReelBuilderIcon = styled(NewClipIcon)`
  color: transparent;
  stroke: ${colorFor('textLt')};
  fill: none;
  ${marginBottom('xs')};
`
const PlaceholderText = styled.p`
  text-align: center;
  max-width: 200px;

  ${color('textLt')};
`

const EmptyReelPlaceholder = ({ handleNew }) => (
  <Container>
    <ReelBuilderIcon width="32px" height="32px" />
    <PlaceholderText>
      Create and arrange clips from highlights, transcripts, or marks for
      export.
    </PlaceholderText>
    <Button ghost onClick={handleNew}>
      Create clip
    </Button>
  </Container>
)

export default EmptyReelPlaceholder
