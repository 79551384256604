import { styled, fontWeight } from '@fv/design-tokens'
import { cadet, white } from 'styles/color'

const Placeholder = styled.span`
  color: ${cadet};

  ${fontWeight('medium')};

  [aria-expanded='true'] & {
    color: ${white};
  }
`

export default Placeholder
