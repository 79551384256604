import { styled, css, fontSize, fontWeight, padding } from '@fv/design-tokens'

const size = ({ size }) => {
  switch (size) {
    case 'lg':
      return css`
        ${fontSize('s')};
        ${padding('xs')};
      `
    default:
      return css`
        ${padding('xxs')};
      `
  }
}

const Container = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${padding('xxs')};

  ${size};

  &:hover:not([disabled]) {
    cursor: pointer;
    background-color: rgba(0, 112, 255, 0.15);
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }

  ${({ highlighted, selected, theme }) =>
    (highlighted || selected) &&
    css`
      background-color: rgba(0, 112, 255, 0.15);
      ${selected && fontWeight('medium')};
    `};
`

Container.displayName = 'Dropdown.Option.Container'

export default Container
