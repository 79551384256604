import React from 'react'
import { withRouter } from 'react-router-dom'
import QueryParams from './QueryParams'
import noop from 'utils/noop'

const QueryParamRoute = ({
  param,
  query,
  render = noop,
  renderDefault = noop,
  location,
  match,
  history
}) => (
  <QueryParams param={param}>
    {({ query: currentQuery }) => {
      const anyQuery = query === true && Boolean(currentQuery)

      return anyQuery || currentQuery === query
        ? render({ location, match, history })
        : renderDefault({ location, match, history })
    }}
  </QueryParams>
)

export default withRouter(QueryParamRoute)
