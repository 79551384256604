import gql from 'graphql-tag'
import VI_REEL_QUERY, {
  INCLUDE,
  SPARSE_FIELDS
} from 'components/AdvancedEditor/ReelBuilder/reel.query.js'
import VI_CURRENT_USER_REELS_QUERY from 'components/AdvancedEditor/ReelBuilder/ReelsIndex/CurrentUserReels.query'
import VI_PROJECT_REELS_QUERY from 'components/AdvancedEditor/ReelBuilder/ReelsIndex/ProjectReels.query'
import { ReelBuilder_reel } from 'components/AdvancedEditor/ReelBuilder/ReelBuilder.fragments'
import { ReelVideoClip_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/ReelVideoClip.fragments'
import { CopyClipModal_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/CopyClipModal/CopyClipModal.fragments'
import { ReelVideoClips_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/ReelVideoClips.fragments'
import { PUBLISH_STATUSES } from 'components/AdvancedEditor/ReelBuilder/utils'
import { useMutation } from '@apollo/react-hooks'

export const VI_CREATE_REEL_MUTATION = gql`
  mutation VICreateReel($input: CreateReelInput!) {
    reel(input: $input)
      @jsonapi(
        path: "/reels?${INCLUDE}&${SPARSE_FIELDS}"
        method: "POST"
      ) {
      id
      ...ReelBuilder_reel

      reelVideoClips {
        id
        ...ReelVideoClip_reelVideoClip
        ...CopyClipModal_reelVideoClip
        ...ReelVideoClips_reelVideoClip
      }
    }
  }

  ${ReelBuilder_reel}
  ${ReelVideoClip_reelVideoClip}
  ${CopyClipModal_reelVideoClip}
  ${ReelVideoClips_reelVideoClip}
`

const createRelationships = ({ workspaceId, videoClips }) => ({
  video_clips: {
    data: videoClips.map(({ id }) => ({
      type: 'video_clips',
      id
    }))
  },
  workspace: {
    data: {
      type: 'workspaces',
      id: workspaceId
    }
  }
})

const useCreateReel = () => {
  const [mutate, ...statusParams] = useMutation(VI_CREATE_REEL_MUTATION)

  const createReel = ({
    userId,
    projectId,
    name = 'New Showreel',
    workspaceId,
    videoClips
  }) =>
    mutate({
      variables: {
        input: {
          data: {
            type: 'reels',
            attributes: {
              name
            },
            relationships: createRelationships({
              videoClips,
              workspaceId
            })
          }
        }
      },
      optimisticResponse: {
        reel: {
          __typename: 'VIReels',
          type: 'reels',
          id: new Date().getTime().toString(),
          name,
          thumbnailUrl: '',
          clipCount: 0,
          updateDt: new Date().toISOString(),
          reelVideoClips: videoClips.map(videoClip => ({
            __typename: 'VIReelVideoClips',
            type: 'reel_video_clips',
            id: new Date().getTime().toString(),
            name: videoClip.name,
            startTime: videoClip.startTime,
            endTime: videoClip.endTime,
            assetUrl: videoClip.assetUrl,
            videoLength: 0,
            content: videoClip
          })),
          publishStatus: PUBLISH_STATUSES.NONE,
          publishDirtyFl: false,
          publishVideoUrl: null,
          publishedToPresentationFl: false
        }
      },
      update: (cache, { data: { reel } }) => {
        try {
          const { reels: currentUserReels } = cache.readQuery({
            query: VI_CURRENT_USER_REELS_QUERY,
            variables: { userId, projectId }
          })

          cache.writeQuery({
            query: VI_CURRENT_USER_REELS_QUERY,
            variables: { userId, projectId },
            data: {
              reels: [reel, ...currentUserReels]
            }
          })
        } catch (e) {}
        try {
          const { reels: projectReels } = cache.readQuery({
            query: VI_PROJECT_REELS_QUERY,
            variables: { projectId }
          })

          cache.writeQuery({
            query: VI_PROJECT_REELS_QUERY,
            variables: { userId, projectId },
            data: {
              reels: [reel, ...projectReels]
            }
          })
        } catch (e) {}

        cache.writeQuery({
          query: VI_REEL_QUERY,
          variables: { reelId: reel.id },
          data: {
            reel
          }
        })
      }
    })

  return [createReel, statusParams]
}

export default useCreateReel
