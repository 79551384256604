import React from 'react'
import {
  styled,
  borderColor,
  color,
  fontSize,
  marginRight
} from '@fv/design-tokens'
import Badge from 'components/Badge'
import StatusIcon from './StatusIcon'
import { capitalize } from 'utils/index'
import moment from 'moment'

const Item = styled.dt``

const Status = styled.dd`
  margin: 0;
  order: -1;
  width: 2rem;
  display: flex;
  justify-content: center;
  ${marginRight('xxs')};
`

const StatusBadge = styled(Badge)`
  width: 1.5rem;
`

const StatusDetails = styled.dd`
  visibility: hidden;
  margin: 0;
  text-align: right;
  ${color('buttonTextDisabled')};
`

const ItemGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  align-items: center;
  ${fontSize('s')};

  &:hover {
    ${StatusDetails} {
      visibility: visible;
    }
  }

  &::before {
    content: '';
    width: 0.25rem;
    height: 2rem;
    margin-left: -1.375rem;
    border-top: 4px solid;
    border-bottom: 4px solid;
    ${({ status }) =>
      status === 'unprocessed'
        ? borderColor('backgroundShaded')
        : borderColor('buttonBackgroundDisabled')};
  }
`

const AnalysisItem = ({
  item,
  badgeBackgroundColor,
  badgeTextColor,
  status,
  icon,
  modifiedStatus,
  lastModified
}) => {
  const displayLastModified = moment(lastModified).isValid()
    ? moment(lastModified).format('l LT')
    : ''

  return (
    <ItemGroup status={status}>
      <Item>{capitalize(item)}</Item>
      <Status>
        <StatusBadge
          circle
          backgroundColorName={badgeBackgroundColor}
          textColorName={badgeTextColor}
        >
          <StatusIcon
            aria-label={capitalize(status)}
            component={icon}
            role="img"
            size="16px"
            title={capitalize(status)}
          />
        </StatusBadge>
      </Status>
      <StatusDetails>
        {`${capitalize(modifiedStatus)} ${displayLastModified}`}
      </StatusDetails>
    </ItemGroup>
  )
}

export default AnalysisItem
