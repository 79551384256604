import React from 'react'
import { Button, ModalV2 as Modal, Dialog } from 'stylist'

const DeleteClipModal = ({
  name,
  handleDelete,
  onClose,
  children,
  onDismiss
}) => (
  <Modal
    blurBackground
    onDismiss={onDismiss}
    render={({ hide }) => (
      <Dialog>
        <Dialog.Header>
          <Dialog.Title>Are you sure?</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <strong>{name}</strong> will be permanently deleted.
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            kind="ghost"
            onClick={() => {
              onDismiss()
              hide()
            }}
          >
            Cancel
          </Button>
          <Button
            kind="danger"
            onClick={() => {
              onDismiss()
              hide()
              handleDelete()
            }}
          >
            Delete
          </Button>
        </Dialog.Footer>
      </Dialog>
    )}
  >
    {children}
  </Modal>
)

export default DeleteClipModal
