import React from 'react'
import Svg from 'components/Svg'

const VideoControlsPlayIcon = props => (
  <Svg viewBox="0 0 18 18" {...props}>
    <title>video-controls-play</title>
    <g>
      <path d="M0.591181818,1.36390909 C0.591181818,1.09620537 0.730016062,0.847647169 0.957759255,0.706940594 C1.18550245,0.566234019 1.4698481,0.553427291 1.70931818,0.673090909 L16.9815,8.30918182 C17.2429252,8.4402064 17.4079937,8.70757813 17.4079937,9 C17.4079937,9.29242187 17.2429252,9.5597936 16.9815,9.69081818 L1.70931818,17.3269091 C1.4698481,17.4465727 1.18550245,17.433766 0.957759255,17.2930594 C0.730016062,17.1523528 0.591181818,16.9037946 0.591181818,16.6360909 L0.591181818,1.36390909 Z" />
    </g>
  </Svg>
)

export default VideoControlsPlayIcon
