import React from 'react'
import FolderIcon from 'components/icons/Folder'
import ReactHtmlParser from 'react-html-parser'
import { ChevronIcon, spacer } from 'stylist'
import styled from '@emotion/styled'
import { offWhite2 } from 'styles/color'

const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${spacer(3)};

  & + & {
    border-top: 1px solid ${offWhite2};
  }

  svg {
    stroke-width: 2.2;
  }
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 390px;
  max-width: 390px;

  span {
    margin-left: ${spacer(2)};
    max-width: 356px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const ProjectList = ({
  projects = [],
  query = '',
  onProjectSelection = () => {}
}) => {
  const matchesRegex = new RegExp(query, 'i')

  const replaceMatches = str =>
    query ? str.replace(matchesRegex, c => `<b>${c}</b>`) : str

  return projects.map(project => (
    <Item key={project.id} onClick={() => onProjectSelection(project)}>
      <ItemContainer>
        <FolderIcon />
        <span>{ReactHtmlParser(replaceMatches(project.name))}</span>
      </ItemContainer>
      <ChevronIcon size="m" direction="right" />
    </Item>
  ))
}

export default ProjectList
