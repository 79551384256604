import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from '@apollo/react-components'
import ADVANCED_EDITOR_QUERY from 'components/AdvancedEditor/AdvancedEditor.query'
import {
  TranscriptSettings_humanAnalysis,
  TranscriptSettings_machineAnalysis
} from 'components/AdvancedEditor/TranscriptSettings/TranscriptSettings.fragments'
import { AnalysisLeftSidebar_videoAnalysis } from 'components/AdvancedEditor/AnalysisLeftSidebar/index.fragments'
import { HighlightClips_videoAnalysis } from 'components/AdvancedEditor/Highlights/HighlightClips.fragments'
import { PollAnalysis_videoAnalysis } from 'components/AdvancedEditor/PollData/PollAnalysis.fragments'
import { Transcript_videoAnalysis } from 'components/AdvancedEditor/Transcript/Transcript.fragments'

export const CREATE_VIDEO_ANALYSIS = gql`
  mutation CreateVideoAnalysisMutation(
    $input: CreateVideoAnalysisInput!
    $limit: Int
    $sentiment: [Sentiment]
    $entities: [String]
    $keyPhrases: [String]
  ) {
    createVideoAnalysis(input: $input) {
      __typename
      videoAnalysis {
        __typename
        id
        videoId
        transcriptType

        ...TranscriptSettings_humanAnalysis
        ...TranscriptSettings_machineAnalysis
        ...AnalysisLeftSidebar_videoAnalysis
        ...HighlightClips_videoAnalysis
        ...PollAnalysis_videoAnalysis
        ...Transcript_videoAnalysis
      }
    }
  }

  ${TranscriptSettings_humanAnalysis}
  ${TranscriptSettings_machineAnalysis}
  ${AnalysisLeftSidebar_videoAnalysis}
  ${HighlightClips_videoAnalysis}
  ${PollAnalysis_videoAnalysis}
  ${Transcript_videoAnalysis}
`

const CreateVideoAnalysis = ({
  children,
  video,
  insightsVideoQueryVariables
}) => (
  <Mutation
    context={{
      headers: {
        Authorization: video && `Bearer ${video.highlightReelJwt}`
      }
    }}
    mutation={CREATE_VIDEO_ANALYSIS}
    update={(
      cache,
      {
        data: {
          createVideoAnalysis: { videoAnalysis }
        }
      }
    ) => {
      const { video: insightsVideo } = cache.readQuery({
        query: ADVANCED_EDITOR_QUERY,
        variables: insightsVideoQueryVariables
      })

      cache.writeQuery({
        query: ADVANCED_EDITOR_QUERY,
        variables: insightsVideoQueryVariables,
        data: {
          video: {
            ...insightsVideo,
            [`${videoAnalysis.transcriptType.toLowerCase()}Analysis`]: videoAnalysis
          }
        }
      })
    }}
  >
    {(mutation, props) =>
      children({
        ...props,
        createVideoAnalysis: ({ videoId, transcriptType }) =>
          mutation({
            variables: {
              input: {
                videoId,
                transcriptType
              }
            }
          })
      })
    }
  </Mutation>
)

export default CreateVideoAnalysis
