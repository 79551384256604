import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  styled,
  borderRadius,
  padding,
  marginTop,
  marginBottom,
  background,
  lineHeight,
  color,
  borderBottomColor
} from '@fv/design-tokens'
import ClipContext from 'components/AdvancedEditor/ReelBuilder/ClipProvider'
import VideoControllerContext from 'components/VideoPlayer/VideoControllerContext'
import HighlightQuery from 'components/HighlightQuery'
import ClipControlBar from 'components/AdvancedEditor/ClipControlBar'
import {
  calcTop,
  calcLeft,
  getPlacement
} from 'components/AdvancedEditor/ClipControlBar/utils'

const TranscriptFragmentContainer = styled.div`
  position: relative;
  ${borderRadius('m')};
  ${padding('xs')};
  ${marginTop('xs')};
  ${marginBottom('xs')};

  transition: background 0.15s;

  ${({ isCurrentSentence, theme }) =>
    isCurrentSentence &&
    `${background('backgroundShadedBorderColor', { theme })}`};

  &:hover {
    ${background('backgroundShaded')};
  }
`

const TranscriptText = styled.div`
  ${lineHeight('l')};

  mark {
    background: transparent;
    ${color('text')};
    border-bottom: 3px solid;
    ${borderBottomColor('primary')};
  }
`

const _isCurrentSentence = (
  { startTime: sentenceStartTime, endTime: sentenceEndTime },
  currentTime
) => sentenceStartTime < currentTime && sentenceEndTime > currentTime

const _isCurrentClip = (
  { startTime: sentenceStartTime, endTime: sentenceEndTime },
  { startTime: clipStartTime, endTime: clipEndTime }
) => sentenceStartTime >= clipStartTime && sentenceEndTime <= clipEndTime

const TranscriptFragment = ({
  autoScroll,
  canEdit,
  index,
  scrollContainerClientRect,
  selectedText,
  sentence
}) => {
  const containerRef = useRef(null)
  const clip = useContext(ClipContext)
  const { currentTime } = useContext(VideoControllerContext)
  const isCurrentClip = _isCurrentClip(sentence, clip)
  const isCurrentSentence = _isCurrentSentence(sentence, currentTime)
  const [showControlBar, setShowControlBar] = useState(false)
  const containerClientRect =
    containerRef.current && containerRef.current.getBoundingClientRect()

  useEffect(() => {
    if (autoScroll && isCurrentSentence) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }, [autoScroll, isCurrentSentence])

  return (
    <TranscriptFragmentContainer
      onMouseOver={() => !showControlBar && setShowControlBar(true)}
      onMouseLeave={() => showControlBar && setShowControlBar(false)}
      isCurrentSentence={isCurrentSentence}
      isCurrentClip={isCurrentClip}
      ref={containerRef}
    >
      <TranscriptText
        isCurrentSentence={isCurrentSentence}
        data-start-time={sentence.startTime}
        data-end-time={sentence.endTime}
      >
        <HighlightQuery string={sentence.text} />{' '}
      </TranscriptText>
      {!selectedText && showControlBar && (
        <ClipControlBar
          canCreateClip={canEdit}
          center
          top={calcTop({
            scrollContainerClientRect,
            containerClientRect
          })}
          left={calcLeft({
            containerClientRect
          })}
          placement={getPlacement({
            scrollContainerClientRect,
            elementOrRangeClientRect: containerClientRect
          })}
          startTime={sentence.startTime}
          endTime={sentence.endTime}
        />
      )}
    </TranscriptFragmentContainer>
  )
}

export default TranscriptFragment
