import React, { Fragment } from 'react'
import PollAnalysis from './PollAnalysis'

const PollData = ({
  insightsVideo = {},
  highlightReelJwt,
  onAnalysisStageCompleted
}) => (
  <Fragment>
    {insightsVideo.machineAnalysis && (
      <PollAnalysis
        videoAnalysis={insightsVideo.machineAnalysis}
        highlightReelJwt={highlightReelJwt}
        onAnalysisStageCompleted={onAnalysisStageCompleted}
      />
    )}
    {insightsVideo.humanAnalysis && (
      <PollAnalysis
        videoAnalysis={insightsVideo.humanAnalysis}
        highlightReelJwt={highlightReelJwt}
        onAnalysisStageCompleted={onAnalysisStageCompleted}
      />
    )}
  </Fragment>
)

export default PollData
