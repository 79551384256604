import { styled } from '@fv/design-tokens'

const Container = styled.div`
  display: inline-block;
  position: relative;
`

Container.displayName = 'DropdownMenu.Container'

export default Container
