import {
  styled,
  borderRadius,
  marginLeft,
  marginRight,
  padding
} from '@fv/design-tokens'
import { cadet, offWhite, paleGrey, silver, slate, white } from 'styles/color'
import Placeholder from './Placeholder'
import TruncateText from 'components/TruncateText'

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease 0s;
  outline-color: ${slate};
  width: 100%;
  font-family: inherit;
  user-select: none;
  background-color: ${white};
  text-align: left;

  ${borderRadius('m')};
  ${padding('xs')};
  border: 1px solid ${silver};

  [aria-expanded='true'] & {
    border: 1px solid ${slate};
  }

  &::-moz-focus-inner {
    border: 0;
  }

  [aria-expanded='true'] & {
    color: ${white};
    background-color: ${slate};
    outline: none;
  }

  &:active {
    border-color: ${slate};
  }

  &:focus {
    outline-color: ${slate};
    outline-offset: -2px;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    cursor: not-allowed;
    color: ${cadet};
    background: ${offWhite};
    border: 1px solid ${paleGrey};
  }

  svg {
    &:first-child {
      ${marginRight('xxs')}
    }

    &:last-child {
      ${marginLeft('xxs')}
    }
  }

  @supports (-moz-appearance: none) {
    &:focus {
      outline-width: 1px;
      outline-style: dotted;
    }
  }

  ${TruncateText}, ${Placeholder} {
    flex: 1;
  }
`

Button.displayName = 'Dropdown.Button'

export default Button
