import React, { useEffect } from 'react'
import withNotifier from 'components/withNotifier'
import CloseButton from './CloseButton'
import Container from './Container'
import Icon from './Icon'
import Message from './Message'

const Notification = ({ notification, removeNotification }) => {
  let timer
  const remove = () => {
    removeNotification(notification.id)
  }
  const scheduleHide = () => {
    timer = setTimeout(remove, notification.timeout)
  }
  const cancelHide = () => {
    clearTimeout(timer)
  }

  useEffect(() => {
    if (!notification.sticky) {
      scheduleHide()
    }

    return () => {
      cancelHide()
      notification.onClose(notification)
    }
  }, [notification])

  return (
    <Container
      onMouseOver={cancelHide}
      onMouseOut={notification.sticky ? null : scheduleHide}
      onClick={() => notification.onClick(notification)}
    >
      <Icon kind={notification.type} renderIcon={notification.renderIcon} />
      <Message>{notification.message}</Message>
      {notification.dismissable && (
        <CloseButton
          onClick={e => {
            e.stopPropagation()
            remove()
          }}
        />
      )}
    </Container>
  )
}

export default withNotifier(Notification)
