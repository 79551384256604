import { useLocation, useHistory } from 'react-router'

const useQueryParams = () => {
  const history = useHistory()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  const setQueryParams = (params = {}) => {
    Object.entries(params).forEach(([key, value]) =>
      key && value !== undefined
        ? queryParams.set(key, value)
        : queryParams.delete(key)
    )

    history.replace({ search: queryParams.toString() })
  }

  return [queryParams, setQueryParams]
}

export default useQueryParams
