import React, { createContext, useState } from 'react'
import noop from 'utils/noop'

const NewClipContext = createContext({
  newClip: null,
  unsetNewClip: noop,
  setNewClip: noop,
  videoId: null,
  assetUrl: null
})

export const NewClipProvider = ({ videoId, assetUrl, children }) => {
  const [newClip, setNewClip] = useState(null)

  return (
    <NewClipContext.Provider
      value={{
        newClip,
        unsetNewClip: () => setNewClip(null),
        setNewClip: ({ name = '', startTime = 0, endTime = 4 } = {}) =>
          setNewClip({
            name,
            startTime,
            endTime
          }),
        videoId,
        assetUrl
      }}
    >
      {children}
    </NewClipContext.Provider>
  )
}

export default NewClipContext
