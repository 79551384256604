import React, { useContext } from 'react'
import { ModalV2 as Modal } from 'stylist'
import CopyClipDialog from './CopyClipDialog'
import CopyClipToProjectDialog from './CopyClipToProjectDialog'
import CurrentUserContext from 'components/CurrentUser/Context'

const CopyClipModal = ({
  reelId,
  projectId,
  reelVideoClip,
  children,
  onDismiss
}) => {
  const { hasFeature } = useContext(CurrentUserContext)

  return (
    <>
      {hasFeature('20210929_copy_clips_between_projects') ? (
        <Modal
          lazyRender
          blurBackground
          onDismiss={onDismiss}
          render={({ hide }) => (
            <CopyClipToProjectDialog
              sourceReelId={reelId}
              reelVideoClip={reelVideoClip}
              hide={() => {
                onDismiss()
                hide()
              }}
            />
          )}
        >
          {children}
        </Modal>
      ) : (
        <Modal
          lazyRender
          blurBackground
          onDismiss={onDismiss}
          render={({ hide }) => (
            <CopyClipDialog
              reelId={reelId}
              projectId={projectId}
              reelVideoClip={reelVideoClip}
              hide={() => {
                onDismiss()
                hide()
              }}
            />
          )}
        >
          {children}
        </Modal>
      )}
    </>
  )
}

export default CopyClipModal
