import React, { useState, useContext, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router'
import {
  styled,
  background,
  borderRadius,
  marginBottom,
  marginLeft,
  padding,
  colorFor,
  css
} from '@fv/design-tokens'
import DropdownMenu from 'components/DropdownMenu'
import Button from 'components/Button'
import MoreIcon from 'components/icons/More'
import PlaySmIcon from 'components/icons/PlaySm'
import PauseSmIcon from 'components/icons/PauseSm'
import ClipDurationBar from './ClipDurationBar'
import DeleteClipModal from './DeleteClipModal'
import CopyClipModal from './CopyClipModal'
import ClipForm from './ClipForm'
import { Draggable } from 'react-beautiful-dnd'
import Tooltip from 'components/Tooltip'
import CurrentUserContext from 'components/CurrentUser/Context'
import MultiVideoControllerContext from 'components/MultiVideoPlayer/MultiVideoControllerContext'
import ClipsPreviewContext from './ClipsPreviewProvider/Context'
import useQueryParams from 'hooks/useQueryParams'

const getActiveTab = pathname =>
  pathname.substring(pathname.lastIndexOf('/') + 1)

const pathToClipVideoSource = ({ pathname, companyId, responseId }) =>
  `/a/companies/${companyId}/videos/${responseId}/${getActiveTab(pathname)}`

const highlightCss = ({ highlight, theme }) =>
  highlight &&
  css`
    border: 1px solid ${colorFor('highlightBackground', { theme })};
  `

const Container = styled.div`
  position: relative;
  border: 1px solid transparent;
  ${marginBottom('s')};
  ${padding('s')};
  ${background('background')};
  ${borderRadius('m')};

  ${highlightCss}
`

const ClipContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${marginBottom('s')};
`

const Bottom = styled.div``

const Name = styled.span`
  flex: 1;
  ${marginLeft('s')};
`

const ReelVideoClip = ({
  reelId,
  projectId,
  index,
  playing,
  loading,
  reelVideoClip,
  reelVideoClip: {
    id,
    name,
    startTime,
    endTime,
    videoLength,
    content: {
      video: {
        response: { id: responseId }
      }
    }
  },
  handlePlay,
  handleDelete,
  handleSubmit
}) => {
  const { search, pathname } = useLocation()
  const { videoId: currentResponseId, companyId } = useParams()
  const containerRef = useRef(null)
  const [editMode, setEditMode] = useState(false)
  const { hasFeature } = useContext(CurrentUserContext)
  const { openClips } = useContext(MultiVideoControllerContext)
  const [query, setQueryParams] = useQueryParams()
  const { show: showPreview } = useContext(ClipsPreviewContext)
  const [isDragDisabled, setIsDragDisabled] = useState(false)

  useEffect(() => {
    if (query.get('clip') === id && containerRef.current) {
      openClips([reelVideoClip])
      showPreview()
      setTimeout(() => containerRef.current.scrollIntoView())
    }
  }, [containerRef])

  const draggableCtrl = () => setIsDragDisabled(!isDragDisabled)

  return editMode ? (
    <ClipForm
      loading={loading}
      handleSubmit={async args => {
        await handleSubmit(args)
        setEditMode(false)
      }}
      handleCancel={() => setEditMode(false)}
      {...reelVideoClip}
    />
  ) : (
    <Draggable
      draggableId={id}
      index={index}
      key={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <Container
          highlight={playing || query.get('clip') === id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ClipContainer ref={containerRef}>
            <Top>
              <Tooltip
                placement="top"
                render={() => (playing ? 'Pause' : 'Play')}
              >
                {({ show, hide, ref }) => (
                  <Button
                    round
                    square
                    onClick={() => {
                      handlePlay()
                      setQueryParams({ clip: id })
                    }}
                    onMouseOver={show}
                    onMouseOut={hide}
                    ref={ref}
                  >
                    {playing ? <PauseSmIcon /> : <PlaySmIcon />}
                  </Button>
                )}
              </Tooltip>
              <Name>{name}</Name>
              <DropdownMenu
                align="right"
                trigger={(toggle, open) => (
                  <Button
                    aria-label="Clip Options"
                    aria-pressed={open}
                    square
                    onClick={toggle}
                    type="button"
                  >
                    <MoreIcon direction="horizontal" />
                  </Button>
                )}
              >
                <DropdownMenu.Item onClick={() => setEditMode(true)}>
                  Edit
                </DropdownMenu.Item>
                {hasFeature('20200128_streamline_v2') && (
                  <>
                    <DropdownMenu.Item
                      disabled={currentResponseId === responseId}
                      to={
                        currentResponseId !== responseId
                          ? {
                              pathname: pathToClipVideoSource({
                                companyId,
                                responseId,
                                pathname
                              }),
                              search
                            }
                          : ''
                      }
                    >
                      View source archive
                    </DropdownMenu.Item>
                    <CopyClipModal
                      reelId={reelId}
                      projectId={projectId}
                      reelVideoClip={reelVideoClip}
                      onDismiss={draggableCtrl}
                    >
                      {({ show }) => (
                        <DropdownMenu.Item
                          onClick={() => {
                            draggableCtrl()
                            show()
                          }}
                        >
                          Copy to...
                        </DropdownMenu.Item>
                      )}
                    </CopyClipModal>
                  </>
                )}
                <DeleteClipModal
                  name={name}
                  handleDelete={handleDelete}
                  onDismiss={draggableCtrl}
                >
                  {({ show }) => (
                    <DropdownMenu.Item
                      onClick={() => {
                        draggableCtrl()
                        show()
                      }}
                    >
                      Delete
                    </DropdownMenu.Item>
                  )}
                </DeleteClipModal>
              </DropdownMenu>
            </Top>
            <Bottom>
              <ClipDurationBar clip={reelVideoClip} videoLength={videoLength} />
            </Bottom>
          </ClipContainer>
        </Container>
      )}
    </Draggable>
  )
}

export default ReelVideoClip
