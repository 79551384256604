export const handleError = ({
  notify,
  error,
  message = 'Something went wrong.'
}) => {
  if (process.env.REACT_APP_ENV !== 'development') {
    console.error(error)
  }

  notify('danger', message)

  return { error }
}

export const reorder = (list, from, to) => {
  const clonedList = [...list]

  clonedList.splice(to, 0, clonedList.splice(from, 1)[0])

  return clonedList
}

export const PUBLISH_STATUSES = {
  NONE: 'none',
  REQUESTED: 'requested',
  PROCESSING: 'processing',
  COMPLETE: 'complete',
  FAILED: 'failed'
}

export const FINALIZING_STATUSES = [
  PUBLISH_STATUSES.REQUESTED,
  PUBLISH_STATUSES.PROCESSING
]
