import React from 'react'
import {
  background,
  styled,
  css,
  color,
  marginTop,
  marginRight,
  marginLeft,
  paddingLeft,
  paddingRight
} from '@fv/design-tokens'
import withQueryParams from 'components/withQueryParams'
import { toggleParamListOption, valuesFromParams } from 'utils/params'
import CloseIcon from 'components/icons/Close'
import Button from 'components/Button'

const Occurrences = styled.span`
  color: #666;
  ${marginLeft('xs')};
  ${marginRight('xs')};
`

const ifActive = ({ active, theme }) =>
  active &&
  css`
    ${background('buttonBackground', { theme })};

    > svg:last-of-type {
      ${color('buttonText', { theme })};
    }

    &:hover {
      ${background('buttonPrimaryBackground', { theme })};
      ${color('textReverse', { theme })};

      > svg:last-of-type {
        ${color('textReverse', { theme })};
      }

      ${Occurrences} {
        ${color('textReverse', { theme })};
      }
    }
  `

const AnalysisItem = styled.li`
  &:not(:first-of-type) {
    ${marginTop('xxs')};
  }
`

const Toggle = styled(Button)`
  width: 100%;
  ${paddingLeft('xs')};
  ${paddingRight('xs')};
  justify-content: left;
  ${color('text')};

  &:hover {
    ${color('text')};
  }

  &:active {
    ${background('buttonPrimaryBackground')};

    &:hover {
      ${color('textReverse')};

      ${Occurrences} {
        ${color('textReverse')};
      }
    }
  }

  > svg:last-of-type {
    height: auto;
    margin-left: auto;
  }

  ${ifActive};
`

const ChildContainer = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const isActive = (paramKey, paramValue, params) =>
  valuesFromParams(params, paramKey).includes(paramValue)

const toggleQueryItem = (paramKey, item, params, history, { pathname }) => {
  toggleParamListOption(params, paramKey, item)
  history.replace({
    pathname,
    search: params.toString()
  })
}

const AnalysisToggle = ({
  paramKey,
  paramValue,
  occurrences,
  query: params,
  history,
  location,
  children,
  ...rest
}) => {
  const active = isActive(paramKey, paramValue, params)
  return (
    <AnalysisItem>
      <Toggle
        title={paramValue}
        active={active}
        aria-label={paramValue}
        aria-pressed={active}
        ghost
        onClick={() =>
          toggleQueryItem(paramKey, paramValue, params, history, location)
        }
        type="button"
        {...rest}
      >
        <ChildContainer>{children}</ChildContainer>
        {occurrences && <Occurrences>{occurrences}</Occurrences>}
        {active && <CloseIcon width={14} height={14} />}
      </Toggle>
    </AnalysisItem>
  )
}

export default withQueryParams(AnalysisToggle)
