import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Routes from 'routes/index'
import RouteUnauthorized from 'routes/unauthorized'
import ErrorBoundary from 'components/ErrorBoundary'
import CurrentUserProvider from 'components/CurrentUser/Provider'
import { NotificationsProvider } from 'components/Notifications/Context'
import Notifications from 'components/Notifications'
import Monit from 'components/Monit'

const App = () => (
  <ErrorBoundary>
    <CurrentUserProvider>
      <NotificationsProvider>
        <Notifications />
        <Monit>
          <Switch>
            <Route path="/unauthorized" component={RouteUnauthorized} />
            <Route path="/" component={Routes} />
          </Switch>
        </Monit>
      </NotificationsProvider>
    </CurrentUserProvider>
  </ErrorBoundary>
)

export default App
