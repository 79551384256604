import gql from 'graphql-tag'

const VI_COMPANY_QUERY = gql`
  query VIWorkspacessQuery($companyId: String!) {
    company(companyId: $companyId)
      @jsonapi(
        path: "/companies/{args.companyId}?fields[companies]=id,restrict_download_videos_fl"
      ) {
      id
      restrictDownloadVideosFl
    }
  }
`

export default VI_COMPANY_QUERY
