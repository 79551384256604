import gql from 'graphql-tag'

export const AnalysisLeftSidebar_videoAnalysis = gql`
  fragment AnalysisLeftSidebar_videoAnalysis on VideoAnalysis {
    entitiesOccurrences(first: $limit) {
      pageInfo {
        endCursor
        hasNextPage
      }

      edges {
        node {
          text
          occurrences
        }
      }
    }

    keyPhrasesOccurrences(first: $limit) {
      pageInfo {
        endCursor
        hasNextPage
      }

      edges {
        node {
          text
          occurrences
        }
      }
    }
  }
`
