import React from 'react'
import Svg from 'components/Svg'

const EmptyIcon = props => (
  <Svg viewBox="0 0 16 16" {...props}>
    <title>{props.title || 'Empty'}</title>
  </Svg>
)

export default EmptyIcon
