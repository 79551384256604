import React from 'react'
import Svg from 'components/Svg'

const FolderIcon = ({ width = 24, height = 25, title }) => (
  <Svg width={width} height={height} viewBox="0 0 24 25" fill="none">
    <title>{title || 'Folder'}</title>
    <path
      d="M9.17 6.72852L11.17 8.72852H20V18.7285H4V6.72852H9.17ZM10 4.72852H4C2.9 4.72852 2.01 5.62852 2.01 6.72852L2 18.7285C2 19.8285 2.9 20.7285 4 20.7285H20C21.1 20.7285 22 19.8285 22 18.7285V8.72852C22 7.62852 21.1 6.72852 20 6.72852H12L10 4.72852Z"
      fill="#323232"
    />
  </Svg>
)

export default FolderIcon
