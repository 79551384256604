import React from 'react'
import { Button, ModalV2 as Modal, Dialog } from 'stylist'
import Loader from 'components/Loader'

const UnpublishReelModal = ({
  loading,
  handleCancel,
  handleSubmit,
  children
}) => (
  <Modal
    blurBackground
    onDismiss={handleCancel}
    render={({ hide }) => (
      <Dialog>
        <Dialog.Header>
          <Dialog.Title>Unpublish Reel to Edit</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          The reel is published. Any edits will require the reel to be
          unpublished. Do you wish to proceed?
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            disabled={loading}
            kind="ghost"
            onClick={() => {
              handleCancel()
              hide()
            }}
          >
            Cancel
          </Button>
          <Button
            kind="primary"
            disabled={loading}
            onClick={() => {
              handleSubmit()
              hide()
            }}
          >
            {loading ? <Loader /> : 'Unpublish'}
          </Button>
        </Dialog.Footer>
      </Dialog>
    )}
  >
    {children}
  </Modal>
)

export default UnpublishReelModal
