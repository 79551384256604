import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const VI_CREATE_VIDEO_CLIP = gql`
  mutation VICreateVideoClipMutation($input: CreateVideoClipInput!) {
    createVideoClip(input: $input)
      @jsonapi(
        path: "/video_clips?include=video,video.response&fields[videos]=id,response&fields[responses]=id"
        method: "POST"
      ) {
      id
      name
      startTime
      endTime
      assetUrl
      video {
        id
        response {
          id
        }
      }
    }
  }
`

export const buildCreateVideoClipVariables = ({
  name,
  startTime,
  endTime,
  videoId
}) => ({
  input: {
    data: {
      type: 'video_clips',
      attributes: {
        name,
        startTime,
        endTime
      },
      relationships: {
        video: {
          data: { type: 'videos', id: videoId }
        }
      }
    }
  }
})

const useCreateVideoClipMutation = () => {
  const [mutate, result] = useMutation(VI_CREATE_VIDEO_CLIP)

  const createVideoClip = ({
    name,
    startTime,
    endTime,
    videoId,
    assetUrl = ''
  }) =>
    mutate({
      variables: buildCreateVideoClipVariables({
        name,
        startTime,
        endTime,
        videoId
      })
    })

  return [createVideoClip, result]
}

export default useCreateVideoClipMutation
