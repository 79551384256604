import gql from 'graphql-tag'
import { ReelCard_reel } from './ReelsIndex/ReelCard.fragments'
import { ReelBuilderHeader_reel } from './Header.fragments'

export const ReelBuilder_reel = gql`
  fragment ReelBuilder_reel on VIReels {
    id
    name
    publishStatus
    publishDirtyFl
    publishVideoUrl

    ...ReelCard_reel
    ...ReelBuilderHeader_reel
  }

  ${ReelCard_reel}
  ${ReelBuilderHeader_reel}
`
