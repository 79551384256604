import gql from 'graphql-tag'

export const ReelVideoClips_workspace = gql`
  fragment ReelVideoClips_workspace on VIWorkspaces {
    id
  }
`

export const ReelVideoClips_reelVideoClip = gql`
  fragment ReelVideoClips_reelVideoClip on VIReelVideoClips {
    content {
      id
    }
  }
`
