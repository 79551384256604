import React, { useContext } from 'react'
import { VideoPlayer as ReactVideoPlayer } from 'react-video-player'
import VideoControllerContext from './VideoControllerContext'

const VideoPlayer = ({ sources, languageSources = [], plugins }) => {
  const {
    handleLoadedMetaData,
    handleOnPause,
    handleOnPlay,
    handleOnEnded,
    handleTimeUpdate,
    videoPlayerRef
  } = useContext(VideoControllerContext)

  return (
    <ReactVideoPlayer
      onLoadedMetaData={handleLoadedMetaData}
      onTimeUpdate={handleTimeUpdate}
      onPause={handleOnPause}
      onPlay={handleOnPlay}
      onEnded={handleOnEnded}
      ref={videoPlayerRef}
      sources={sources}
      languageSources={languageSources}
      plugins={plugins}
    />
  )
}

export default VideoPlayer
