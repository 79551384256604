import React from 'react'
import { Link } from 'react-router-dom'
import { styled, padding } from '@fv/design-tokens'

const ItemLink = styled(({ external, to, ...props }) =>
  external ? (
    <a href={to} {...props}>
      {props.children}
    </a>
  ) : (
    <Link to={to} {...props} />
  )
)`
  display: block;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${padding('xs')};

  &:hover {
    background-color: rgba(0, 112, 255, 0.15);
  }
`

ItemLink.displayName = 'DropdownMenu.ItemLink'

export default ItemLink
