import { useMemo } from 'react'
import { useLocation } from 'react-router'
import ReactHtmlParser from 'react-html-parser'
import { isFunction } from 'utils'
import { valuesFromLocation } from 'utils/params'

const HighlightQuery = ({ string, children }) => {
  const location = useLocation()
  const entityQuery = valuesFromLocation(location, 'entity')
  const keyphraseQuery = valuesFromLocation(location, 'keyphrase')
  const query = entityQuery.concat(keyphraseQuery)

  if (!query.length) {
    return isFunction(children)
      ? children({
          result: string
        })
      : string
  }

  const result = useMemo(() => {
    const pattern = new RegExp(`\\b(${query.join('|')})\\b`, 'g')

    return string.replace(pattern, match => `<mark>${match}</mark>`)
  }, [query.toString()])

  return isFunction(children)
    ? children({
        result: ReactHtmlParser(result)
      })
    : ReactHtmlParser(result)
}

export default HighlightQuery
