import { styled, borderColor } from '@fv/design-tokens'

export const LeftSidebar = styled.div`
  grid-area: videoLeftSidebar;
  display: grid;
  border-top: 1px solid;
  ${borderColor('backgroundBorderColor')};
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: gray transparent;
`

export const LeftSidebarContainer = styled.div`
  width: 220px;
  border-right: 1px solid;
  ${borderColor('backgroundBorderColor')};
`
