import React from 'react'

const Plus = () => (
  <svg width="32px" height="32px" x="0px" y="0px" viewBox="0 0 32 32">
    <rect x="4" y="15" width="24" height="2" />
    <rect x="15" y="4" width="2" height="24" />
  </svg>
)

export default Plus
