import React, { useState } from 'react'
import { Provider } from './Context'

const ClipsPreviewProvider = ({ children }) => {
  const [hidden, setHidden] = useState(true)

  return (
    <Provider
      value={{
        show: () => setHidden(false),
        hide: () => setHidden(true),
        hidden
      }}
    >
      {children}
    </Provider>
  )
}

export default ClipsPreviewProvider
