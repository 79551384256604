import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import VI_VIDEO_SOURCES_QUERY, {
  VI_DYNAMIC_VIDEO_SOURCES_QUERY
} from './VideoSources.query'
import VideoSources from './VideoSources'
import { getNextPagePath } from 'utils'

const VideoSourcesContainer = ({ projectId }) => {
  const {
    data: {
      responses: {
        graphql: responses = [],
        jsonapi: { links: { next } = {} } = {}
      } = {}
    } = {},
    loading,
    error,
    fetchMore
  } = useQuery(VI_VIDEO_SOURCES_QUERY, {
    variables: {
      projectId
    },
    fetchPolicy: 'cache-and-network'
  })

  return (
    <VideoSources
      responses={responses}
      loading={loading}
      error={error}
      fetchMore={() => {
        if (!next || loading) {
          return
        }

        fetchMore({
          query: VI_DYNAMIC_VIDEO_SOURCES_QUERY,
          variables: {
            path: getNextPagePath(next)
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev

            return {
              ...prev,
              responses: {
                ...fetchMoreResult.responses,
                graphql: [
                  ...prev.responses.graphql,
                  ...fetchMoreResult.responses.graphql
                ]
              }
            }
          }
        })
      }}
    />
  )
}

export default VideoSourcesContainer
