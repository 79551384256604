import React from 'react'
import FeatherSvg from 'components/FeatherSvg'

const Caret = props => (
  <FeatherSvg fill="currentColor" strokeWidth={0} {...props}>
    <title>caret</title>
    <path d="M0 6h24L12 18z" />
  </FeatherSvg>
)

export default Caret
