const DEFAULT_VARIANTS = Object.freeze({
  enter: reverse => {
    return {
      x: reverse ? '-35%' : '35%',
      opacity: 0
    }
  },
  center: {
    x: '0%',
    opacity: 1
  },
  exit: reverse => {
    return {
      x: reverse ? '35%' : '-35%',
      opacity: 0
    }
  }
})

export default DEFAULT_VARIANTS
