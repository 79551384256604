import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { ReelBuilder_reel } from 'components/AdvancedEditor/ReelBuilder/ReelBuilder.fragments'
import VI_WORKSPACES_QUERY from 'components/AdvancedEditor/ReelBuilder/vi_workspaces.query'
import VI_REEL_QUERY from 'components/AdvancedEditor/ReelBuilder/reel.query.js'

export const VI_RENAME_REEL_MUTATION = gql`
  mutation VIRenameReel($input: UpdateReelInput!) {
    renameReel(input: $input)
      @jsonapi(path: "/reels/{args.input.data.id}", method: "PATCH") {
      id
      ...ReelBuilder_reel
    }
  }

  ${ReelBuilder_reel}
`

const useRenameReelMutation = () => {
  const [mutate, ...statusParams] = useMutation(VI_RENAME_REEL_MUTATION)

  const renameReel = ({
    id,
    name,
    reelVideoClips,
    userId,
    projectId,
    ...attributes
  }) =>
    mutate({
      variables: {
        input: {
          data: {
            type: 'reels',
            id,
            attributes: {
              name
            }
          }
        }
      },
      optimisticResponse: {
        renameReel: {
          __typename: 'VIReels',
          type: 'reels',
          id,
          name,
          ...attributes
        }
      },
      update: (cache, { data: { renameReel: renamedReel } }) => {
        const {
          workspaces: [workspace]
        } = cache.readQuery({
          query: VI_WORKSPACES_QUERY,
          variables: { userId, projectId }
        })

        const { reels = [] } = workspace

        cache.writeQuery({
          query: VI_WORKSPACES_QUERY,
          variables: { userId, projectId },
          data: {
            workspaces: [
              {
                ...workspace,
                reels: reels.map(reel =>
                  reel.id === renamedReel.id
                    ? { ...reel, name: renamedReel.name }
                    : reel
                )
              }
            ]
          }
        })

        const { reel } = cache.readQuery({
          query: VI_REEL_QUERY,
          variables: { reelId: renamedReel.id }
        })

        cache.writeQuery({
          query: VI_REEL_QUERY,
          variables: { reelId: renamedReel.id },
          data: { reel: { ...reel, name: renamedReel.name } }
        })
      }
    })

  return [renameReel, statusParams]
}

export default useRenameReelMutation
