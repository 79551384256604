import gql from 'graphql-tag'

export const CopyClipModal_reelVideoClip = gql`
  fragment CopyClipModal_reelVideoClip on VIReelVideoClips {
    name
    startTime
    endTime
    content {
      video {
        id
      }
    }
  }
`
