import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router'
import {
  styled,
  css,
  background,
  marginBottom,
  marginRight,
  marginTop,
  paddingBottom,
  paddingLeft,
  paddingRight
} from '@fv/design-tokens'
import { electricBlue } from 'styles/color'
import { Button } from 'stylist'
import { InlineButtonLink } from 'components/Button'
import Dialog from 'components/Dialog'
import Loader from 'components/Loader'
import useCreateVideoClip from 'hooks/mutations/useCreateVideoClip'
import useCreateReelVideoClip from 'hooks/mutations/useCreateReelVideoClip'
import NotificationsContext from 'components/Notifications/Context'
import ReelSelector from './ReelSelector'

const DialogHeader = styled(Dialog.Header)`
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 3px -3px #0006;
  z-index: 2;
`

const DialogDescription = styled.p`
  ${marginBottom('m')};
  ${marginTop('m')};
`

const DialogBody = styled(Dialog.Body)`
  overflow: auto;
  max-height: 350px;

  ${background('backgroundShaded')};
  ${paddingLeft('xxl')};
  ${paddingRight('xxl')};
`

const DialogFooter = styled(Dialog.Footer)`
  box-shadow: 0 -3px 3px -3px #0006;
  z-index: 2;

  button + button {
    margin-left: 0.5rem;
  }
`

const ReelsTabContainer = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  ${marginTop('xs')};
`

const activeCss = ({ active }) =>
  active &&
  css`
    border-bottom: 4px solid ${electricBlue};
  `

const ReelsTabItem = styled.li`
  list-style: none;
  cursor: pointer;

  ${paddingBottom('xxs')};
  ${marginRight('s')};
  ${activeCss};
`

const SuccessNotification = ({ clipName, reel }) => {
  const { pathname } = useLocation()

  return (
    <span>
      Successfully copied {clipName} clip to{' '}
      <InlineButtonLink
        to={{
          pathname,
          search: `?panel=showreelBuilder&reel=${reel.id}`
        }}
        replace
      >
        {reel.name}
      </InlineButtonLink>{' '}
      showreel.
    </span>
  )
}

const CopyClipDialog = ({
  reelId,
  projectId,
  reelVideoClip: {
    name,
    startTime,
    endTime,
    content: {
      video: { id: videoId }
    }
  },
  hide
}) => {
  const [activeTab, setActiveTab] = useState('userReels')
  const { notify } = useContext(NotificationsContext)
  const [selectedReel, setSelectedReel] = useState(null)
  const [
    createVideoClip,
    { loading: loadingCreateVideoClip }
  ] = useCreateVideoClip()
  const [
    createReelVideoClip,
    { loading: loadingCreateReelVideoClip }
  ] = useCreateReelVideoClip()

  return (
    <Dialog>
      <DialogHeader>
        <Dialog.Title>Select Destination</Dialog.Title>
        <DialogDescription>
          <strong>{name}</strong> will be copied to
          {selectedReel ? ` ${selectedReel.name}` : '..'}.
        </DialogDescription>
        <ReelsTabContainer>
          <ReelsTabItem
            key="my-reels"
            active={activeTab !== 'allReels'}
            onClick={() => {
              setActiveTab('userReels')
              setSelectedReel(null)
            }}
          >
            My Reels
          </ReelsTabItem>
          <ReelsTabItem
            key="all-reels"
            active={activeTab === 'allReels'}
            onClick={() => {
              setActiveTab('allReels')
              setSelectedReel(null)
            }}
          >
            All Reels
          </ReelsTabItem>
        </ReelsTabContainer>
      </DialogHeader>
      <DialogBody>
        <ReelSelector
          reelId={reelId}
          projectId={projectId}
          selectedReelId={selectedReel && selectedReel.id}
          onReelSelect={reel => setSelectedReel(reel)}
          activeTab={activeTab}
        />
      </DialogBody>
      <DialogFooter>
        <Button
          kind="ghost"
          onClick={hide}
          disabled={loadingCreateVideoClip || loadingCreateReelVideoClip}
        >
          Cancel
        </Button>
        <Button
          kind="primary"
          onClick={async () => {
            try {
              const {
                data: {
                  createVideoClip: { id: videoClipId }
                }
              } = await createVideoClip({
                name,
                startTime,
                endTime,
                videoId: videoId
              })
              await createReelVideoClip({
                videoClipId,
                reelId: selectedReel.id,
                name,
                startTime,
                endTime
              })
            } catch (e) {
              notify('danger', 'Something weng wrong.')
              return
            }

            notify(
              'success',
              <SuccessNotification clipName={name} reel={selectedReel} />
            )
            hide()
          }}
          disabled={
            !selectedReel ||
            loadingCreateVideoClip ||
            loadingCreateReelVideoClip
          }
        >
          {loadingCreateVideoClip || loadingCreateReelVideoClip ? (
            <Loader aria-label="Copy clip loader" />
          ) : (
            'Copy'
          )}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default CopyClipDialog
