import { compose, prop, valueOr } from 'utils/fp'

const getFeatures = compose(
  valueOr([]),
  prop('features'),
  valueOr({}),
  prop('currentUser'),
  valueOr({})
)

export const hasFeature = (data, feature) =>
  getFeatures(data).indexOf(feature) > -1
