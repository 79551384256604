import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { JsonApiLink } from 'apollo-link-json-api'
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link'
import { hasSubscription } from 'utils/graphql'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import absintheSocket from './absintheSocket'
import introspectionQueryResultData from './fragmentTypes.json'
import {
  getAccessToken,
  getHighlightReelAccessToken,
  redirectToLogin
} from 'utils/session'
import { camelize, decamelize, pascalize } from 'humps'

// If an object is from GraphQL, it will have the typename encoded into the ID
// If an object is from JSON API, it will have a sequential ID, so include typename
export const dataIdFromObject = ({ id, __typename }) =>
  __typename ? (__typename.startsWith('VI') ? `${__typename}:${id}` : id) : null

const cache = new InMemoryCache({
  dataIdFromObject,
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  })
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    if (
      networkError.statusCode === 401 &&
      operation.operationName.startsWith('VI')
    ) {
      redirectToLogin()
    }
    if (
      networkError.statusCode === 401 &&
      process.env.REACT_APP_ENV === 'development' &&
      !operation.operationName.startsWith('VI')
    ) {
      alert('Restart your insights-backend service')
    }
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }
})

const parseBatchResult = new ApolloLink((operation, forward) =>
  forward(operation).map(({ payload, ...rest }) => ({
    ...payload,
    ...rest
  }))
)

const httpLink = ApolloLink.split(
  operation => hasSubscription(operation.query),
  createAbsintheSocketLink(absintheSocket),
  new BatchHttpLink({
    uri: `${process.env.REACT_APP_HIGHLIGHT_API_URL}/graphql`,
    fetch: (uri, opts) =>
      window.fetch(uri, {
        ...opts,
        headers: {
          Authorization: `Bearer ${getHighlightReelAccessToken()}`,
          ...opts.headers
        }
      })
  })
)

const jsonApiLink = new JsonApiLink({
  uri: '/r/api',
  typeNameNormalizer: type => `VI${pascalize(type)}`,
  fieldNameNormalizer: name => camelize(name),
  fieldNameDenormalizer: name => decamelize(name),
  customFetch: (uri, opts) =>
    window.fetch(uri, {
      ...opts,
      headers: {
        'Content-Type': 'application/vnd.api+json',
        Authorization: `Bearer ${getAccessToken()}`,
        ...opts.headers
      }
    })
})

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, parseBatchResult, jsonApiLink, httpLink])
})

export default client
