import React, { useContext, forwardRef } from 'react'
import { styled, background, color } from '@fv/design-tokens'
import Button from 'components/Button'
import NewClipIcon from 'components/icons/NewClip'
import NewClipContext from 'components/AdvancedEditor/ReelBuilder/NewClipContext'
import withQueryParams from 'components/withQueryParams'
import { electricBlue } from 'styles/color'

const StyledNewClipIcon = styled(NewClipIcon)`
  width: 1rem;
  vertical-align: text-top;
  stroke: ${electricBlue};
  fill: none;
  color: transparent;
`

const StyledButton = styled(Button)`
  border-radius: 0;
  ${background('buttonPlayBackground')};
  ${color('buttonPlayText')};
`

const CreateClipButton = forwardRef(
  ({ startTime, endTime, history, query, ...rest }, ref) => {
    const { setNewClip } = useContext(NewClipContext)

    const ensureReelBuilderVisible = () => {
      if (query.get('panel') !== 'showreelBuilder') {
        query.set('panel', 'showreelBuilder')
        history.replace({ search: query.toString() })
      }
    }

    return (
      <StyledButton
        ref={ref}
        onClick={() => {
          ensureReelBuilderVisible()
          setNewClip({
            startTime: parseInt(startTime, 10),
            endTime: parseInt(endTime, 10)
          })
        }}
        {...rest}
      >
        <StyledNewClipIcon pointerEvents="none" />
      </StyledButton>
    )
  }
)

export default withQueryParams(CreateClipButton)
