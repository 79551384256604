import React from 'react'
import Svg from 'components/Svg'

const DotIcon = props => (
  <Svg viewBox="0 0 16 16" {...props}>
    <title>{props.title || 'dot'}</title>
    <circle cx="8" cy="8" r="5" />
  </Svg>
)

export default DotIcon
