export const ink = '#16263b'
export const red = '#f5496c'
export const lightRed = '#FFDBE2'
export const offWhite = '#F7F9FA'
export const offWhite2 = '#F5F5F5'
export const paleGrey = '#E4E8EB'
export const purple = '#5641CC'
export const green = '#00CC52'
export const green2 = '#39A84C'
export const navy = '#00306E'
export const black = '#111111'
export const white = '#FFFFFF'
export const electricBlue = '#0070FF'
export const cadet = '#8394A3'
export const pewter = '#627585'
export const slate = '#3E5467'
export const silver = '#CBD2D6'
export const orange = '#E07A04'
export const darkGrey = '#979797'
