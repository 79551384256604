import React from 'react'
import {
  styled,
  background,
  paddingLeft,
  paddingRight,
  marginBottom,
  color,
  colorFor
} from '@fv/design-tokens'
import NewClipIcon from 'components/icons/NewClip'
import Button from 'components/Button'
import useQueryParams from 'hooks/useQueryParams'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  ${background('backgroundShaded')};
  ${paddingLeft('m')};
  ${paddingRight('m')};
`

const ReelBuilderIcon = styled(NewClipIcon)`
  color: transparent;
  stroke: ${colorFor('textLt')};
  fill: none;
  ${marginBottom('xs')};
`
const PlaceholderText = styled.p`
  text-align: center;
  max-width: 200px;

  ${color('textLt')};
`

const NoReelsPlaceholder = () => {
  const [, setQueryParams] = useQueryParams()

  return (
    <Container>
      <ReelBuilderIcon width="32px" height="32px" />
      <PlaceholderText>
        It looks like you don't have any showreels yet.
      </PlaceholderText>
      <Button
        ghost
        onClick={() =>
          setQueryParams({ reelBuilderPanel: 'builder', reel: undefined })
        }
      >
        Create a new showreel
      </Button>
    </Container>
  )
}

export default NoReelsPlaceholder
