import { styled, padding } from '@fv/design-tokens'

const IconContainer = styled.div`
  ${padding('xs')};

  padding-right: 0;

  svg {
    vertical-align: middle;
  }
`

IconContainer.displayName = 'Notification.IconContainer'

export default IconContainer
