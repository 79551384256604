import React, { forwardRef } from 'react'
import { styled, marginLeft } from '@fv/design-tokens'
import Button from 'components/Button'
import Info from 'components/icons/Info'

const InlineButton = styled(Button)`
  display: inline-flex;
  ${marginLeft('xs')};
`

const InfoButton = forwardRef((props, ref) => (
  <InlineButton ref={ref} type="button" {...props}>
    <Info />
  </InlineButton>
))

export default InfoButton
