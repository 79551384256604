import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import VI_WORKSPACES_QUERY from 'components/AdvancedEditor/ReelBuilder/vi_workspaces.query.js'

export const VI_DELETE_REEL_MUTATION = gql`
  mutation VIDeleteReel($id: String!) {
    deleteReel(id: $id) @jsonapi(path: "/reels/{args.id}", method: "DELETE")
  }
`

const useDeleteReel = () => {
  const [mutate, statusParams] = useMutation(VI_DELETE_REEL_MUTATION)

  const deleteReel = ({ reelId, projectId, userId }) =>
    mutate({
      variables: {
        id: reelId
      },
      optimisticResponse: {
        deleteReel: null
      },
      update: cache => {
        const {
          workspaces: [workspace]
        } = cache.readQuery({
          query: VI_WORKSPACES_QUERY,
          variables: { userId, projectId }
        })

        const { reels } = workspace

        cache.writeQuery({
          query: VI_WORKSPACES_QUERY,
          variables: { userId, projectId },
          data: {
            workspaces: [
              {
                ...workspace,
                reels: reels.filter(reel => reel.id !== reelId)
              }
            ]
          }
        })
      }
    })

  return [deleteReel, statusParams]
}

export default useDeleteReel
