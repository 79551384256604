import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import VI_WORKSPACES_QUERY from 'components/AdvancedEditor/ReelBuilder/vi_workspaces.query'
import { ReelBuilder_reel } from 'components/AdvancedEditor/ReelBuilder/ReelBuilder.fragments'

export const VI_CREATE_WORKSPACE_MUTATION = gql`
  mutation VICreateWorkspaceMutation($input: CreateWorkspaceInput!) {
    createWorkspace(input: $input)
      @jsonapi(path: "/workspaces", method: "POST") {
      id
      reels {
        ...ReelBuilder_reel
      }
    }
  }

  ${ReelBuilder_reel}
`

const useCreateWorkspaceMutation = () => {
  const [mutate, statusParams] = useMutation(VI_CREATE_WORKSPACE_MUTATION)

  const createWorkspace = ({ userId, projectId, companyId }) =>
    mutate({
      variables: {
        input: {
          data: {
            type: 'workspaces',
            attributes: {
              name: `Advanced Workspace for Project ${projectId}`
            },
            relationships: {
              project: {
                data: {
                  type: 'projects',
                  id: projectId
                }
              },
              company: {
                data: {
                  type: 'companies',
                  id: companyId
                }
              }
            }
          }
        }
      },
      update: (cache, { data: { createWorkspace } }) => {
        cache.writeQuery({
          query: VI_WORKSPACES_QUERY,
          variables: { userId, projectId },
          data: { workspaces: [createWorkspace] }
        })
      }
    })

  return [createWorkspace, statusParams]
}

export default useCreateWorkspaceMutation
