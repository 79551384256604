import React from 'react'
import Svg from 'components/Svg'

const CaretUpIcon = props => (
  <Svg viewBox="0 0 16 16" {...props}>
    <title>{props.title || 'Caret Up'}</title>
    <path
      transform="translate(8.000000, 8.083333) scale(1, -1) translate(-8.000000, -8.083333) translate(1.000000, 4.000000)"
      d="M13.7-0.1c-0.1,0-0.3,0.1-0.4,0.2L7,7.2L0.7,0C0.5-0.2,0.2-0.2,0,0c-0.2,0.2-0.2,0.5,0,0.7L6.4,8c0.3,0.3,0.9,0.3,1.2,0l6.5-7.3c0.2-0.2,0.2-0.5,0-0.7C14-0.1,13.9-0.1,13.7-0.1z"
    />
  </Svg>
)

export default CaretUpIcon
