import React, { Fragment, useState } from 'react'
import Analysis from './Analysis'
import withQueryParams from 'components/withQueryParams'
import { isActive } from 'components/AnalysisToggle'
import { styled, borderColor } from '@fv/design-tokens'
import ShowMoreButton from './ShowMoreButton'

const StyledHr = styled.hr`
  border: 0;
  border-top: 1px solid;
  ${borderColor('backgroundBorderColor')};
`

const AnalysisOccurrences = ({
  data,
  query: params,
  component,
  analysisType
}) => {
  const newData = data.map(data => {
    const { text } = data
    const active = isActive(analysisType, text, params)
    return { ...data, active }
  })

  const activeData = newData.filter(({ active }) => active)

  const [showMore, setShowMore] = useState(false)
  const inactiveData = newData.filter(({ active }) => !active)
  const shownInactiveData = showMore ? inactiveData : inactiveData.slice(0, 5)
  const displayShowMore = inactiveData.length > 5

  return (
    <Fragment>
      <Analysis Component={component} data={activeData} />

      {!!activeData.length && <StyledHr />}

      <Analysis Component={component} data={shownInactiveData} />

      {displayShowMore && (
        <ShowMoreButton
          showMore={showMore}
          onClick={() => setShowMore(!showMore)}
        />
      )}
    </Fragment>
  )
}

export default withQueryParams(AnalysisOccurrences)
