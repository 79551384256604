import React from 'react'
import Svg from 'components/Svg'

const NewClipIcon = props => (
  <Svg viewBox="0 0 18 18" {...props}>
    <title>new-clip</title>
    <g>
      <path d="M11.63175,7.13475 L11.63175,1.13475 C11.63175,0.720536438 11.2959636,0.38475 10.88175,0.38475 L1.13175,0.38475 C0.717536438,0.38475 0.38175,0.720536438 0.38175,1.13475 L0.38175,14.63475 C0.38175,15.0489636 0.717536438,15.38475 1.13175,15.38475 L7.13175,15.38475" />
      <polyline points="9.38175 0.38475 9.38175 6.38475 2.63175 6.38475 2.63175 0.38475" />
      <path d="M2.63175,3.38475 L0.38175,3.38475" />
      <path d="M2.63175,6.38475 L0.38175,6.38475" />
      <path d="M11.63175,3.38475 L9.38175,3.38475" />
      <path d="M11.63175,6.38475 L9.38175,6.38475" />
      <polyline points="2.63175 15.38475 2.63175 9.38475 7.88175 9.38475" />
      <path d="M2.63175,9.38475 L0.38175,9.38475" />
      <path d="M2.63175,12.38475 L0.38175,12.38475" />
      <circle cx="13.13175" cy="13.13475" r="4.5" />
      <path d="M13.13175,10.88475 L13.13175,15.38475" />
      <path d="M15.38175,13.13475 L10.88175,13.13475" />
    </g>
  </Svg>
)

export default NewClipIcon
