import { createContext } from 'react'
import noop from 'utils/noop'

const Context = createContext({
  loading: false,
  viewer: null,
  hasFeature: noop
})

export const Consumer = Context.Consumer
export const Provider = Context.Provider

export default Context
