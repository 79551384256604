import React from 'react'
import { styled, css } from '@fv/design-tokens'
import { green2 } from 'styles/color'

const uppercaseCss = ({ uppercase }) =>
  uppercase
    ? css`
        text-transform: uppercase;
      `
    : null

const Container = styled.span`
  display: inline-block;
  border-radius: 2rem;
  padding: 4px 8px;
  color: ${green2};
  background: rgba(57, 168, 76, 0.1);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.08em;

  ${uppercaseCss};
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: justify-content;
  align-items: center;
`

const Tag = ({ children, ...props }) => (
  <Container {...props}>
    <InnerContainer>{children}</InnerContainer>
  </Container>
)

export default Tag
