import React, { useState } from 'react'
import {
  styled,
  color,
  fontSize,
  fontWeight,
  marginLeft,
  padding
} from '@fv/design-tokens'
import AnalysisItem from './AnalysisItem'
import Badge from 'components/Badge'
import Button from 'components/Button'
import ShowIcon from 'components/icons/Show'
import StatusIcon from './StatusIcon'
import Toggle from './Toggle'
import { capitalize } from 'utils/index'
import {
  ANALYSIS_EVENTS,
  HUMAN_TRANSCRIPT_TYPE,
  getAnalysisStatus,
  getOverallStatus
} from 'utils/analysis'
import { formattedAnalysisEvent, itemStatusProps } from './helpers'

const Container = styled.div`
  ${padding('s')};
`

const Header = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
`

const Title = styled.span`
  ${fontSize('m')};
  ${fontWeight('bold')};
`

const OverallStatus = styled(Badge)`
  svg {
    ${marginLeft('xs')};
    margin-right: -2px;
  }
`

const ActiveBadgeContainer = styled.div`
  ${marginLeft('xxs')};
  width: 1.75rem;
`

const ActiveBadge = styled(Badge)`
  ${marginLeft('xxs')};
  ${color('text')};
`

const ItemList = styled.dl``

const TranscriptionDetails = ({
  isActive,
  showOrderHumanTranscriptionModal,
  videoAnalysis,
  canRequestTranscription
}) => {
  const [showDetails, toggleDetails] = useState(false)
  const isUnprocessed = !videoAnalysis['updatedAt']
  const overallStatus = getOverallStatus(videoAnalysis)
  const overallStatusProps = itemStatusProps(videoAnalysis, overallStatus, null)

  return (
    <Container>
      <Header>
        <Toggle showDetails={showDetails} toggleDetails={toggleDetails} />
        <Title>{capitalize(videoAnalysis.transcriptType.toLowerCase())}</Title>
        {isUnprocessed && videoAnalysis.transcriptType === HUMAN_TRANSCRIPT_TYPE
          ? canRequestTranscription && (
              <Button primary onClick={showOrderHumanTranscriptionModal}>
                Order
              </Button>
            )
          : !isUnprocessed && (
              <OverallStatus
                backgroundColorName={overallStatusProps.badgeBackgroundColor}
                textColorName={overallStatusProps.badgeTextColor}
              >
                {capitalize(overallStatus)}
                <StatusIcon
                  aria-label={capitalize(overallStatus)}
                  component={overallStatusProps.icon}
                  role="img"
                  size="16px"
                  title={capitalize(overallStatus)}
                />
              </OverallStatus>
            )}
        <ActiveBadgeContainer>
          {!isUnprocessed && isActive && (
            <ActiveBadge circle backgroundColorName="buttonBackground">
              <ShowIcon
                aria-label="Active"
                role="img"
                title="Active"
                size="16px"
              />
            </ActiveBadge>
          )}
        </ActiveBadgeContainer>
      </Header>
      <ItemList>
        {showDetails &&
          ANALYSIS_EVENTS.map(analysisEvent => {
            const status = getAnalysisStatus(videoAnalysis, analysisEvent)
            const statusProps = itemStatusProps(
              videoAnalysis,
              status,
              analysisEvent
            )

            return (
              <AnalysisItem
                key={analysisEvent}
                item={formattedAnalysisEvent(analysisEvent)}
                badgeBackgroundColor={statusProps.badgeBackgroundColor}
                badgeTextColor={statusProps.badgeTextColor}
                status={status}
                icon={statusProps.icon}
                modifiedStatus={statusProps.modifiedStatus}
                lastModified={statusProps.lastModified}
              />
            )
          })}
      </ItemList>
    </Container>
  )
}

export default TranscriptionDetails
