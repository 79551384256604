import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const VI_RENAME_RESPONSE_MUTATION = gql`
  mutation VIRenameResponse($input: UpdateReelInput!) {
    renameResponse(input: $input)
      @jsonapi(path: "/responses/{args.input.data.id}", method: "PATCH") {
      title
    }
  }
`

const useRenameResponseMutation = () => {
  const [mutate, result] = useMutation(VI_RENAME_RESPONSE_MUTATION)

  const renameResponseMutation = ({ id, title, ...attributes }) =>
    mutate({
      variables: {
        input: {
          data: {
            type: 'responses',
            id,
            attributes: {
              title
            }
          }
        }
      },
      optimisticResponse: {
        renameResponse: {
          __typename: 'VIResponses',
          type: 'responses',
          id,
          title,
          ...attributes
        }
      }
    })

  return [renameResponseMutation, result]
}

export default useRenameResponseMutation
