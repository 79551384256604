import gql from 'graphql-tag'

const VI_VIDEO_SOURCES_QUERY = gql`
  query VIVideoResponsesQuery($projectId: Integer!) {
    responses(projectId: $projectId)
      @jsonapi(
        path: "/responses?filter[project]={args.projectId}&filter[response_type]=video&include=original_video&page[size]=50"
        includeJsonapi: true
      ) {
      graphql {
        ... on VIVideoResponses {
          id
          title
          thumbnailUrl
          originalVideo {
            id
            length
          }
        }
      }
      jsonapi {
        links {
          next
        }
      }
    }
  }
`

export const VI_DYNAMIC_VIDEO_SOURCES_QUERY = gql`
  query VIVideoResponsesQuery($path: String!) {
    responses(path: $path) @jsonapi(path: "{args.path}", includeJsonapi: true) {
      graphql {
        ... on VIVideoResponses {
          id
          title
          thumbnailUrl
          originalVideo {
            id
            length
          }
        }
      }
      jsonapi {
        links {
          next
        }
      }
    }
  }
`

export default VI_VIDEO_SOURCES_QUERY
