import React from 'react'
import Svg from 'components/Svg'

const ShowIcon = props => (
  <Svg viewBox="0 0 16 16" {...props}>
    <title>{props.title || 'Show'}</title>
    <path
      d="M8,5.5c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S9.4,5.5,8,5.5z M8,9.6c-0.9,0-1.5-0.7-1.5-1.5
		c0-0.9,0.7-1.5,1.5-1.5c0.9,0,1.5,0.7,1.5,1.5C9.5,8.9,8.9,9.6,8,9.6z"
    />
    <path
      d="M15,7.6C15,7.6,15,7.6,15,7.6c-0.8-0.9-3.6-3.8-7-3.8c-3.4,0-6.2,2.9-7,3.8c-0.3,0.3-0.3,0.7,0,1c0.8,0.9,3.6,3.8,7,3.8
		c3.4,0,6.2-2.9,7-3.8C15.3,8.3,15.3,7.9,15,7.6z M8,11.4c-2.9,0-5.3-2.4-6.1-3.3C2.7,7.2,5.2,4.8,8,4.8c2.9,0,5.4,2.5,6.1,3.3
		C13.4,8.9,10.9,11.4,8,11.4z"
    />
  </Svg>
)

export default ShowIcon
