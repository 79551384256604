import React from 'react'
import Svg from 'components/Svg'

const HumanTranscript = props => (
  <Svg {...props}>
    <title>human-transcript</title>
    <g>
      <path
        d="M67.6,27.5h-18c-2.2,0-4,1.8-4,4v18c0,2.2,1.8,4,4,4h18c2.2,0,4-1.8,4-4v-18C71.6,29.3,69.8,27.5,67.6,27.5z M69.6,49.5
                    c0,1.1-0.9,2-2,2h-18c-1.1,0-2-0.9-2-2v-18c0-1.1,0.9-2,2-2h18c1.1,0,2,0.9,2,2V49.5z"
      />
      <path d="M64.6,33.5h-12c-0.6,0-1,0.4-1,1s0.4,1,1,1h12c0.6,0,1-0.4,1-1S65.1,33.5,64.6,33.5z" />
      <path d="M64.6,39.5h-12c-0.6,0-1,0.4-1,1s0.4,1,1,1h12c0.6,0,1-0.4,1-1S65.1,39.5,64.6,39.5z" />
      <path d="M57.1,45.5h-4.5c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.5c0.6,0,1-0.4,1-1S57.6,45.5,57.1,45.5z" />
      <path
        d="M29.1,13.5c0,3.9,3.1,7,7,7s7-3.1,7-7s-3.1-7-7-7S29.1,9.6,29.1,13.5z M41.1,13.5c0,2.8-2.2,5-5,5s-5-2.2-5-5s2.2-5,5-5
                                            S41.1,10.7,41.1,13.5z"
      />
      <path
        d="M22.5,27.5h-18c-2.2,0-4,1.8-4,4v18c0,2.2,1.8,4,4,4h18c2.2,0,4-1.8,4-4v-18C26.5,29.3,24.7,27.5,22.5,27.5z M24.5,49.5
                                                        c0,1.1-0.9,2-2,2h-18c-1.1,0-2-0.9-2-2v-18c0-1.1,0.9-2,2-2h18c1.1,0,2,0.9,2,2V49.5z"
      />
      <path
        d="M18.4,38.9l-6.1-3.6c-0.3-0.2-0.6-0.3-1-0.3c-1,0-1.9,0.8-1.9,1.9l0,7.3c0,0.3,0.1,0.7,0.3,1c0.3,0.4,0.7,0.7,1.1,0.8
                                                                    c0.1,0,0.3,0.1,0.4,0.1c0.3,0,0.7-0.1,1-0.3l6.1-3.6c0.3-0.2,0.5-0.4,0.6-0.6c0.3-0.4,0.3-0.9,0.2-1.4
                                                                            C19.1,39.6,18.8,39.2,18.4,38.9z M11.5,43.9l0-3.4c0,0,0,0,0,0v-3.4l5.7,3.4L11.5,43.9z"
      />
      <path
        d="M41.2,46.4c-0.5,0-1,0.3-1.1,0.9l-0.4,4.2h-7.2l-0.4-4.2c-0.1-0.5-0.5-0.9-1.1-0.9c-0.5,0.1-1,0.5-0.9,1.1l0.5,5.1
                                                                                        c0.1,0.5,0.5,0.9,1,0.9h9c0.5,0,0.9-0.4,1-0.9l0.5-5.1C42.2,46.9,41.7,46.4,41.2,46.4z"
      />
      <path
        d="M28.4,24.4c-0.4,0.4-0.4,1-0.1,1.4c0.4,0.4,1,0.4,1.4,0.1c3.6-3.2,9-3.2,12.6,0c0.2,0.2,0.4,0.3,0.7,0.3
                                                                                                    c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.3-1-0.1-1.4C39.4,20.5,32.8,20.5,28.4,24.4z"
      />
      <path
        d="M22.6,20.5c1.4,0,2.5-1.1,2.5-2.5v-4.5c0-6.1,4.9-11,11-11s11,4.9,11,11v3V18c0,1.4,1.1,2.5,2.5,2.5c3,0,5.5-2.5,5.5-5.5
                                                                                                                s-2.5-5.5-5.5-5.5h-1.1c-1.7-5.2-6.6-9-12.4-9s-10.7,3.8-12.4,9h-1.1c-3,0-5.5,2.5-5.5,5.5S19.6,20.5,22.6,20.5z M49.1,11.5h0.5
                                                                                                                        c1.9,0,3.5,1.6,3.5,3.5s-1.6,3.5-3.5,3.5c-0.3,0-0.5-0.2-0.5-0.5v-1.5v-3V11.5z M22.6,11.5h0.5v2V18c0,0.3-0.2,0.5-0.5,0.5
                                                                                                                                c-1.9,0-3.5-1.6-3.5-3.5S20.7,11.5,22.6,11.5z"
      />
    </g>
  </Svg>
)

export default HumanTranscript
