import React from 'react'
import Svg from 'components/Svg'

const EndPoint = props => (
  <Svg viewBox="0 0 14 14" {...props}>
    <title>End Point</title>
    <polygon points="10 6 10 2 4 2 4 6 7 9 10 6" />
    <path d="M7,10H2v3H12V10Zm4,2H7V11h4Z" />
  </Svg>
)

export default EndPoint
