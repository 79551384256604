import React from 'react'
import {
  backgroundColor,
  borderRadius,
  color,
  css,
  fontSize,
  fontWeight,
  marginBottom,
  padding,
  spacingFor,
  styled
} from '@fv/design-tokens'
import { formatTime } from 'utils/time'
import { slate } from 'styles/color'
import { Link } from 'react-router-dom'
import { paramsForLocation } from 'utils/params'
import { withRouter } from 'react-router'

const ifActive = ({ active, theme }) =>
  active &&
  css`
    border: 1px solid ${slate};
  `

const ifDisabled = ({ disabled, theme }) =>
  disabled &&
  css`
    ${backgroundColor('backgroundShadedBorderColor', { theme })};
    ${color('buttonTextDisabled', { theme })};
  `

const Container = styled.div`
  display: grid;
  grid-column-gap: ${spacingFor('s')};
  grid-template-columns: auto 1fr;

  ${backgroundColor('background')};
  ${borderRadius('m')};
  ${marginBottom('s')};
  ${padding('s')};

  ${ifActive}
  ${ifDisabled}
`

const ThumbnailContainer = styled.div`
  width: 80px;
`

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${borderRadius('m')};
`

const Metadata = styled.div`
  overflow: hidden;
`

const ResponseTitle = styled.h4`
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${fontSize('m')};
  ${fontWeight('regular')};
`

const Duration = styled.p`
  font-style: italic;

  ${fontSize('s')};
`

const searchWithShowreelBuilder = location => {
  const params = paramsForLocation(location)
  params.set('panel', 'showreelBuilder')
  return params.toString()
}

const VideoSource = ({
  response: {
    id,
    title,
    thumbnailUrl,
    originalVideo: { length }
  },
  match: {
    params: { companyId, videoId: activeResponseId }
  },
  location
}) => {
  const isReady = !!length

  if (isReady) {
    return (
      <Link
        to={{
          pathname: `/a/companies/${companyId}/videos/${id}/highlights`,
          search: searchWithShowreelBuilder(location)
        }}
        replace
      >
        <Container active={id === activeResponseId}>
          <ThumbnailContainer>
            <Thumbnail src={thumbnailUrl} alt={title} />
          </ThumbnailContainer>
          <Metadata>
            <ResponseTitle>{title}</ResponseTitle>
            <Duration>{formatTime(length)}</Duration>
          </Metadata>
        </Container>
      </Link>
    )
  }

  return (
    <Container disabled={true} active={id === activeResponseId}>
      <ThumbnailContainer>
        <Thumbnail src={thumbnailUrl} alt={title} />
      </ThumbnailContainer>
      <Metadata>
        <ResponseTitle>{title}</ResponseTitle>
        <Duration>Processing&hellip;</Duration>
      </Metadata>
    </Container>
  )
}

export default withRouter(VideoSource)
