import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from '@apollo/react-components'
import VI_MARKS_QUERY from 'components/AdvancedEditor/Marks/marks.query.js'
import { Mark_mark } from 'components/AdvancedEditor/Marks/Mark.fragments.js'

export const VI_UPDATE_MARK = gql`
  mutation VIUpdateMarkMutation($input: UpdateMarkInput!) {
    updateMark(input: $input)
      @jsonapi(path: "/marks/{args.input.data.id}", method: "PATCH") {
      id
      ...Mark_mark
    }
  }

  ${Mark_mark}
`

const updateCache = ({ cache, updateMark, responseId }) => {
  const { marks } = cache.readQuery({
    query: VI_MARKS_QUERY,
    variables: { responseId }
  })

  cache.writeQuery({
    query: VI_MARKS_QUERY,
    variables: { responseId },
    data: {
      marks
    }
  })
}

const UpdateMark = ({ responseId, children }) => (
  <Mutation mutation={VI_UPDATE_MARK}>
    {(mutation, props) =>
      children({
        ...props,
        updateMark: ({ id, text, secondsAfterStart }) =>
          mutation({
            variables: {
              input: {
                data: {
                  id,
                  type: 'marks',
                  attributes: {
                    text,
                    secondsAfterStart
                  },
                  relationships: {
                    response: {
                      data: { type: 'responses', id: responseId }
                    }
                  }
                }
              }
            },
            optimisticResponse: {
              updateMark: {
                __typename: 'VIMarks',
                type: 'marks',
                id,
                text,
                secondsAfterStart
              }
            },
            update: (cache, { data: { updateMark } }) => {
              updateCache({ cache, updateMark, responseId })
            }
          })
      })
    }
  </Mutation>
)

export default UpdateMark
