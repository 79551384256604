const dig = (obj, ...attrs) => {
  if (!obj) {
    return null
  }
  const [attr, ...rest] = attrs
  if (!rest.length) {
    return obj[attr]
  }
  return dig(obj[attr], ...rest)
}

export default dig
