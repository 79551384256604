import React from 'react'
import { styled, withTheme, fontWeight } from '@fv/design-tokens'
import PropTypes from 'prop-types'
import CheckboxHidden from './CheckboxHidden'
import CheckboxDisplay from './CheckboxDisplay'
import { slate, white } from 'styles/color'

const Label = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  margin-bottom: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const LabelText = styled.span`
  margin-bottom: 0;
  color: ${slate};
  vertical-align: middle;
  text-decoration: normal;
  font-size: 14px;
  ${fontWeight('regular')};
`

const CheckboxContainer = styled.div`
  position: relative;
`

const Checkbox = ({
  checked,
  label,
  disabled,
  valid,
  id,
  theme,
  className,
  ...rest
}) => (
  <CheckboxContainer className={className}>
    <CheckboxHidden checked={checked} disabled={disabled} id={id} {...rest} />
    <Label disabled={disabled} htmlFor={id}>
      <CheckboxDisplay checked={checked} disabled={disabled} valid={valid}>
        <svg viewBox="-4 -6 20 20">
          <g fill="none">
            {checked && (
              <path stroke={white} strokeWidth="2" d="M1.5 3.75l3 3 6-6" />
            )}
          </g>
        </svg>
      </CheckboxDisplay>
      <LabelText>{label}</LabelText>
    </Label>
  </CheckboxContainer>
)

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  valid: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

Checkbox.defaultProps = {
  onChange: () => {},
  checked: false,
  disabled: false,
  valid: true
}

export default withTheme(Checkbox)
