export const VAW_WALKTHROUGH = Object.freeze({
  1: {
    title: 'Welcome to the new Video Analysis Workspace',
    text:
      'Analyze your video content and easily create showreels with just a few clicks.',
    imgSrc: '/r/images/onboarding/onboarding-3-1.jpg'
  },
  2: {
    title: 'Automatic insights with FV Highlights',
    text:
      'Our unique algorithm will surface the most potent highlights for you to review.',
    imgSrc: '/r/images/onboarding/onboarding-3-2.jpg'
  },
  3: {
    title: 'Cut to the core with our advanced NLP algorithm',
    text:
      'The sentiment of each highlight is indicated with a face icon to the left. Our confidence level of how insightful the highlight is can be seen to the right. Use the filter to the left side to further sift through the highlights.',
    imgSrc: '/r/images/onboarding/onboarding-3-3.jpg'
  },
  4: {
    title: 'Generate a showreel, supporting your insights',
    text:
      'Once you have identified your key moments, click on the clip icon to add them to your showreel on the right. Edit timestamps to fine tune the video length of each clip.',
    imgSrc: '/r/images/onboarding/onboarding-3-4.jpg'
  }
})

export const VAW_KEY = Object.freeze('fv_vi_onboarding_vaw')
