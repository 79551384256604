import {
  background,
  borderBottomColor,
  borderRadius,
  color,
  css,
  fontWeight,
  gridColumnGap,
  gridRowGap,
  lineHeight,
  marginTop,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  spacingFor,
  styled
} from '@fv/design-tokens'
import SentimentIcon from 'components/icons/Sentiment'

export const Container = styled.div`
  position: relative;
  ${borderRadius('m')};
  ${padding('s')};
  ${paddingTop('xs')};

  display: grid;
  grid-template:
    'divider'
    'content';
  grid-template-columns: auto 1fr auto;
  ${gridColumnGap('m')};
  ${gridRowGap('xs')};
  justify-content: left;

  transition: background 0.15s;

  ${({ isCurrentClip, theme }) =>
    isCurrentClip && background('backgroundShaded', { theme })};

  ${({ isCurrentText, theme }) =>
    isCurrentText && background('backgroundShaded', { theme })};
`

export const Content = styled.div`
  grid-area: content;
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${spacingFor('s')};
  align-items: center;
`

export const Timestamps = styled.div`
  display: inline-block;
  ${color('textLt')};
`

export const Score = styled.div`
  ${background('backgroundShaded')};
  ${fontWeight('bold')};
  ${paddingTop('xxs')};
  ${paddingRight('s')};
  ${paddingBottom('xxs')};
  ${paddingLeft('s')};
  border-radius: 50px;
  margin-left: auto;
`

export const StyledSentimentIcon = styled(SentimentIcon)`
  width: 21px;
  height: 21px;
`

export const SentimentIconContainer = styled.div`
  position: relative;
`

const textHoverCss = ({ noHover, theme }) =>
  !noHover &&
  css`
    &:hover {
      ${background('backgroundShaded', { theme })};
    }
  `

export const Text = styled.div`
  position: relative;
  ${marginTop('s')};
  ${lineHeight('l')};
  ${padding('xs')};

  mark {
    background: transparent;
    ${color('text')};
    border-bottom: 3px solid;
    ${borderBottomColor('primary')};
  }

  ${textHoverCss};
`
