import React, { useState } from 'react'
import {
  styled,
  color,
  colorFor,
  paddingLeft,
  paddingRight,
  marginLeft,
  fontSize,
  fontWeight
} from '@fv/design-tokens'
import Button, { ButtonLink } from 'components/Button'
import BackIcon from 'components/icons/Back'
import Tooltip from 'components/Tooltip'
import QueryParamLink from 'components/QueryParams/QueryParamLink'
import TranscriptsIcon from 'components/icons/Transcripts'
import ReelBuilderIcon from 'components/icons/ReelBuilder'
import VideoSourcesIcon from 'components/icons/VideoSources'
import EditableTitle from 'components/EditableTitle'
import useRenameResponseMutation from 'hooks/mutations/useRenameResponse'

const Container = styled.header`
  align-items: center;
  display: flex;
  height: 70px;

  ${paddingLeft('m')};
  ${paddingRight('m')};
`

const BackButton = styled(Button)`
  ${color('text')};
`

const TitleWrapper = styled.div`
  margin: 0;
  ${marginLeft('s')};
  margin-right: auto;

  h1,
  input {
    ${fontSize('xl')};
    ${fontWeight('regular')};
    line-height: 1.25;
    max-width: 600px;
  }
`

const NavButtonLink = styled(ButtonLink)`
  ${marginLeft('xs')};

  svg {
    color: transparent;
    stroke: ${colorFor('textLt')};
    fill: none;
  }

  &:active {
    svg {
      stroke: ${colorFor('textReverseLt')};
    }
  }
`

const Header = ({ canEdit, responseId, title, video, data, close }) => {
  const [renameResponse] = useRenameResponseMutation()
  const [responseTitle, setResponseTitle] = useState(title)

  const handleSubmitResponseTitle = title => {
    renameResponse({ id: responseId, title })
    setResponseTitle(title)
  }

  return (
    <Container>
      <Tooltip placement="bottom" render={() => 'Back'}>
        {({ show, hide, ref }) => (
          <BackButton
            aria-label="Back"
            ghost
            onClick={close}
            onMouseOut={hide}
            onMouseOver={show}
            ref={ref}
            square
            type="button"
          >
            <BackIcon />
          </BackButton>
        )}
      </Tooltip>
      <TitleWrapper>
        {canEdit ? (
          <EditableTitle
            title={responseTitle}
            inputLabel="Response title"
            editButtonLabel="Rename response title"
            saveButtonLabel="Save response title"
            handleSubmit={handleSubmitResponseTitle}
            adjustableWidth
          />
        ) : (
          <h1>{responseTitle}</h1>
        )}
      </TitleWrapper>
      <Tooltip placement="bottom" render={() => 'Transcription'}>
        {({ show, hide, ref }) => (
          <QueryParamLink
            activeWhen={{ panel: 'transcription' }}
            aria-label="Transcription"
            component={NavButtonLink}
            replace
            square
            toParams={{ panel: 'transcription' }}
            ref={ref}
            onMouseOver={show}
            onMouseOut={hide}
          >
            <TranscriptsIcon />
          </QueryParamLink>
        )}
      </Tooltip>
      <Tooltip
        placement={canEdit ? 'bottom' : 'bottom-end'}
        render={() => 'Video Sources'}
      >
        {({ show, hide, ref }) => (
          <QueryParamLink
            activeWhen={{ panel: 'videoSources' }}
            aria-label="Video Sources"
            component={NavButtonLink}
            replace
            square
            toParams={{ panel: 'videoSources' }}
            ref={ref}
            onMouseOver={show}
            onMouseOut={hide}
          >
            <VideoSourcesIcon />
          </QueryParamLink>
        )}
      </Tooltip>
      {canEdit && (
        <Tooltip placement="bottom-end" render={() => 'Showreel Builder'}>
          {({ show, hide, ref }) => (
            <QueryParamLink
              activeWhen={{ panel: 'showreelBuilder' }}
              aria-label="Showreel Builder"
              component={NavButtonLink}
              replace
              square
              toParams={{ panel: 'showreelBuilder' }}
              ref={ref}
              onMouseOver={show}
              onMouseOut={hide}
            >
              <ReelBuilderIcon />
            </QueryParamLink>
          )}
        </Tooltip>
      )}
    </Container>
  )
}

export default Header
