import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  ModalV2 as Modal,
  Dialog,
  Checkbox,
  color,
  fontSize,
  spacer
} from 'stylist'
import { useLazyQuery } from '@apollo/react-hooks'
import VI_REEL_QUERY from './reel.query'
import Loader from 'components/Loader'

const ReelCard = styled.div`
  display: flex;
  background: ${color('offWhite')};
  padding: ${spacer(3)};
  margin-bottom: ${spacer(2)};
`

const ReelCardThumbnail = styled.img`
  width: 106px;
  height: 60px;
  margin-right: ${spacer(3)};
`

const ReelCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ReelCardPublished = styled.span`
  ${fontSize('xxs')}
  color: ${color('pewter')}
`

const Confirmation = styled.div`
  margin-top: ${spacer(5)};
`

const DeleteModal = ({ reelId = null, hide, handleDelete }) => {
  const [confirmationCheckbox, setConfirmationCheckbox] = useState(false)

  const [
    loadReel,
    {
      called: calledReel,
      loading: loadingReel,
      data: {
        reel: { name, thumbnailUrl, publishedToPresentationFl } = {}
      } = {}
    }
  ] = useLazyQuery(VI_REEL_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (reelId) {
      loadReel({
        variables: { reelId }
      })
    }
  }, [reelId])

  return (
    <Dialog>
      <Dialog.Header>
        <Dialog.Title>Permanently delete this reel?</Dialog.Title>
      </Dialog.Header>
      <Dialog.Body>
        {loadingReel ? (
          <Loader aria-label="delete reel modal loader" />
        ) : (
          <>
            <ReelCard>
              <ReelCardThumbnail src={thumbnailUrl} />
              <ReelCardInfo>
                {name}
                <ReelCardPublished>
                  {publishedToPresentationFl ? 'Published' : 'Unpublished'}
                </ReelCardPublished>
              </ReelCardInfo>
            </ReelCard>
            <p>
              {publishedToPresentationFl
                ? 'Once you delete a reel, you will not be able to recover it. If you think you’ll want to come back to this reel in the future, you can unpublish it instead of deleting it.'
                : 'Once you delete a reel, you will not be able to recover it.'}
            </p>
            <Confirmation>
              <Checkbox
                id="delete-reel-confirmation-checkbox"
                checked={confirmationCheckbox}
                onChange={e => setConfirmationCheckbox(e.target.checked)}
                label="I understand that deleting a reel is permanent, and can’t be undone"
              />
            </Confirmation>
          </>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Button kind="ghost" onClick={hide}>
          Cancel
        </Button>
        <Button
          disabled={!confirmationCheckbox}
          kind="danger"
          onClick={() => {
            handleDelete()
            hide()
          }}
        >
          Yes,{' '}
          {publishedToPresentationFl ? 'unpublish and delete it' : 'delete it'}
        </Button>
      </Dialog.Footer>
    </Dialog>
  )
}

const DeleteReelModal = ({ reelId = null, handleDelete, children }) => {
  return (
    <Modal
      lazyRender
      blurBackground
      render={({ hide }) => (
        <DeleteModal reelId={reelId} hide={hide} handleDelete={handleDelete} />
      )}
    >
      {children}
    </Modal>
  )
}

export default DeleteReelModal
