import React from 'react'
import { Route, Switch } from 'react-router-dom'
import RouteRails from 'routes/rails'
import NotFound from 'routes/notFound'
import RouteVideo from 'routes/video'
import RouteVersion from 'routes/version'

const Routes = () => (
  <Switch>
    <Route path="/r" component={RouteRails} />
    <Route
      path="/a/companies/:companyId/videos/:videoId"
      component={RouteVideo}
    />
    <Route path="/a/version" component={RouteVersion} />
    <Route path="*" exact={true} component={NotFound} />
  </Switch>
)

export default Routes
