import React, { useContext, useState, useMemo, useRef } from 'react'
import { styled, paddingTop } from '@fv/design-tokens'
import { getDoubleSpaceCount } from 'utils'
import TranscriptFragment from './TranscriptFragment'
import TextSelector from 'components/TextSelector'
import ClipControlBar from 'components/AdvancedEditor/ClipControlBar'
import TranscriptContext from 'components/AdvancedEditor/Transcript/Context'
import {
  calcTop,
  calcLeft,
  getPlacement
} from 'components/AdvancedEditor/ClipControlBar/utils'
import { getSelectionRangeClientRect } from 'components/TextSelector/utils'

const Container = styled.div`
  position: relative;
  ${paddingTop('xs')};
`

const getTimestampsAndSkipFromSelection = () => {
  const {
    anchorNode,
    focusNode,
    anchorOffset,
    focusOffset
  } = window.getSelection()
  const { parentElement: anchorElement } = anchorNode
  const { parentElement: focusElement } = focusNode
  const startOffset = Math.min(anchorOffset, focusOffset)
  let {
    startTime: anchorStartTime,
    endTime: anchorEndTime
  } = anchorElement.dataset
  anchorStartTime = parseFloat(anchorStartTime)
  anchorEndTime = parseFloat(anchorEndTime)
  let {
    startTime: focusStartTime,
    endTime: focusEndTime
  } = focusElement.dataset
  focusStartTime = parseFloat(focusStartTime)
  focusEndTime = parseFloat(focusEndTime)
  let startNode = anchorNode
  let startTime = anchorStartTime
  let endTime = focusEndTime

  if (startTime > focusStartTime) {
    startNode = focusNode
    startTime = focusStartTime
  }

  if (anchorEndTime > focusEndTime) {
    endTime = anchorEndTime
  }
  const contentDoubleSpaceCount = getDoubleSpaceCount(
    startNode.textContent.slice(0, startOffset)
  )
  const skip =
    startNode.textContent
      .slice(0, startOffset - contentDoubleSpaceCount)
      .split(' ').length - 1

  return {
    startTime,
    endTime,
    skip
  }
}

const TranscriptFragments = ({
  canEdit,
  autoScroll,
  scrollContainerClientRect,
  sentences
}) => {
  const [selectedText, setSelectedText] = useState(null)
  const containerRef = useRef(null)
  const { findTimestamps } = useContext(TranscriptContext)
  const containerClientRect =
    containerRef.current && containerRef.current.getBoundingClientRect()
  const selectionRangeClientRect = selectedText && getSelectionRangeClientRect()
  const [startTime, endTime] = useMemo(() => {
    const startTime = sentences[0].startTime
    const endTime = sentences[sentences.length - 1].endTime

    if (selectedText) {
      const { startTime, endTime, skip } = getTimestampsAndSkipFromSelection()

      return findTimestamps({ text: selectedText, startTime, endTime, skip })
    }

    return [startTime, endTime]
  }, [selectedText])

  return (
    <Container ref={containerRef}>
      <TextSelector
        onSelection={selectedText => {
          setSelectedText(selectedText)
        }}
        onDeselection={() => {
          setSelectedText(null)
        }}
      >
        {sentences.map((sentence, index) => (
          <TranscriptFragment
            key={index}
            sentence={sentence}
            scrollContainerClientRect={scrollContainerClientRect}
            selectedText={selectedText}
            autoScroll={autoScroll}
            canEdit={canEdit}
          />
        ))}
      </TextSelector>
      {selectedText && (
        <ClipControlBar
          center
          canCreateClip={canEdit}
          top={calcTop({
            scrollContainerClientRect,
            containerClientRect,
            selectionRangeClientRect
          })}
          left={calcLeft({
            containerClientRect,
            selectionRangeClientRect
          })}
          placement={getPlacement({
            scrollContainerClientRect,
            elementOrRangeClientRect: selectionRangeClientRect
          })}
          startTime={startTime}
          endTime={endTime}
        />
      )}
    </Container>
  )
}

export default TranscriptFragments
