import React, { cloneElement, Children } from 'react'
import Caret from 'components/icons/Caret'
import TruncateText from 'components/TruncateText'
import Button from './Button'
import Container from './Container'
import Downshift from 'downshift'
import Menu from './Menu'
import Option from './Option'
import Placeholder from './Placeholder'
import PropTypes from 'prop-types'

const Dropdown = ({
  children,
  icon: Icon,
  itemToString,
  disabled,
  kind,
  name,
  onChange,
  placeholder,
  size,
  valid,
  value,
  width
}) => (
  <Downshift
    onChange={onChange}
    selectedItem={value}
    itemToString={itemToString}
  >
    {({
      getToggleButtonProps,
      getItemProps,
      getMenuProps,
      getRootProps,
      highlightedIndex,
      isOpen,
      selectedItem
    }) => (
      <Container width={width} {...getRootProps({ refKey: 'innerRef' })}>
        <Button
          size={size}
          kind={kind}
          valid={valid}
          {...getToggleButtonProps({ disabled })}
        >
          {Icon && <Icon size="m" />}
          {selectedItem ? (
            <TruncateText>
              {
                Children.toArray(children).find(
                  ({ props }) => props.value === selectedItem
                ).props.children
              }
            </TruncateText>
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )}
          <Caret size="8px" color="cadet" />
        </Button>
        {isOpen && (
          <Menu data-testid="dropdown-menu" {...getMenuProps()}>
            {Children.map(children, (child, idx) =>
              cloneElement(child, {
                ...getItemProps({
                  key: child.props.value,
                  item: child.props.value
                }),
                size,
                highlighted: highlightedIndex === idx,
                selected: selectedItem === child.props.value
              })
            )}
          </Menu>
        )}
      </Container>
    )}
  </Downshift>
)

Dropdown.propTypes = {
  children: PropTypes.node,
  kind: PropTypes.oneOf(['ghost']),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['lg']),
  valid: PropTypes.bool,
  value: PropTypes.any
}

Dropdown.defaultProps = {
  itemToString: value => (value == null ? '' : value),
  placeholder: 'Select...',
  valid: true
}

Dropdown.Option = Option

export default Dropdown
