import gql from 'graphql-tag'
import { HighlightClip_clip } from './HighlightClip.fragments'

export const HighlightClips_videoAnalysis = gql`
  fragment HighlightClips_videoAnalysis on VideoAnalysis {
    clips(
      first: 50
      sentiment: $sentiment
      entities: $entities
      keyPhrases: $keyPhrases
    ) {
      edges {
        node {
          id
          startTime
          endTime

          ...HighlightClip_clip
        }
      }
    }
  }

  ${HighlightClip_clip}
`
