import { styled, marginLeft, padding } from '@fv/design-tokens'
import Button from 'components/Button'

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  ${padding('m')};

  ${Button} + ${Button} {
    ${marginLeft('xxs')};
  }
`

Footer.displayName = 'Dialog.Footer'

export default Footer
