import React from 'react'
import FeatherSvg from 'components/FeatherSvg'

const AlertTriangle = props => (
  <FeatherSvg {...props}>
    <title>{props.title || 'alert-triangle'}</title>
    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
    <line x1="12" y1="9" x2="12" y2="13" />
    <line x1="12" y1="17" x2="12" y2="17" />
  </FeatherSvg>
)

export default AlertTriangle
