import { styled, borderColor } from '@fv/design-tokens'

const RightSidebar = styled.div`
  width: 350px;
  grid-area: videoRightSidebar;
  display: grid;
  border-top: 1px solid;
  border-left: 1px solid;
  overflow: hidden;
  ${borderColor('backgroundBorderColor')};
`

export default RightSidebar
