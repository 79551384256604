import React from 'react'
import { Field as FormikField } from 'formik'

const Field = ({ name, render }) => (
  <FormikField
    name={name}
    render={({ field, form: { errors, setFieldValue, values } }) =>
      render({
        ...field,
        form: { values },
        setValue: value => setFieldValue(name, value),
        error: errors[field.name],
        valid: !errors[field.name]
      })
    }
  />
)

export default Field
