import gql from 'graphql-tag'
import { AdvancedEditor_response } from 'components/AdvancedEditor/AdvancedEditor.fragments'
import { OrderHumanTranscriptionModal_response } from 'components/AdvancedEditor/OrderHumanTranscriptionModal/fragments'

const fields = 'fields[projects]=id,permissions'
const includes =
  'include=videos,videos.video_transcript,videos.video_clips,project'

const VI_VIDEO_QUERY = gql`
  query VIResponseQuery($responseId: String!) {
    response(responseId: $responseId)
      @jsonapi(
        path: "/responses/{args.responseId}?${includes}&${fields}"
      ) {
      ...AdvancedEditor_response
      ...OrderHumanTranscriptionModal_response
    }
  }

  ${AdvancedEditor_response}
  ${OrderHumanTranscriptionModal_response}
`

export default VI_VIDEO_QUERY
