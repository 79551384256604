import gql from 'graphql-tag'
import { DeleteReelModal_reel } from './DeleteReelModal.fragments'

export const ReelBuilderHeader_reel = gql`
  fragment ReelBuilderHeader_reel on VIReels {
    id
    name
    publishStatus
    publishDirtyFl
    publishedToPresentationFl

    ...DeleteReelModal_reel
  }

  ${DeleteReelModal_reel}
`
