import { styled, padding } from '@fv/design-tokens'

const Body = styled.div`
  ${padding('m')};
  flex: 1;
`

Body.displayName = 'Dialog.Body'

export default Body
