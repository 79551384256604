import gql from 'graphql-tag'
import { AnalysisLeftSidebar_videoAnalysis } from './AnalysisLeftSidebar/index.fragments'
import { HighlightClips_videoAnalysis } from './Highlights/HighlightClips.fragments'
import {
  TranscriptSettings_machineAnalysis,
  TranscriptSettings_humanAnalysis
} from './TranscriptSettings/TranscriptSettings.fragments'
import { PollAnalysis_videoAnalysis } from './PollData/PollAnalysis.fragments'
import { Transcript_videoAnalysis } from './Transcript/Transcript.fragments'

const ADVANCED_EDITOR_QUERY = gql`
  query VideoQuery(
    $applicationId: String!
    $sourceType: String!
    $videoId: String!
    $limit: Int
    $sentiment: [Sentiment]
    $entities: [String]
    $keyPhrases: [String]
  ) {
    video(
      applicationId: $applicationId
      sourceType: $sourceType
      sourceId: $videoId
    ) {
      __typename
      id

      humanAnalysis {
        highlightAnalysisCompletedAt

        ...TranscriptSettings_humanAnalysis
        ...PollAnalysis_videoAnalysis
        ...AnalysisLeftSidebar_videoAnalysis
        ...HighlightClips_videoAnalysis
        ...Transcript_videoAnalysis
      }
      machineAnalysis {
        ...TranscriptSettings_machineAnalysis
        ...PollAnalysis_videoAnalysis
        ...AnalysisLeftSidebar_videoAnalysis
        ...HighlightClips_videoAnalysis
        ...Transcript_videoAnalysis
      }
    }
  }

  ${HighlightClips_videoAnalysis}
  ${TranscriptSettings_machineAnalysis}
  ${TranscriptSettings_humanAnalysis}
  ${PollAnalysis_videoAnalysis}
  ${AnalysisLeftSidebar_videoAnalysis}
  ${Transcript_videoAnalysis}
`

export default ADVANCED_EDITOR_QUERY
