import React from 'react'
import { styled, fontSize, fontWeight, padding } from '@fv/design-tokens'

const Title = styled.h1`
  ${fontSize('m')};
  ${fontWeight('bold')};
  line-height: 1.25rem;
  margin: 0;
  ${padding('m')};
`

const Header = ({ children }) => <Title>{children}</Title>

export default Header
