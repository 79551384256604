import { styled } from '@fv/design-tokens'
import { compose, defaultTo, prop } from 'utils/fp'
import { withProps } from 'recompose'

const FILTERED_PROPS = ['size', 'innerRef']

const size = ({ size }) => size || '1.5rem'

const FeatherSVG = styled('svg', {
  shouldForwardProp: prop => FILTERED_PROPS.indexOf(prop) === -1
})`
  color: currentColor;
  width: ${size};
  height: ${size};
  cursor: ${prop('cursor')};
  fill: none;
  stroke: currentColor;
  stroke-width: ${compose(
    defaultTo(2),
    prop('strokeWidth')
  )};
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: ${prop('pointerEvents')};
  vertical-align: middle;
`

export default withProps({
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 24 24'
})(FeatherSVG)
