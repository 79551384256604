import React from 'react'
import { styled } from '@fv/design-tokens'

const StyledPanel = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 420px;
  padding: 0 2.25rem;
  text-align: center;
  background: #ffffff;
  border-radius: 6px;
`

const PanelTitle = styled('h1')`
  margin: 0 0 1.25rem 0;
  font-size: 24px;
  color: #3e5467;
  position: relative;
  top: -1.25rem;
`

const PanelDescription = styled('p')`
  flex: 0 0 auto;
  margin: 1.25rem;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
  text-align: left;
`

const PanelImage = styled('img')`
  flex: 0 0 auto;
  width: 100%;
`

const Panel = ({ title, text, imgSrc }) => (
  <StyledPanel>
    <PanelTitle>{title}</PanelTitle>
    <PanelImage src={imgSrc} />
    <PanelDescription>{text}</PanelDescription>
  </StyledPanel>
)

export default Panel
