import gql from 'graphql-tag'
import { ReelCard_reel } from './ReelCard.fragments'

const path =
  '/reels' +
  '?filter[project]={args.projectId}' +
  '&page[size]=50' +
  '&sort=-update_dt' +
  '&fields[reels]=id,name,published_to_presentation_fl,thumbnail_url,clip_count,update_dt'

const VI_PROJECT_REELS_QUERY = gql`
  query VIProjectReelsQuery($projectId: String!) {
    reels(projectId: $projectId)
    @jsonapi(
      path: "${path}"
      includeJsonapi: true
    ) {
      graphql {
        ... on VIReels {
          id

          ...ReelCard_reel
        }
      }
      jsonapi {
        links {
          next
        }
      }
    }
  }

  ${ReelCard_reel}
`

export const VI_DYNAMIC_PROJECT_REELS_QUERY = gql`
  query VIProjectReelsQuery($path: String!) {
    reels(path: $path) @jsonapi(path: "{args.path}", includeJsonapi: true) {
      graphql {
        ... on VIReels {
          id

          ...ReelCard_reel
        }
      }
      jsonapi {
        links {
          next
        }
      }
    }
  }

  ${ReelCard_reel}
`

export default VI_PROJECT_REELS_QUERY
