import React from 'react'
import { Button, ModalV2 as Modal, Dialog } from 'stylist'

const FinalizeReelWarningModal = ({ name, children }) => (
  <Modal
    lazyRender
    blurBackground
    render={({ hide }) => (
      <Dialog>
        <Dialog.Header>
          <Dialog.Title>Publish reel</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          Can’t publish "{name}" because it wasn’t finalized.
        </Dialog.Body>
        <Dialog.Footer>
          <Button kind="utility" onClick={() => hide()}>
            Close
          </Button>
        </Dialog.Footer>
      </Dialog>
    )}
  >
    {children}
  </Modal>
)

export default FinalizeReelWarningModal
