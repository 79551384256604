import React, { useState, useRef, useEffect } from 'react'
import {
  css,
  color,
  fontWeight,
  fontSize,
  marginRight,
  styled
} from '@fv/design-tokens'
import { electricBlue, silver } from 'styles/color'
import Button from 'components/Button'
import EditIcon from 'components/icons/Edit'
import SaveIcon from 'components/icons/Save'
import Tooltip from 'components/Tooltip'
import { ENTER, ESC } from 'utils/keyCodes'
import { replaceSpacesWithUnicode } from 'utils/regex'

const EditableTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const hideTitleCss = ({ hide }) =>
  hide
    ? css`
        position: absolute;
        top: 0;
        visibility: hidden;
      `
    : null

const Title = styled.h1`
  ${fontSize('m')};
  ${fontWeight('bold')};
  line-height: 1.25rem;
  margin: 0;
  ${marginRight('s')};
  padding: 4px;
  border: 1px solid rgba(255, 255, 255, 0);
  cursor: default;
  hyphens: auto;
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${hideTitleCss}
`

const adjustableTitleInputWidthCss = ({ adjustableWidth, width }) =>
  adjustableWidth
    ? css`
        width: ${width}px;
      `
    : css`
        width: 100%;
      `

const TitleInput = styled.input`
  ${fontSize('m')};
  ${fontWeight('bold')};
  line-height: 1.25rem;
  color: ${color('white')};
  background: transparent;
  margin: 0;
  ${marginRight('s')};
  padding: 4px;
  border: 1px solid ${silver};
  border-radius: 4px;
  min-width: ${({ minWidth }) => `${minWidth}px`};
  ${adjustableTitleInputWidthCss}

  &:focus {
    outline: none;
    border: 1px solid ${electricBlue};
  }
`

const EditableTitle = ({
  title,
  handleSubmit,
  inputLabel,
  editButtonLabel,
  saveButtonLabel,
  adjustableWidth,
  minWidth = 64
}) => {
  const [name, setName] = useState(title)
  const [width, setWidth] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const titleInputRef = useRef(null)
  const titleRef = useRef(null)

  useEffect(() => {
    if (isEditing) {
      titleInputRef.current.focus()
    }
  }, [isEditing])

  useEffect(() => {
    if (adjustableWidth) {
      updateWidth()
    }
  }, [name])

  useEffect(() => {
    setName(title)
  }, [title])

  const updateWidth = () => {
    setWidth(titleRef.current.offsetWidth)
  }

  const cancelChanges = () => {
    stopEditing()
    setName(title)
  }

  const startEditing = () => {
    setIsEditing(true)
  }

  const stopEditing = () => {
    setIsEditing(false)
  }

  const handleChange = e => {
    setName(e.target.value)
  }

  const handleKeyUp = e => {
    if (e.which === ENTER) {
      saveChanges()
    } else if (e.which === ESC) {
      cancelChanges()
    }
  }

  const saveChanges = async () => {
    if (name.length === 0) {
      cancelChanges()
      return
    }

    stopEditing()
    handleSubmit(name)
  }

  return (
    <EditableTitleContainer>
      <Title ref={titleRef} hide={isEditing}>
        {replaceSpacesWithUnicode(name)}
      </Title>
      {isEditing ? (
        <>
          <TitleInput
            value={name}
            ref={titleInputRef}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            aria-label={inputLabel}
            adjustableWidth={adjustableWidth}
            minWidth={minWidth}
            width={width}
          />
          <Tooltip placement="top" render={() => saveButtonLabel}>
            {({ show, hide, ref }) => (
              <Button
                type="button"
                ghost
                square
                ref={ref}
                onMouseOver={show}
                onMouseOut={hide}
                onClick={saveChanges}
                aria-label={saveButtonLabel}
              >
                <SaveIcon />
              </Button>
            )}
          </Tooltip>
        </>
      ) : (
        <Tooltip placement="top" render={() => editButtonLabel}>
          {({ show, hide, ref }) => (
            <Button
              type="button"
              ghost
              square
              onClick={startEditing}
              ref={ref}
              onMouseOver={show}
              onMouseOut={hide}
              aria-label={editButtonLabel}
            >
              <EditIcon />
            </Button>
          )}
        </Tooltip>
      )}
    </EditableTitleContainer>
  )
}

export default EditableTitle
