import gql from 'graphql-tag'
import { ReelSelectorOption_reel } from './ReelSelectorOption.fragments'

const VI_REELS_QUERY = gql`
  query VICopyClipModalReelsQuery($projectId: String!) {
    reels(projectId: $projectId)
      @jsonapi(
        path: "/reels?filter[project]={args.projectId}&fields[reels]=name,thumbnail_url,update_dt&sort=-update_dt"
      ) {
      id
      ...ReelSelectorOption_reel
    }
  }

  ${ReelSelectorOption_reel}
`

export default VI_REELS_QUERY
