import {
  styled,
  css,
  borderRadius,
  boxShadow,
  marginBottom,
  marginTop
} from '@fv/design-tokens'
import { white } from 'styles/color'

const ghost = ({ ghost }) =>
  ghost &&
  css`
    background: none;
    border-color: transparent;
    box-shadow: unset;
  `

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: ${({ width = '480px' }) => width};

  background: ${white};
  ${boxShadow('raised')};
  ${marginTop('xs')};
  ${marginBottom('xs')};
  ${borderRadius('m')};
  ${ghost};
`

Content.displayName = 'Dialog.Content'

export default Content
