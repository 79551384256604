import React, { useContext } from 'react'
import { styled } from '@fv/design-tokens'
import useQueryParams from 'hooks/useQueryParams'
import CurrentUserContext from 'components/CurrentUser/Context'
import Header from './Header'
import CurrentUserReels from './CurrentUserReels'
import ProjectReels from './ProjectReels'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 70px);
`

const ReelsIndex = ({ projectId }) => {
  const [queryParams] = useQueryParams()
  const activeTab = queryParams.get('reelsIndexTab')
  const { hasFeature } = useContext(CurrentUserContext)

  return (
    <Container>
      <Header />
      {activeTab === 'allReels' && hasFeature('20200128_streamline_v2') ? (
        <ProjectReels projectId={projectId} />
      ) : (
        <CurrentUserReels projectId={projectId} />
      )}
    </Container>
  )
}

export default ReelsIndex
