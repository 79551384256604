import React from 'react'
import { Button, ModalV2 as Modal, Dialog } from 'stylist'

const DeleteMarkModal = ({ text, secondsAfterStart, onDelete, children }) => (
  <Modal
    blurBackground
    render={({ hide }) => (
      <Dialog>
        <Dialog.Header>
          <Dialog.Title>Are you sure?</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          Mark '<strong>{text}</strong>' at <strong>{secondsAfterStart}</strong>{' '}
          will be permanently deleted.
        </Dialog.Body>
        <Dialog.Footer>
          <Button kind="ghost" onClick={hide}>
            Cancel
          </Button>
          <Button kind="danger" onClick={onDelete}>
            Delete
          </Button>
        </Dialog.Footer>
      </Dialog>
    )}
  >
    {children}
  </Modal>
)

export default DeleteMarkModal
