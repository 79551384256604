import React from 'react'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import { RadioButton, spacer } from 'stylist'
import styled from '@emotion/styled'
import AlertTriangleIcon from 'components/icons/AlertTriangle'
import { offWhite2, paleGrey, red } from 'styles/color'

const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${spacer(2)};
  border-top: 1px solid ${offWhite2};
  &.selected {
    background-color: rgb(214, 232, 255);
  }
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 389px;
  max-width: 389px;
`

const ItemText = styled.div`
  margin-left: ${spacer(2)};
  margin-right: ${spacer(2)};
  max-width: 301px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const NameContainer = styled.div`
  max-width: 301px;
  overflow: hidden;
  p {
    margin-bottom: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

const ProjectName = styled.p`
  font-size: 12px;
`

const ReelName = styled.p`
  font-size: 15px;
`

const MetadataContainer = styled.div`
  color: rgba(17, 17, 17, 0.5);
  font-size: 12px;
`

const Thumbnail = styled.img`
  grid-area: thumbnail;
  width: 70px;
  height: 40px;
`

const NewItemContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 389px;
  padding-left: 5px;
  padding-right: 20px;
  width: 389px;
  input {
    border: 1px solid ${paleGrey};
    border-radius: 4px;
    padding 9px 15px;
    width: 100%;
    &.no-name-warning {
      border: 1px solid ${red};
    }
  }
`

const StyledAlertTriangleIcon = styled(AlertTriangleIcon)`
  color: ${red};
  margin-left: -30px;
`

const ReelList = ({
  reels = [],
  query = '',
  selectedReel,
  onReelSelection = () => {},
  onNewReelNameChange = () => {},
  noNameWarning = true
}) => {
  const matchesRegex = new RegExp(query, 'i')

  const replaceMatches = str =>
    query ? str.replace(matchesRegex, c => `<b>${c}</b>`) : str

  const clipCountText = count => {
    const unit = count === 1 ? 'clip' : 'clips'
    return `${count} ${unit}`
  }

  const humanDateTime = dateTime => {
    const momentDate = moment(dateTime)
    const today = moment()
    const yesterday = moment().subtract(1, 'days')

    if (momentDate.isSame(today, 'd')) {
      return `Today at ${momentDate.format('h:mm A')}`
    } else if (momentDate.isSame(yesterday, 'd')) {
      return `Yesterday at ${momentDate.format('h:mm A')}`
    } else {
      return `on ${momentDate.format('MMM DD, YYYY')}`
    }
  }

  return reels.map(reel => (
    <Item
      key={reel.id || 'new'}
      className={selectedReel.id === reel.id ? 'selected' : undefined}
      onClick={() => {
        onReelSelection(reel)
      }}
    >
      {reel.new ? (
        <NewItemContainer>
          <input
            type="text"
            id="newReelName"
            className={noNameWarning ? 'no-name-warning' : undefined}
            placeholder="Untitled-1"
            value={reel.name}
            onChange={e => onNewReelNameChange(e.target.value)}
          />
          {noNameWarning && <StyledAlertTriangleIcon title={'Please enter a showreel name'} />}
        </NewItemContainer>
      ) : (
        <ItemContainer>
          <Thumbnail src={reel.thumbnailUrl} alt="Reel Thumbnail" />
          <ItemText>
            <NameContainer>
              <ProjectName>{reel.projectName}</ProjectName>
              <ReelName>{ReactHtmlParser(replaceMatches(reel.name))}</ReelName>
            </NameContainer>
            <MetadataContainer>
              <span>
                {clipCountText(reel.clipCount)} &middot; Last modified{' '}
                {humanDateTime(reel.updateDt)}
              </span>
            </MetadataContainer>
          </ItemText>
        </ItemContainer>
      )}
      <RadioButton
        name="reel-option"
        id={`reel-option-${reel.id}`}
        checked={selectedReel.id === reel.id}
        onClick={event => {
          event.stopPropagation()
        }}
      />
    </Item>
  ))
}

export default ReelList
