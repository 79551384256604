import { NOTIFY, REMOVE_NOTIFICATION } from './actions'

const reducer = (state = [], action) => {
  switch (action.type) {
    case NOTIFY:
      return [...state, action.payload]
    case REMOVE_NOTIFICATION:
      return state.filter(notification => notification.id !== action.payload.id)
    default:
      return state
  }
}

export default reducer
