import * as Sentry from '@sentry/browser'

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_GIT_REVISION,
    environment: process.env.REACT_APP_ENV,
    debug: false
  })
}

export default Sentry
