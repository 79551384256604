import gql from 'graphql-tag'
import { ReelVideoClip_reelVideoClip } from './ReelVideoClip.fragments'
import { CopyClipModal_reelVideoClip } from './CopyClipModal/CopyClipModal.fragments'
import { ReelVideoClips_reelVideoClip } from './ReelVideoClips.fragments'
import { ReelBuilder_reel } from './ReelBuilder.fragments'

export const INCLUDE =
  'include=reel_video_clips,reel_video_clips.content,reel_video_clips.content.video,reel_video_clips.content.video.response'
export const SPARSE_FIELDS = 'fields[videos]=id,response&fields[responses]=id'

const VI_REEL_QUERY = gql`
  query VIReelQuery($reelId: String!) {
    reel(reelId: $reelId)
      @jsonapi(
        path: "/reels/{args.reelId}?${INCLUDE}&${SPARSE_FIELDS}"
      ) {
      id
      ...ReelBuilder_reel

      reelVideoClips {
        id
        ...ReelVideoClip_reelVideoClip
        ...CopyClipModal_reelVideoClip
        ...ReelVideoClips_reelVideoClip
      }
    }
  }

  ${ReelVideoClip_reelVideoClip}
  ${CopyClipModal_reelVideoClip}
  ${ReelVideoClips_reelVideoClip}
  ${ReelBuilder_reel}
`

export default VI_REEL_QUERY
