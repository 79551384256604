import {
  styled,
  css,
  borderRadius,
  boxShadow,
  marginTop,
  marginBottom,
  paddingTop,
  paddingBottom
} from '@fv/design-tokens'
import { black, white } from 'styles/color'
import { compose, defaultTo, prop } from 'utils/fp'

const Menu = styled.ul`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  z-index: 1100;
  min-width: 10rem;
  list-style: none;
  margin: 0;
  padding: 0;
  ${marginTop('xxxs')};
  ${marginBottom('xxxs')};
  ${paddingTop('xxxs')};
  ${paddingBottom('xxxs')};
  color: ${black};
  background-color: ${white};
  max-height: ${compose(
    defaultTo('275px'),
    prop('maxHeight')
  )};
  overflow-y: auto;

  ${borderRadius('m')};
  ${boxShadow('distant')};

  ${({ align }) =>
    align === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
`

Menu.displayName = 'DropdownMenu.Menu'

export default Menu
