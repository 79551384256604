import {
  styled,
  borderRadius,
  background,
  color,
  borderColor,
  boxShadow
} from '@fv/design-tokens'

const UtilityButton = styled.button`
  border: 1px solid;
  ${background('buttonUtilityBackground')};
  ${borderColor('buttonUtilityBorderColor')};
  ${color('buttonUtilityText')};

  cursor: pointer;
  transition: background 0.15s;

  ${borderRadius('r')};
  width: 32px;
  height: 32px;

  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    ${background('buttonUtilityBackgroundHover')};
    ${borderColor('buttonUtilityBorderColorHover')};
    ${color('buttonUtilityTextHover')};
    ${boxShadow('subtle')};
  }

  &:active {
    ${background('buttonUtilityBackgroundActive')};
    ${borderColor('buttonUtilityBorderColorActive')};
    ${color('buttonUtilityTextActive')};
  }

  svg {
    fill: currentColor;
    height: 18px;
    width: 18px;
  }
`

export default UtilityButton
