import React, { useRef, useEffect, useState, useContext } from 'react'
import { debounce } from 'debounce'
import {
  styled,
  background,
  fontWeight,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft
} from '@fv/design-tokens'
import Popover from 'components/Popover'
import PopoverDialog from 'components/PopoverDialog'
import Button from 'components/Button'
import VideoControllerContext from 'components/VideoPlayer/VideoControllerContext'
import InfoButton from 'components/InfoButton'
import TabLoader from 'components/AdvancedEditor/TabLoader'
import TranscriptFragments from './TranscriptFragments'
import TranscriptContext from './Context'
import { capitalize } from 'utils/index'
import {
  HUMAN_TRANSCRIPT_TYPE,
  MACHINE_TRANSCRIPT_TYPE,
  getAnalysisStatus
} from 'utils/analysis'

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${paddingTop('s')};
  ${paddingRight('m')};
  ${paddingBottom('s')};
  ${paddingLeft('m')};
`

const Column = styled.div`
  width: 70%;
  margin: 0 auto;
  ${paddingTop('m')};
  ${paddingRight('m')};
`

const Tip = styled.span`
  ${fontWeight('bold')};
  display: flex;
  align-items: center;
`

const TranscriptContainer = styled.div`
  overflow-y: auto;
  scrollbar-color: gray transparent;

  ${background('background')};
`

const StatusMessage = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  ${padding('m')};
`

const isCurrentSentence = ({ startTime, endTime }, time) =>
  startTime < time && endTime > time

const Transcript = ({ canEdit, videoAnalysis, loading, viTranscriptionStatus }) => {
  const { sentences } = useContext(TranscriptContext)
  const reactListRef = useRef(null)
  const transcriptContainerRef = useRef(null)
  const [autoScroll, setAutoScroll] = useState(true)
  const { currentTime } = useContext(VideoControllerContext)
  const transcriptType = videoAnalysis && videoAnalysis.transcriptType
  const status =
    videoAnalysis && getAnalysisStatus(videoAnalysis, 'transcription')
  const [scrollContainerClientRect, setScrollContainerClientRect] = useState({})

  useEffect(() => {
    if (transcriptContainerRef.current) {
      setScrollContainerClientRect(
        transcriptContainerRef.current.getBoundingClientRect()
      )
    }
  }, [transcriptContainerRef.current])

  const statusMessageText = () => {
    return viTranscriptionStatus === 'processing'
      ? 'Still generating transcript...'
      : viTranscriptionStatus === 'unprocessed'
      ? 'Transcript not available for this video.'
      : 'An error has occurred.'
  }

  if (autoScroll) {
    const currentIndex = sentences.findIndex(sentence =>
      isCurrentSentence(sentence, currentTime)
    )
    reactListRef.current && reactListRef.current.scrollTo(currentIndex)
  }

  /* eslint-disable no-multi-str */
  const popoverTextGeneric =
    'A time-synced transcript of the video. Machine-generated transcripts are \
    available by default, though you can request human transcription if \
    desired.'
  const popoverTextHuman =
    'A transcript of the video created by a human being, available upon \
    request. You must manually submit videos for human transcription.'
  const popoverTextMachine =
    "A machine-generated transcript of the video. When the video is analyzed, \
    machine transcripts are automatically created using Insights' specialized \
    automatic speech recognition (ASR) technology."
  /* eslint-enable no-multi-str */
  const popoverText =
    transcriptType === HUMAN_TRANSCRIPT_TYPE && status === 'completed'
      ? popoverTextHuman
      : transcriptType === MACHINE_TRANSCRIPT_TYPE
      ? popoverTextMachine
      : popoverTextGeneric

  return (
    <Container>
      <Header>
        <Popover
          portal
          placement="bottom"
          render={({ hide }) => (
            <PopoverDialog
              hide={hide}
              popoverTitle={
                transcriptType
                  ? `${capitalize(transcriptType.toLowerCase())} Transcript`
                  : ''
              }
              popoverBody={popoverText}
            />
          )}
        >
          {({ toggle, ref }) => (
            <Tip>
              Transcript
              <InfoButton ghost square small ref={ref} onClick={toggle} />
            </Tip>
          )}
        </Popover>
        <Button
          small
          active={autoScroll}
          onClick={() => setAutoScroll(!autoScroll)}
        >
          Autoscroll {autoScroll ? 'on' : 'off'}
        </Button>
      </Header>
      {loading ? (
        <TabLoader />
      ) : (
        <TranscriptContainer
          ref={transcriptContainerRef}
          onWheel={() => autoScroll && setAutoScroll(false)}
          onScroll={debounce(() =>
            setScrollContainerClientRect(
              transcriptContainerRef.current.getBoundingClientRect()
            )
          )}
        >
          <Column>
            {sentences.length ? (
              <TranscriptFragments
                sentences={sentences}
                scrollContainerClientRect={scrollContainerClientRect}
                autoScroll={autoScroll}
                canEdit={canEdit}
              />
            ) : (
              <StatusMessage>{statusMessageText()}</StatusMessage>
            )}
          </Column>
        </TranscriptContainer>
      )}
    </Container>
  )
}

export default Transcript
