import { createContext } from 'react'
import noop from 'utils/noop'

const Context = createContext({
  show: noop,
  hide: noop,
  hidden: true
})

export const Consumer = Context.Consumer
export const Provider = Context.Provider

export default Context
