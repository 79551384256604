import React from 'react'
import { Redirect } from 'react-router'

const RouteRails = ({ match: { path }, location }) => (
  <Redirect
    to={{ ...location, pathname: location.pathname.replace(path, '/a') }}
    replace
  />
)

export default RouteRails
