import { styled, css } from '@fv/design-tokens'

const size = ({ size }) =>
  size
    ? css`
        width: ${size};
        height: ${size};
      `
    : null

const pointerEvents = ({ pointerEvents }) =>
  pointerEvents
    ? css`
        pointer-events: ${pointerEvents};
      `
    : null

const Svg = styled.svg`
  ${size};
  ${pointerEvents};
  fill: currentColor;

  path {
    fill: currentColor;
  }
`

export default Svg
