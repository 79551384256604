import gql from 'graphql-tag'
import { ReelSelectorOption_reel } from './ReelSelectorOption.fragments'

const path =
  '/reels' +
  '?filter[company]={args.companyId}' +
  '&filter[user_roles]=default' +
  '&filter[name]={args.name}' +
  '&fields[reels]=name,thumbnail_url,project_name,clip_count,update_dt&sort=-update_dt'

const VI_REELS_QUERY = gql`
  query VICopyClipModalReelsQuery($companyId: String!, $name: String) {
    reels(companyId: $companyId, name: $name)
      @jsonapi(
        path: "${path}"
        includeJsonapi: true
      ) {
      graphql {
        ... on VIReels {
          id
          ...ReelSelectorOption_reel
        }
      }
      jsonapi {
        links {
          next
        }
      }
    }
  }

  ${ReelSelectorOption_reel}
`

export default VI_REELS_QUERY
