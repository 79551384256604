import gql from 'graphql-tag'
import { ReelVideoClips_workspace } from './ReelVideoClips.fragments'

const VI_WORKSPACES_QUERY = gql`
  query VIWorkspacessQuery($projectId: String!, $userId: String!) {
    workspaces(projectId: $projectId, userId: $userId)
      @jsonapi(
        path: "/workspaces?filter[project]={args.projectId}&filter[user]={args.userId}"
      ) {
      id

      ...ReelVideoClips_workspace
    }
  }

  ${ReelVideoClips_workspace}
`

export default VI_WORKSPACES_QUERY
