import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  css,
  styled,
  background,
  fontWeight,
  marginRight,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft
} from '@fv/design-tokens'
import Popover from 'components/Popover'
import VideoControllerContext from 'components/VideoPlayer/VideoControllerContext'
import Button from 'components/Button'
import PopoverDialog from 'components/PopoverDialog'
import PlaySmIcon from 'components/icons/PlaySm'
import HighlightClip from './HighlightClip'
import HighlightClipsGuard from './HighlightClipsGuard'
import InfoButton from 'components/InfoButton'
import getSuggestedClips from 'components/AdvancedEditor/getSuggestedClips'

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`

const ClipsContainer = styled.div`
  ${background('background')};
  overflow-y: auto;
  scrollbar-color: gray transparent;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${paddingTop('s')};
  ${paddingRight('m')};
  ${paddingBottom('s')};
  ${paddingLeft('m')};
`

const Tip = styled.span`
  ${fontWeight('bold')};
  display: flex;
  align-items: center;
`

const Column = styled.div`
  width: 70%;
  margin: 0 auto;
  ${paddingTop('m')};
  ${paddingRight('m')};
`

const defaultIconStyles = ({ theme }) => css`
  ${marginRight('xxs', { theme })};
  width: 0.825rem !important;
  height: 0.825rem !important;
`

const PlayIcon = styled(PlaySmIcon)`
  ${defaultIconStyles};
`

const Highlights = ({ canEdit, videoAnalysis, video, isPlaying, title }) => {
  const { playClips } = useContext(VideoControllerContext)
  const clips = getSuggestedClips(videoAnalysis)
  const containerRef = useRef(null)
  const [containerRect, setContainerRect] = useState({})

  useEffect(() => {
    if (containerRef.current) {
      setContainerRect(containerRef.current.getBoundingClientRect())
    }
  }, [containerRef.current])

  return (
    <Container>
      <Header>
        <Popover
          portal
          placement="bottom"
          render={({ hide }) => (
            <PopoverDialog
              hide={hide}
              popoverTitle="Highlights"
              popoverBody="A selection of key moments from the video, suggested based on a contextual analysis of the video transcript. Highlights are automatically generated by Insights and can contain any amount of text."
            />
          )}
        >
          {({ toggle, ref }) => (
            <Tip>
              What is this?
              <InfoButton ghost square small ref={ref} onClick={toggle} />
            </Tip>
          )}
        </Popover>
        {!!clips.length && (
          <Button
            small
            active={isPlaying}
            onClick={() => {
              playClips(
                clips.map(({ id, startTime, endTime }) => ({
                  id,
                  startTime,
                  endTime
                }))
              )
            }}
          >
            <PlayIcon />
            Play all
          </Button>
        )}
      </Header>
      <ClipsContainer
        ref={containerRef}
        onScroll={() =>
          setContainerRect(containerRef.current.getBoundingClientRect())
        }
      >
        <HighlightClipsGuard video={video} clips={clips}>
          <Column>
            {clips.map((clip, index) => (
              <HighlightClip
                canEdit={canEdit}
                scrollContainerClientRect={containerRect}
                id={clip.id}
                isPlaying={isPlaying}
                key={clip.id}
                title={title}
                ordinal={index + 1}
                {...clip}
              />
            ))}
          </Column>
        </HighlightClipsGuard>
      </ClipsContainer>
    </Container>
  )
}

export default Highlights
