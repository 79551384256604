import React from 'react'
import {
  styled,
  gridColumnGap,
  paddingLeft,
  paddingRight
} from '@fv/design-tokens'
import HorizontalNavigation from './HorizontalNavigation'

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  align-items: center;
  ${gridColumnGap('m')};
`

const Left = styled.div`
  ${paddingLeft('m')};
`
const Center = styled.div`
  display: flex;
  justify-content: center;
`
const Right = styled.div`
  ${paddingRight('m')};
`

const SubNavigation = () => (
  <Container>
    <Left />
    <Center>
      <HorizontalNavigation />
    </Center>
    <Right />
  </Container>
)

export default SubNavigation
