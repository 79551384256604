import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from '@apollo/react-components'
import { AdvancedEditor_video } from 'components/AdvancedEditor/AdvancedEditor.fragments'
import { dataIdFromObject } from 'config/apollo'

export const VI_SEND_VIDEO_TO_HIGHLIGHT_REEL = gql`
  mutation VISendVideoToHighlightReel($input: SendVideoToHighlightReelInput!) {
    video(input: $input)
      @jsonapi(path: "/videos/{args.input.data.id}", method: "PATCH") {
      id
      sentToInsightsAt
    }
  }
`

const SendVideoToHighlightReel = ({ video, children }) => (
  <Mutation
    mutation={VI_SEND_VIDEO_TO_HIGHLIGHT_REEL}
    update={(
      cache,
      {
        data: {
          video: { sentToInsightsAt }
        }
      }
    ) => {
      const cachedVideo = cache.readFragment({
        id: dataIdFromObject(video),
        fragment: AdvancedEditor_video,
        fragmentName: 'AdvancedEditor_video'
      })

      cache.writeFragment({
        id: dataIdFromObject(video),
        fragment: AdvancedEditor_video,
        fragmentName: 'AdvancedEditor_video',
        data: { ...cachedVideo, sentToInsightsAt }
      })
    }}
  >
    {(mutate, result) =>
      children({
        sendVideoToHighlightReel: () =>
          mutate({
            variables: {
              input: {
                data: {
                  id: video.id,
                  type: 'videos',
                  attributes: { sentToInsightsAt: true }
                }
              }
            },
            optimisticResponse: {
              video: {
                __typename: 'VIVideos',
                type: 'videos',
                id: video.id,
                sentToInsightsAt: new Date().toISOString()
              }
            }
          }),
        ...result
      })
    }
  </Mutation>
)

export default SendVideoToHighlightReel
