import React from 'react'
import Svg from 'components/Svg'

const SynchronizeIcon = props => (
  <Svg viewBox="0 0 16 16" {...props}>
    <title>{props.title || 'Synchronize'}</title>
    <path d="M5.3,7.8c0.2-0.2,0.1-0.5-0.1-0.7C5,6.9,4.7,6.9,4.5,7.2L3.4,8.5c0-0.9,0.2-1.7,0.7-2.5C4.7,5,5.8,4.2,7,3.9c1.6-0.4,3.3,0.2,4.4,1.4c0.2,0.2,0.5,0.2,0.7,0c0.2-0.2,0.2-0.5,0-0.7c-1.3-1.5-3.4-2.2-5.3-1.7C5.3,3.3,4,4.2,3.2,5.5C2.8,6.2,2.5,7.1,2.4,7.9L1.8,7C1.7,6.8,1.4,6.7,1.1,6.9C0.9,7,0.8,7.3,1,7.6L2.6,10c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1L5.3,7.8z" />
    <path d="M15,9.5l-1.6-2.4C13.3,7,13.1,6.9,13,6.9c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0l-1.8,2.2c-0.2,0.2-0.1,0.5,0.1,0.7s0.5,0.1,0.7-0.1l1-1.2c-0.2,1.8-1.6,3.4-3.4,3.8c-1.5,0.4-3.1-0.1-4.2-1.2c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7c1,1,2.4,1.6,3.8,1.6c0.4,0,0.8,0,1.3-0.1c2.2-0.5,3.8-2.4,4.2-4.6l0.8,1.1c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1C15.1,10.1,15.2,9.8,15,9.5z" />{' '}
  </Svg>
)

export default SynchronizeIcon
