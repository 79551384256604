import React from 'react'
import styled from '@emotion/styled'
import { spacer } from 'stylist'
import { offWhite2 } from 'styles/color'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${spacer(5)};
  background-color: ${offWhite2};
  font-size: 13px;
`

const NoResults = ({ text }) => {
  return (
    <Container>
      <span>{text}</span>
    </Container>
  )
}

export default NoResults
