import React, { useEffect, useRef } from 'react'
import { styled } from '@fv/design-tokens'
import { getSelectedText } from './utils'

const Container = styled.div``

const TextSelector = ({ children, onSelection, onDeselection }) => {
  const container = useRef(null)

  useEffect(() => {
    const selectionChangeHandler = e => {
      const selection = window.getSelection()

      if (
        container.current.contains(selection.focusNode) &&
        container.current.contains(selection.anchorNode) &&
        selection.toString().trim()
      ) {
        if (onSelection) {
          onSelection(getSelectedText())
        }
      } else {
        if (onDeselection) {
          onDeselection()
        }
      }
    }

    document.addEventListener('selectionchange', selectionChangeHandler)

    return () =>
      document.removeEventListener('selectionchange', selectionChangeHandler)
  }, [])

  return <Container ref={container}>{children}</Container>
}

export default TextSelector
