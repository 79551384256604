import React from 'react'
import Svg from 'components/Svg'

const ReelBuilder = props => (
  <Svg viewBox="0 0 18 18" {...props}>
    <title>reel builder</title>
    <g>
      <path d="M11.813 9.375v-8.25a.75.75 0 00-.75-.75h-9.75a.75.75 0 00-.75.75v13.5c0 .414.335.75.75.75h7.125" />
      <path d="M9.563.375v6h-6.75v-6M2.813 3.375H.563M2.813 6.375H.563M11.813 3.375h-2.25M11.813 6.375h-2.25M2.813 15.375v-6h6.75v3.375M2.813 9.375H.563M2.813 12.375H.563M11.813 9.375h-2.25M12.739 17.625l4.235-7.941a.75.75 0 00-.309-1.015l-1.323-.706a.75.75 0 00-1.015.31L9.34 17.625M15.563 12.33l-2.648-1.41M14.813 4.875v1.5M15.563 5.625h-1.5M16.313.75V3M17.438 1.875h-2.25" />
    </g>
  </Svg>
)

export default ReelBuilder
