import React, { useContext } from 'react'
import {
  styled,
  css,
  spacingFor,
  padding,
  paddingBottom,
  marginRight,
  marginTop,
  fontSize,
  fontWeight
} from '@fv/design-tokens'
import Button from 'components/Button'
import CloseIcon from 'components/icons/Close'
import CurrentUserContext from 'components/CurrentUser/Context'
import useQueryParams from 'hooks/useQueryParams'
import { electricBlue } from 'styles/color'

const containerWithTabsStyle = ({ hasTabs }) =>
  hasTabs &&
  css`
    padding-bottom: 0;
  `

const Container = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: ${spacingFor('m')};
  ${padding('m')};
  ${containerWithTabsStyle};
  box-shadow: 0 4px 4px -4px #0006;
  z-index: 2;
  align-items: center;
`

const Title = styled.h1`
  width: 100%;
  ${fontSize('m')};
  ${fontWeight('bold')};
  line-height: 1.25;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: default;
`

const ReelsTabContainer = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  ${marginTop('xs')};
`

const activeCss = ({ active }) =>
  active &&
  css`
    border-bottom: 4px solid ${electricBlue};
  `

const ReelsTabItem = styled.li`
  list-style: none;
  cursor: pointer;

  ${paddingBottom('xxs')};
  ${marginRight('s')};
  ${activeCss};
`

const Header = () => {
  const [queryParams, setQueryParams] = useQueryParams()
  const activeTab = queryParams.get('reelsIndexTab')
  const { hasFeature } = useContext(CurrentUserContext)

  return (
    <Container hasTabs={hasFeature('20200128_streamline_v2')}>
      <Title>Open Reel</Title>
      <Button
        square
        type="button"
        aria-label="Close"
        onClick={() =>
          setQueryParams({
            reelBuilderPanel: undefined,
            reelsIndexTab: undefined
          })
        }
      >
        <CloseIcon />
      </Button>
      {hasFeature('20200128_streamline_v2') && (
        <ReelsTabContainer>
          <ReelsTabItem
            key="my-reels"
            active={activeTab !== 'allReels'}
            onClick={() =>
              setQueryParams({
                reelsIndexTab: 'myReels'
              })
            }
          >
            My Reels
          </ReelsTabItem>
          <ReelsTabItem
            key="all-reels"
            active={activeTab === 'allReels'}
            onClick={() =>
              setQueryParams({
                reelsIndexTab: 'allReels'
              })
            }
          >
            All Reels
          </ReelsTabItem>
        </ReelsTabContainer>
      )}
    </Container>
  )
}

export default Header
