import React from 'react'
import { Query } from '@apollo/react-components'
import FEATURES_QUERY from './Features.query'
import { Provider } from './FeaturesContext'
import dig from 'utils/dig'

const FeaturesProvider = ({ children, video }) => (
  <Query
    context={{
      headers: {
        Authorization: video && `Bearer ${video.highlightReelJwt}`
      }
    }}
    query={FEATURES_QUERY}
  >
    {({ loading, data }) => (
      <Provider
        value={{ features: dig(data, 'viewer', 'features') || [], loading }}
      >
        {children}
      </Provider>
    )}
  </Query>
)

export default FeaturesProvider
