import React, { forwardRef } from 'react'
import {
  styled,
  background,
  color,
  borderColor,
  boxShadow
} from '@fv/design-tokens'
import PauseSmIcon from 'components/icons/PauseSm'
import PlaySmIcon from 'components/icons/PlaySm'
import UtilityButton from './UtilityButton'

const ToggleButton = styled(UtilityButton)`
  ${background('buttonPlayBackground')};
  ${borderColor('buttonPlayBorderColor')};
  ${color('buttonPlayText')};

  &:hover {
    ${background('buttonPlayBackgroundHover')};
    ${borderColor('buttonPlayBorderColorHover')};
    ${color('buttonPlayTextHover')};
  }

  &:active {
    ${background('buttonPlayBackgroundActive')};
    ${borderColor('buttonPlayBorderColorActive')};
    ${color('buttonPlayTextActive')};
  }

  ${({ isActivePlayer, theme }) =>
    isActivePlayer &&
    `
    ${background('buttonPlayBackgroundActive', { theme })};
    ${borderColor('buttonPlayBorderColorActive', { theme })};
    ${color('buttonPlayTextActive', { theme })};
    ${boxShadow('raised', { theme })};

    &:hover {
      ${background('buttonPlayBackgroundActive', { theme })};
      ${borderColor('buttonPlayBorderColorActive', { theme })};
      ${color('buttonPlayTextActive', { theme })};
    }
  `}
`

const PlaybackToggle = forwardRef(
  ({ isActivePlayer, playing, ...rest }, ref) => (
    <ToggleButton ref={ref} isActivePlayer={isActivePlayer} {...rest}>
      {playing && isActivePlayer ? <PauseSmIcon /> : <PlaySmIcon />}
    </ToggleButton>
  )
)

export default PlaybackToggle
