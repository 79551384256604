import gql from 'graphql-tag'

export const DeleteReelModal_reel = gql`
  fragment DeleteReelModal_reel on VIReels {
    id
    name
    thumbnailUrl
    publishedToPresentationFl
    updateDt
  }
`
