import React, { useState, useEffect } from 'react'
import { Manager, Reference, Popper } from 'react-popper'
import { createPortal } from 'react-dom'
import Container from './Container'
import { styled } from '@fv/design-tokens'

const NoSvgPointerEvents = styled.div`
  svg {
    pointer-events: none;
  }
`

const CLOSED = 'closed'
const OPENING = 'opening'
const OPEN = 'open'
const CLOSING = 'closing'

const Tooltip = ({
  placement,
  render,
  children,
  delay = 250,
  transitionTime = 200
}) => {
  const [state, setState] = useState(CLOSED)
  const [timer, setTimer] = useState(null)
  const [{ top, left }, setPosition] = useState({})

  const setNewTimer = newTimer => {
    clearTimeout(timer)
    setTimer(newTimer)
  }

  const show = () =>
    setNewTimer(
      setTimeout(() => {
        setState(OPENING)
      }, delay)
    )

  // We want the `OPENING` state to persist for a single render
  // so we can CSS transition in the tooltip
  useEffect(() => {
    if (state === OPENING) {
      setState(OPEN)
    }
  }, [state])

  const hide = () => {
    // We want the `CLOSING` state to persist until we are ready to un-render
    // the tooltip so CSS can transition the tooltip away
    setState(CLOSING)
    setNewTimer(
      setTimeout(() => {
        setState(CLOSED)
      }, transitionTime)
    )
  }

  const tooltipRoot = document.getElementById('tooltip-root')

  const reference = (
    <Reference>
      {({ ref }) => (
        <NoSvgPointerEvents>{children({ show, hide, ref })}</NoSvgPointerEvents>
      )}
    </Reference>
  )

  const updatePosition = ({
    offsets: {
      popper: { top, left }
    }
  }) => setPosition({ top, left })

  useEffect(() => () => {
    clearTimeout(timer)
  })

  if (state === CLOSED) {
    return <Manager>{reference}</Manager>
  }

  const tooltip = (
    <Popper
      placement={placement}
      modifiers={{
        preventOverflow: { enabled: false },
        eventsEnabled: false,
        hide: { enabled: false },
        applyStyle: { enabled: false },
        applyReactStyle: {
          enabled: true,
          order: 900,
          fn: data => {
            updatePosition(data)
            return data
          }
        }
      }}
    >
      {({ ref, placement: popperPlacement }) => (
        <Container
          ref={ref}
          placement={popperPlacement || placement}
          top={top}
          left={left}
          open={state === OPEN}
          opening={state === OPENING}
          closing={state === CLOSING}
          transitionTime={transitionTime}
        >
          {render()}
        </Container>
      )}
    </Popper>
  )

  return (
    <Manager>
      {reference}
      {createPortal(tooltip, tooltipRoot)}
    </Manager>
  )
}

export default Tooltip
