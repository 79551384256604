import React, { Fragment, useContext, useRef, useEffect } from 'react'
import {
  styled,
  background,
  borderRadius,
  fontWeight,
  marginBottom,
  padding,
  gridGap
} from '@fv/design-tokens'
import * as yup from 'yup'
import { formatTime } from 'utils/time'
import { floor, ceil } from 'utils/math'
import Form from 'components/Form'
import Button from 'components/Button'
import Loader from 'components/Loader'
import TimestampInput from 'components/TimestampInput'
import CloseIcon from 'components/icons/Close'
import SaveIcon from 'components/icons/Save'
import StartPointIcon from 'components/icons/StartPoint'
import EndPointIcon from 'components/icons/EndPoint'
import FormError from 'components/AdvancedEditor/FormError'
import Tooltip from 'components/Tooltip'
import VideoControllerContext from 'components/VideoPlayer/VideoControllerContext'

const schema = (videoLength = 3600 * 2) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Name can't be blank"),
    startTime: yup
      .number()
      .lessThan(
        videoLength,
        `Start time must be less than ${formatTime(videoLength)}`
      )
      .required("Start time can't be blank"),
    endTime: yup
      .number()
      .lessThan(
        videoLength,
        `End time must be less than ${formatTime(videoLength)}`
      )
      .test(
        'clip-must-be-greater-than-3-seconds',
        'Clip must be greater than 3 seconds in length',
        function(value) {
          return value - this.parent.startTime > 3
        }
      )
      .required("End time can't be blank")
  })

const StyledForm = styled(Form)`
  ${background('background')};
  ${borderRadius('m')};
  ${marginBottom('m')};
  ${padding('s')};

  [kind='error'] {
    margin: 0;
    ${marginBottom('xs')};
  }
`

const SaveButton = styled(Button)`
  grid-area: save;
`

const CancelButton = styled(Button)`
  grid-area: cancel;
`

const InputContainer = styled.div`
  display: grid;
  ${gridGap('xs')};
  grid-template-columns: 1fr 1fr auto auto;
  grid-template-areas:
    'name name save cancel'
    'start end length length';

  [kind='error'] {
    display: none;
  }
`

const TimestampInputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  > label {
    ${fontWeight('bold')};
  }
`

const Start = styled(TimestampInputGroup)`
  grid-area: start;
`

const End = styled(TimestampInputGroup)`
  grid-area: end;
`

const StyledTimestampInput = styled(TimestampInput)`
  ${padding('xxs')};
  ${background('backgroundShaded')};
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-right: 0px;
`

const SetTimeButton = styled(Button)`
  border: none;
  ${background('buttonBackground')};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 100%;
  ${padding('xxs')};
`

const LengthDisplay = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${background('backgroundShaded')};
  ${borderRadius('m')};
  grid-area: length;
`

const NameInput = styled(Form.Input)`
  grid-area: name;
`

const ClipForm = ({
  name = '',
  startTime = 0,
  endTime = 4,
  videoLength,
  handleSubmit,
  handleCancel,
  loading
}) => {
  const nameInput = useRef(null)
  const formRef = useRef(null)

  useEffect(() => {
    formRef.current.scrollIntoView(false)
    nameInput.current.focus()
  }, [])

  const handleOnKeyDown = e => {
    if (e.key === 'Escape') {
      e.preventDefault()
      handleCancel()
    }
  }

  const { currentTime } = useContext(VideoControllerContext)

  return (
    <StyledForm
      ref={formRef}
      initialValues={{
        name,
        startTime: floor(startTime),
        endTime: ceil(endTime)
      }}
      validationSchema={schema(videoLength)}
      validateOnChange={false}
      validateOnBlur={false}
      disabled={loading}
      onSubmit={handleSubmit}
    >
      {({ values: { startTime, endTime } }) => (
        <Fragment>
          <FormError name="name" />
          <FormError name="startTime" />
          <FormError name="endTime" />
          <InputContainer>
            <NameInput
              onKeyDown={handleOnKeyDown}
              ref={nameInput}
              type="text"
              name="name"
              size="s"
              aria-label="Name"
              placeholder="Add name"
            />
            <Tooltip placement="top" render={() => 'Save'}>
              {({ show, hide, ref }) => (
                <SaveButton
                  small
                  square
                  primary
                  disabled={loading}
                  onMouseOver={show}
                  onMouseOut={hide}
                  ref={ref}
                  aria-label="Save clip"
                >
                  {loading ? <Loader /> : <SaveIcon />}
                </SaveButton>
              )}
            </Tooltip>
            <Tooltip placement="top" render={() => 'Cancel'}>
              {({ show, hide, ref }) => (
                <CancelButton
                  small
                  square
                  disabled={loading}
                  type="button"
                  onClick={() => handleCancel()}
                  onMouseOver={show}
                  onMouseOut={hide}
                  ref={ref}
                  aria-label="Cancel"
                >
                  <CloseIcon />
                </CancelButton>
              )}
            </Tooltip>
            <Form.Field
              name="startTime"
              render={({ setValue, value }) => (
                <Start>
                  <StyledTimestampInput
                    name="startTime"
                    aria-label="Start time"
                    setValue={setValue}
                    value={value}
                  />
                  <Tooltip placement="bottom" render={() => 'Set start time'}>
                    {({ show, hide, ref }) => (
                      <SetTimeButton
                        type="button"
                        onClick={() => setValue(floor(currentTime))}
                        onMouseOver={show}
                        onMouseOut={hide}
                        ref={ref}
                        aria-label="Set start time"
                      >
                        <StartPointIcon />
                      </SetTimeButton>
                    )}
                  </Tooltip>
                </Start>
              )}
            />
            <Form.Field
              name="endTime"
              render={({ setValue, value }) => (
                <End>
                  <StyledTimestampInput
                    name="endTime"
                    aria-label="End time"
                    setValue={setValue}
                    value={value}
                  />
                  <Tooltip placement="bottom" render={() => 'Set end time'}>
                    {({ show, hide, ref }) => (
                      <SetTimeButton
                        type="button"
                        onClick={() => setValue(ceil(currentTime))}
                        onMouseOver={show}
                        onMouseOut={hide}
                        ref={ref}
                        aria-label="Set end time"
                      >
                        <EndPointIcon />
                      </SetTimeButton>
                    )}
                  </Tooltip>
                </End>
              )}
            />
            <LengthDisplay>
              {endTime - startTime > 0 ? `${endTime - startTime}s` : 'N/A'}
            </LengthDisplay>
          </InputContainer>
        </Fragment>
      )}
    </StyledForm>
  )
}

export default ClipForm
