import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import CurrentUserContext from 'components/CurrentUser/Context'
import NoReelsPlaceholder from 'components/AdvancedEditor/ReelBuilder/NoReelsPlaceholder'
import VI_CURRENT_USER_REELS_QUERY, {
  VI_DYNAMIC_CURRENT_USER_REELS_QUERY
} from './CurrentUserReels.query'
import ListContainer from './ListContainer'
import { getNextPagePath } from 'utils'

const CurrentUserReels = ({
  projectId,
  onReelSelect,
  excludeReelIds,
  selectedReelId
}) => {
  const { viewer } = useContext(CurrentUserContext)
  const {
    data: {
      reels: {
        graphql: reels = [],
        jsonapi: { links: { next } = {} } = {}
      } = {}
    } = {},
    loading,
    fetchMore
  } = useQuery(VI_CURRENT_USER_REELS_QUERY, {
    variables: { projectId, userId: viewer.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  return loading || reels.length ? (
    <ListContainer
      reels={reels}
      loading={loading}
      next={next}
      fetchMore={() => {
        if (!next || loading) {
          return
        }

        fetchMore({
          query: VI_DYNAMIC_CURRENT_USER_REELS_QUERY,
          variables: {
            path: getNextPagePath(next)
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev

            return {
              ...prev,
              reels: {
                ...fetchMoreResult.reels,
                graphql: [
                  ...prev.reels.graphql,
                  ...fetchMoreResult.reels.graphql
                ]
              }
            }
          }
        })
      }}
      onReelSelect={onReelSelect}
      excludeReelIds={excludeReelIds}
      selectedReelId={selectedReelId}
    />
  ) : (
    <NoReelsPlaceholder />
  )
}

export default CurrentUserReels
