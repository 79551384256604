import React from 'react'
import Dropdown from 'components/Dropdown'
import Field from './Field'

const composeHandlers = (...fns) => (...args) =>
  fns.filter(Boolean).forEach(fn => fn(...args))

const Select = ({ name, onChange, ...props }) => (
  <Field
    name={name}
    render={({ setValue, valid, value }) => (
      <Dropdown
        {...props}
        name={name}
        onChange={composeHandlers(setValue, onChange)}
        valid={valid}
        value={value}
      />
    )}
  />
)

Select.Option = Dropdown.Option

export default Select
