import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { getCurrentUserId } from 'utils/session'
import PageLoader from 'components/PageLoader'
import VI_CURRENT_USER_QUERY from './CurrentUser.query'
import { Provider } from './Context'
import { hasFeature } from './utils'

const CurrentUserProvider = ({ children }) => {
  const { data, loading } = useQuery(VI_CURRENT_USER_QUERY, {
    variables: { userId: getCurrentUserId() }
  })

  if (loading) {
    return <PageLoader />
  }

  return (
    <Provider
      value={{
        loading,
        viewer: data && data.currentUser,
        hasFeature: feature => hasFeature(data, feature)
      }}
    >
      {children}
    </Provider>
  )
}

export default CurrentUserProvider
