import React, { useContext, useState, useRef, useMemo } from 'react'
import { getDoubleSpaceCount } from 'utils'
import { formatTime } from 'utils/time'
import HighlightQuery from 'components/HighlightQuery'
import ClipContext from 'components/AdvancedEditor/ReelBuilder/ClipProvider'
import VideoControllerContext from 'components/VideoPlayer/VideoControllerContext'
import TranscriptContext from 'components/AdvancedEditor/Transcript/Context'
import Tooltip from 'components/Tooltip'
import TextSelector from 'components/TextSelector'
import ClipControlBar from 'components/AdvancedEditor/ClipControlBar'
import {
  calcTop,
  calcLeft,
  getPlacement
} from 'components/AdvancedEditor/ClipControlBar/utils'
import { getSelectionRangeClientRect } from 'components/TextSelector/utils'
import {
  Container,
  Content,
  Header,
  Timestamps,
  Score,
  StyledSentimentIcon,
  SentimentIconContainer,
  Text
} from './styled'

const _isCurrentText = (startTime, endTime, currentTime) =>
  startTime < currentTime && endTime > currentTime

const _isCurrentClip = (
  startTime,
  endTime,
  { startTime: clipStartTime, endTime: clipEndTime }
) => startTime >= clipStartTime && endTime <= clipEndTime

const formatText = text =>
  (text || '')
    .replace(/\s\.\s?/g, '.')
    .replace(/\./g, '. ')
    .replace(/\s,\s?/g, ',')
    .replace(/,/g, ', ')
    .replace(/\s\?\s?/g, '?')
    .replace(/\?/g, '? ')

const titleCase = word =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

const HighlightClip = ({
  canEdit,
  endTime,
  highlightScore,
  ordinal,
  scrollContainerClientRect,
  sentiment,
  startTime,
  text
}) => {
  // the highlight score is a float from -1 to 1
  const scoreAsPercentage = Math.round(((highlightScore + 1) / 2) * 100)
  const clip = useContext(ClipContext)
  const { currentTime } = useContext(VideoControllerContext)
  const { findTimestamps } = useContext(TranscriptContext)
  const containerRef = useRef(null)
  const [showControlBar, setShowControlBar] = useState(false)
  const [selectedText, setSelectedText] = useState(null)

  const isCurrentClip = _isCurrentClip(startTime, endTime, clip)
  const isCurrentText = _isCurrentText(startTime, endTime, currentTime)
  const containerClientRect =
    containerRef.current && containerRef.current.getBoundingClientRect()
  const selectionRangeClientRect = selectedText && getSelectionRangeClientRect()
  const [_startTime, _endTime] = useMemo(() => {
    if (selectedText) {
      const { anchorOffset, anchorNode } = window.getSelection()
      const anchorDoubleSpaceCount = getDoubleSpaceCount(
        anchorNode.textContent.slice(0, anchorOffset)
      )
      const textOffset = anchorOffset - anchorDoubleSpaceCount
      const skip = text.slice(0, textOffset).split(' ').length - 1

      return findTimestamps({ text: selectedText, startTime, endTime, skip })
    }

    return [startTime, endTime]
  }, [selectedText])

  return (
    <Container isCurrentText={isCurrentText} isCurrentClip={isCurrentClip}>
      <Content>
        <Header>
          <Tooltip
            placement="top"
            render={() => titleCase(sentiment || 'unknown')}
          >
            {({ show, hide, ref }) => (
              <SentimentIconContainer
                onMouseOver={show}
                onMouseOut={hide}
                ref={ref}
              >
                <StyledSentimentIcon sentiment={sentiment} />
              </SentimentIconContainer>
            )}
          </Tooltip>
          <Timestamps>
            {formatTime(startTime)} &ndash; {formatTime(endTime)}
          </Timestamps>
          <Tooltip placement="left" render={() => 'Confidence level'}>
            {({ show, hide, ref }) => (
              <Score onMouseOver={show} onMouseOut={hide} ref={ref}>
                {scoreAsPercentage}%
              </Score>
            )}
          </Tooltip>
        </Header>
        {text && (
          <Text
            noHover={selectedText}
            ref={containerRef}
            onMouseOver={() => !showControlBar && setShowControlBar(true)}
            onMouseLeave={() => showControlBar && setShowControlBar(false)}
          >
            <HighlightQuery string={formatText(text)}>
              {({ result }) => (
                <TextSelector
                  onSelection={selectedText => setSelectedText(selectedText)}
                  onDeselection={() => setSelectedText(null)}
                >
                  {result}
                </TextSelector>
              )}
            </HighlightQuery>
            {(selectedText || showControlBar) && (
              <ClipControlBar
                canCreateClip={canEdit}
                center
                top={calcTop({
                  scrollContainerClientRect,
                  containerClientRect,
                  selectionRangeClientRect,
                  adjustment: 3
                })}
                left={calcLeft({
                  containerClientRect,
                  selectionRangeClientRect
                })}
                placement={getPlacement({
                  scrollContainerClientRect,
                  elementOrRangeClientRect:
                    selectionRangeClientRect || containerClientRect
                })}
                startTime={_startTime}
                endTime={_endTime}
              />
            )}
          </Text>
        )}
      </Content>
    </Container>
  )
}

export default HighlightClip
