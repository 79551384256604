import { styled, padding } from '@fv/design-tokens'

const Message = styled.div`
  flex: 1;
  ${padding('xs')};
`

Message.displayName = 'Notification.Message'

export default Message
