import React from 'react'
import Svg from 'components/Svg'

const SaveIcon = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <title>save</title>
    <path d="M22,0H5.914A1.986,1.986,0,0,0,4.5.587L.586,4.5A2.009,2.009,0,0,0,0,5.915V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0ZM18.5,2a.5.5,0,0,1,.5.5v4A1.5,1.5,0,0,1,17.5,8H7.5A1.5,1.5,0,0,1,6,6.5v-4A.5.5,0,0,1,6.5,2ZM5,22a.5.5,0,0,1-.5-.5v-8A1.5,1.5,0,0,1,6,12H19a1.5,1.5,0,0,1,1.5,1.5v8a.5.5,0,0,1-.5.5Z" />
    <path d="M15,4.251v1.5a.75.75,0,1,0,1.5,0v-1.5a.75.75,0,1,0-1.5,0Z" />
    <path d="M7,16.251h6.5a.75.75,0,0,0,0-1.5H7a.75.75,0,0,0,0,1.5Z" />
    <path d="M7,20.251H18a.75.75,0,0,0,0-1.5H7a.75.75,0,0,0,0,1.5Z" />
  </Svg>
)

export default SaveIcon
