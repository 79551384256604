import { styled } from '@fv/design-tokens'

const AnalysisContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
`

export default AnalysisContainer
