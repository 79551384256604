import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from '@apollo/react-components'
import VI_MARKS_QUERY from 'components/AdvancedEditor/Marks/marks.query.js'
import { Mark_mark } from 'components/AdvancedEditor/Marks/Mark.fragments.js'

export const VI_CREATE_MARK = gql`
  mutation VICreateMarkMutation($input: CreateMarkInput!) {
    createMark(input: $input) @jsonapi(path: "/marks", method: "POST") {
      id
      ...Mark_mark
    }
  }

  ${Mark_mark}
`

const CreateMark = ({ responseId, children }) => (
  <Mutation
    mutation={VI_CREATE_MARK}
    update={(cache, { data: { createMark } }) => {
      const { marks } = cache.readQuery({
        query: VI_MARKS_QUERY,
        variables: { responseId }
      })

      cache.writeQuery({
        query: VI_MARKS_QUERY,
        variables: { responseId },
        data: {
          marks: {
            ...marks,
            graphql: [...marks.graphql, createMark]
          }
        }
      })
    }}
  >
    {(mutation, props) =>
      children({
        ...props,
        createMark: ({ text, secondsAfterStart }) =>
          mutation({
            variables: {
              input: {
                data: {
                  type: 'marks',
                  attributes: {
                    text,
                    secondsAfterStart
                  },
                  relationships: {
                    response: {
                      data: { type: 'responses', id: responseId }
                    }
                  }
                }
              }
            },
            optimisticResponse: {
              createMark: {
                __typename: 'VIMarks',
                type: 'marks',
                id: new Date().getTime().toString(),
                text,
                secondsAfterStart
              }
            }
          })
      })
    }
  </Mutation>
)

export default CreateMark
