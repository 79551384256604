import { useEffect } from 'react'
import debounce from 'lodash/debounce'

const useDebounce = (callback = () => {}, deps = [], wait = 300, options) => {
  useEffect(() => {
    const debounced = debounce(callback, wait, options)
    debounced()
    return debounced.cancel
  }, deps)
}

export default useDebounce
