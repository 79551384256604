import React, { useContext } from 'react'
import NotificationsContext from 'components/Notifications/Context'

const withNotifier = Component => props => {
  const value = useContext(NotificationsContext)

  return <Component {...props} {...value} />
}

export default withNotifier
