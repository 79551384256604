import React, { useState, useContext } from 'react'
import {
  css,
  styled,
  borderRadius,
  color,
  gridColumnGap,
  gridRowGap,
  lineHeight,
  marginTop,
  marginRight,
  padding,
  paddingTop
} from '@fv/design-tokens'
import { formatTime } from 'utils/time'
import DropdownMenu from 'components/DropdownMenu'
import MarkForm from './MarkForm'
import DeleteMarkModal from './DeleteMarkModal'
import VideoControllerContext from 'components/VideoPlayer/VideoControllerContext'
import PlaybackToggle from 'components/AdvancedEditor/PlaybackToggle'
import CreateClipButton from 'components/AdvancedEditor/CreateClipButton'
import MoreButton from 'components/AdvancedEditor/MoreButton'
import Tooltip from 'components/Tooltip'

const sharedOpacity = css`
  transition: opacity 0.15s;
  opacity: 0;
`

const Controls = styled.div`
  grid-area: controls;
  ${sharedOpacity};
`

const Actions = styled.div`
  grid-area: actions;
  ${sharedOpacity};
  display: flex;

  button {
    ${marginRight('xs')};
  }
`

const Container = styled.div`
  position: relative;
  ${borderRadius('m')};
  ${padding('s')};
  ${paddingTop('xs')};

  display: grid;
  grid-template-areas:
    'divider divider divider'
    'controls textContent actions';
  grid-template-columns: auto 1fr auto;
  ${gridColumnGap('m')};
  ${gridRowGap('xs')};
  justify-content: left;

  transition: background 0.15s;

  &:hover {
    ${Controls}, ${Actions} {
      opacity: 1;
    }
  }
`

const Text = styled.div`
  grid-area: textContent;
  ${lineHeight('m')};
`

const Timestamp = styled.span`
  ${color('textLt')};
`

const Comment = styled.p`
  ${marginTop('xxs')}
`

const Mark = ({
  handleDelete,
  handleSubmit,
  mark,
  mark: { secondsAfterStart, text },
  videoLength,
  canEdit
}) => {
  const [editMode, setEditMode] = useState(false)
  const { playing, seekAndPlay } = useContext(VideoControllerContext)

  return editMode ? (
    <MarkForm
      {...mark}
      videoLength={videoLength}
      handleSubmit={args => {
        handleSubmit(args)
        setEditMode(false)
      }}
      handleCancel={() => setEditMode(false)}
    />
  ) : (
    <Container>
      <Controls>
        <Tooltip placement="left" render={() => 'Play'}>
          {({ show, hide, ref }) => (
            <PlaybackToggle
              onClick={() => seekAndPlay(secondsAfterStart)}
              onMouseOver={show}
              onMouseOut={hide}
              ref={ref}
              playing={playing}
            />
          )}
        </Tooltip>
      </Controls>
      <Text>
        <Timestamp>{formatTime(secondsAfterStart)}</Timestamp>
        <Comment>{text}</Comment>
      </Text>
      {canEdit && (
        <Actions>
          <Tooltip placement="left" render={() => 'Create clip'}>
            {({ show, hide, ref }) => (
              <CreateClipButton
                startTime={secondsAfterStart}
                endTime={secondsAfterStart + 4}
                onMouseOver={show}
                onMouseOut={hide}
                ref={ref}
              />
            )}
          </Tooltip>
          <DropdownMenu
            align="right"
            trigger={(toggle, open) => (
              <MoreButton
                aria-label="More"
                aria-pressed={open}
                onClick={toggle}
                type="button"
              />
            )}
          >
            <DropdownMenu.Item onClick={() => setEditMode(true)}>
              Edit
            </DropdownMenu.Item>
            <DeleteMarkModal {...mark} onDelete={handleDelete}>
              {({ show }) => (
                <DropdownMenu.Item onClick={show}>Delete</DropdownMenu.Item>
              )}
            </DeleteMarkModal>
          </DropdownMenu>
        </Actions>
      )}
    </Container>
  )
}

export default Mark
