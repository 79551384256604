import React from 'react'
import AnalysisContainer from './AnalysisContainer'

const Analysis = ({ Component, data = [] }) => (
  <AnalysisContainer>
    {data.map((item, idx) => (
      <Component key={idx} {...item} />
    ))}
  </AnalysisContainer>
)

export default Analysis
