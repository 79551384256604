import React from 'react'
import Dialog from 'components/Dialog'

const PopoverDialog = ({ hide, popoverTitle, popoverBody, children }) => (
  <Dialog>
    <Dialog.Header onClose={hide}>
      <Dialog.Title>{popoverTitle}</Dialog.Title>
    </Dialog.Header>
    <Dialog.Body>{popoverBody ? popoverBody : children}</Dialog.Body>
  </Dialog>
)

export default PopoverDialog
