import React from 'react'
import Svg from 'components/Svg'

const EditIcon = props => (
  <Svg viewBox="0 0 18 18" {...props}>
    <title>edit</title>
    <g>
      <path d="M5.2245,16.48725 L0.375,17.625 L1.51275,12.77625 L13.473,0.8145 C13.754322,0.53306399 14.135945,0.3749453 14.533875,0.3749453 C14.931805,0.3749453 15.313428,0.53306399 15.59475,0.8145 L17.18625,2.40525 C17.467686,2.68657202 17.6258047,3.06819498 17.6258047,3.466125 C17.6258047,3.86405502 17.467686,4.24567798 17.18625,4.527 L5.2245,16.48725 Z" />
      <path d="M16.6575,5.05875 L12.94275,1.34475" />
      <path d="M15.58725,6.129 L11.8815,2.40525" />
      <path d="M5.4135,16.29975 L1.701,12.58725" />
    </g>
  </Svg>
)

export default EditIcon
