import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { ReelVideoClip_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/ReelVideoClip.fragments'
import { CopyClipModal_reelVideoClip } from 'components/AdvancedEditor/ReelBuilder/CopyClipModal/CopyClipModal.fragments'
import VI_REEL_QUERY from 'components/AdvancedEditor/ReelBuilder/reel.query.js'

export const VI_CREATE_REEL_VIDEO_CLIP = gql`
  mutation CreateReelVideoClipMutation($input: CreateVideoClipInput!) {
    createReelVideoClip(input: $input)
      @jsonapi(path: "/reel_video_clips", method: "POST") {
      id
      ...ReelVideoClip_reelVideoClip
      ...CopyClipModal_reelVideoClip
    }
  }

  ${ReelVideoClip_reelVideoClip}
  ${CopyClipModal_reelVideoClip}
`

export const buildCreateReelVideoClipVariables = ({
  name,
  startTime,
  endTime,
  reelId,
  videoClipId
}) => ({
  input: {
    data: {
      type: 'reel_video_clips',
      attributes: {
        name,
        startTime,
        endTime
      },
      relationships: {
        reel: {
          data: {
            type: 'reels',
            id: reelId
          }
        },
        content: {
          data: {
            type: 'video_clips',
            id: videoClipId
          }
        }
      }
    }
  }
})

const useCreateReelVideoClipMutation = () => {
  const [mutate, result] = useMutation(VI_CREATE_REEL_VIDEO_CLIP)

  const createReelVideoClip = ({
    name,
    startTime,
    endTime,
    videoClipId,
    reelId
  }) =>
    mutate({
      variables: buildCreateReelVideoClipVariables({
        name,
        startTime,
        endTime,
        videoClipId,
        reelId
      }),
      optimisticResponse: {
        createReelVideoClip: {
          __typename: 'VIReelVideoClips',
          type: 'reel_video_clips',
          id: new Date().getTime(),
          name,
          startTime,
          endTime,
          content: {
            __typename: 'VIVideoClips',
            id: videoClipId,
            video: {
              __typename: 'VIVideos',
              id: 0,
              response: null
            }
          },
          videoLength: endTime - startTime,
          assetUrl: ''
        }
      },
      update: (cache, result) => {
        const {
          data: { createReelVideoClip }
        } = result

        try {
          const { reel } = cache.readQuery({
            query: VI_REEL_QUERY,
            variables: { reelId }
          })

          cache.writeQuery({
            query: VI_REEL_QUERY,
            variables: { reelId },
            data: {
              reel: {
                ...reel,
                reelVideoClips: [...reel.reelVideoClips, createReelVideoClip]
              }
            }
          })
        } catch (e) {
          // The reel from the readQuery might not be fetched yet
        }
      }
    })

  return [createReelVideoClip, result]
}

export default useCreateReelVideoClipMutation
