import React from 'react'
import {
  styled,
  marginLeft,
  marginTop,
  paddingLeft,
  paddingRight
} from '@fv/design-tokens'
import ChevronDownSmIcon from 'components/icons/ChevronDownSm'
import ChevronUpSmIcon from 'components/icons/ChevronUpSm'
import Button from 'components/Button'

const StyledButton = styled(Button)`
  ${marginTop('xs')};
  ${paddingLeft('xs')};
  ${paddingRight('xs')};
  > svg:last-of-type {
    height: 12px;
    ${marginLeft('xxs')};
  }
`

const ShowMoreButton = ({ showMore, ...rest }) => (
  <StyledButton small ghost {...rest}>
    {showMore ? 'less' : 'more'}
    {showMore ? <ChevronUpSmIcon /> : <ChevronDownSmIcon />}
  </StyledButton>
)

export default ShowMoreButton
