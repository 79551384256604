import React from 'react'
import FeatherSvg from 'components/FeatherSvg'

const CheckCircle = props => (
  <FeatherSvg {...props}>
    <title>{props.title || 'check-circle'}</title>
    <circle cx="12" cy="12" r="10" />
    <polyline points="17 9 11 16.01 8 13.01" />
  </FeatherSvg>
)

export default CheckCircle
