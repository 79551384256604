import { styled, borderRadius, boxShadow } from '@fv/design-tokens'
import { black, white } from 'styles/color'

const Container = styled.div`
  color: ${black};
  background: ${white};
  width: 375px;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${borderRadius('m')};
  ${boxShadow('raised')};
`

Container.displayName = 'Notification.Container'

export default Container
