import React, { forwardRef } from 'react'
import { styled, color } from '@fv/design-tokens'
import { electricBlue } from 'styles/color'
import PauseSmIcon from 'components/icons/PauseSm'
import Button from 'components/Button'
import VideoControlsPlayIcon from 'components/icons/VideoControlsPlay'

const StyledVideoControlsPlayIcon = styled(VideoControlsPlayIcon)`
  vertical-align: text-top;
  stroke: ${electricBlue};
  fill: none;
  color: transparent;
`

const ToggleButton = styled(Button)`
  border-radius: 0;
  &:hover {
    ${color('buttonPlayBackgroundHover')};
  }
`

const PlaybackToggle = forwardRef(
  ({ isActivePlayer, playing, ...rest }, ref) => (
    <ToggleButton ref={ref} isActivePlayer={isActivePlayer} {...rest}>
      {playing && isActivePlayer ? (
        <PauseSmIcon pointerEvents="none" />
      ) : (
        <StyledVideoControlsPlayIcon pointerEvents="none" />
      )}
    </ToggleButton>
  )
)

export default PlaybackToggle
