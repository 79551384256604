import React from 'react'
import { styled } from '@fv/design-tokens'
import IconContainer from './IconContainer'
import InfoIcon from 'components/icons/Info'
import AlertCircleIcon from 'components/icons/AlertCircle'
import CheckCircleIcon from 'components/icons/CheckCircle'
import AlertTriangleIcon from 'components/icons/AlertTriangle'
import { green, orange, electricBlue, red } from 'styles/color'

const StyledInfoIcon = styled(InfoIcon)`
  color: ${electricBlue};
`
const StyledAlertCircleIcon = styled(AlertCircleIcon)`
  color: ${red};
`
const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: ${green};
`
const StyledAlertTriangleIcon = styled(AlertTriangleIcon)`
  color: ${orange};
`

const ICON_SIZE = '1.75rem'

const Icon = ({ kind, renderIcon }) => {
  const props = { width: ICON_SIZE, height: ICON_SIZE }

  if (typeof renderIcon === 'function') {
    return <IconContainer>{renderIcon(props)}</IconContainer>
  }

  switch (kind) {
    case 'success':
      return (
        <IconContainer>
          <StyledCheckCircleIcon title="success" {...props} />
        </IconContainer>
      )
    case 'warning':
      return (
        <IconContainer>
          <StyledAlertTriangleIcon title="warning" {...props} />
        </IconContainer>
      )
    case 'info':
      return (
        <IconContainer>
          <StyledInfoIcon title="info" {...props} />
        </IconContainer>
      )
    case 'danger':
      return (
        <IconContainer>
          <StyledAlertCircleIcon title="danger" {...props} />
        </IconContainer>
      )
    default:
      return null
  }
}

Icon.displayName = 'Notification.Icon'

export default Icon
