import moment from 'moment'

export const isProcessing = time =>
  time && moment() < moment(time).add(4, 'hours')

export const PROCESSING_STATUSES = {
  NOT_PROCESSED: 'NOT_PROCESSED',
  ERROR: 'ERROR',
  PROCESSING: 'PROCESSING'
}

export const chooseProcessingStatus = ({ status, sentToInsightsAt }) => {
  if (status === 'Active' && !sentToInsightsAt) {
    return PROCESSING_STATUSES.NOT_PROCESSED
  } else if (
    status === 'Uploading' ||
    (sentToInsightsAt && isProcessing(sentToInsightsAt))
  ) {
    return PROCESSING_STATUSES.PROCESSING
  }
  return PROCESSING_STATUSES.ERROR
}

const PROCESSING_MESSAGES = Object.freeze({
  NOT_PROCESSED: 'NLP not available',
  ERROR: 'Something went wrong',
  PROCESSING: 'Working on it'
})

export const chooseProcessingMessage = video =>
  PROCESSING_MESSAGES[chooseProcessingStatus(video)]

export const ANALYSIS_EVENTS = Object.freeze([
  'transcription',
  'highlightAnalysis',
  'entities',
  'keyPhrases'
])

export const HUMAN_TRANSCRIPT_TYPE = 'HUMAN'
export const MACHINE_TRANSCRIPT_TYPE = 'MACHINE'

export const HUMAN_ANALYSIS_SHAPE = Object.freeze({
  transcriptType: HUMAN_TRANSCRIPT_TYPE
})

export const MACHINE_ANALYSIS_SHAPE = Object.freeze({
  transcriptType: MACHINE_TRANSCRIPT_TYPE
})

export const getAnalysisStatus = (videoAnalysis, analysisEvent) => {
  if (!videoAnalysis['updatedAt']) return 'unprocessed'

  const completed = videoAnalysis[`${analysisEvent}CompletedAt`]
  const errored = videoAnalysis[`${analysisEvent}ErroredAt`]
  const started = videoAnalysis[`${analysisEvent}StartedAt`]

  if (completed) return 'completed'
  if (errored) return 'errored'
  if (started) return 'processing'
  return 'pending'
}

export const getOverallStatus = videoAnalysis => {
  if (!videoAnalysis['updatedAt']) return 'unprocessed'

  const statuses = ANALYSIS_EVENTS.map(analysisEvent =>
    getAnalysisStatus(videoAnalysis, analysisEvent)
  )

  if (statuses.every(status => status === 'completed')) return 'completed'
  if (statuses.some(status => status === 'errored')) return 'errored'
  if (statuses.some(status => status === 'processing')) return 'processing'
  return 'pending'
}
