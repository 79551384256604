import { styled, css } from '@fv/design-tokens'

const visibility = ({ open }) =>
  css`
    visibility: ${open ? 'visible' : 'hidden'};
  `

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  max-width: 100%;
  z-index: 1020;
  ${visibility};
`

Container.displayName = 'Popover.Container'

export default Container
