import gql from 'graphql-tag'

export const ReelSelectorOption_reel = gql`
  fragment ReelSelectorOption_reel on VIReels {
    id
    name
    thumbnailUrl
    updateDt
    clipCount
  }
`
