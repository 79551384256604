import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Redirect } from 'react-router-dom'
import { useParams } from 'react-router'
import { DesignTokenProvider } from '@fv/design-tokens'
import AdvancedEditor from 'components/AdvancedEditor'
import PageLoader from 'components/PageLoader'
import VI_VIDEO_QUERY from './video.query'

const VideoRoute = () => {
  const { videoId } = useParams()
  const { data, loading, error } = useQuery(VI_VIDEO_QUERY, {
    variables: { responseId: videoId }
  })

  if (error) {
    return (
      <Redirect
        to={{
          pathname: '/a/404',
          state: {
            message: 'Resource not found.'
          }
        }}
      />
    )
  }

  return (
    <DesignTokenProvider currentThemeType="light">
      {loading ? (
        <PageLoader />
      ) : (
        <AdvancedEditor videoId={videoId} data={data} />
      )}
    </DesignTokenProvider>
  )
}

export default VideoRoute
