import gql from 'graphql-tag'
import { OrderHumanTranscriptionModal_video } from './OrderHumanTranscriptionModal/fragments'

export const AdvancedEditor_videoClips = gql`
  fragment AdvancedEditor_videoClips on VIVideoClips {
    id
    startTime
    endTime
  }
`

export const AdvancedEditor_videoTranscripts = gql`
  fragment AdvancedEditor_videoTranscripts on VIVideoTranscripts {
    id
    transcript
    timestamps
    transcriptType
  }
`

export const AdvancedEditor_video = gql`
  fragment AdvancedEditor_video on VIVideos {
    id
    length
    highlightReelJwt
    sentToInsightsAt
    status
    language
    languageDescription
    sourceUrl
    videoTranscript {
      ...AdvancedEditor_videoTranscripts
    }
    videoClips {
      ...AdvancedEditor_videoClips
    }
    ...OrderHumanTranscriptionModal_video
  }

  ${AdvancedEditor_videoTranscripts}
  ${AdvancedEditor_videoClips}
  ${OrderHumanTranscriptionModal_video}
`

export const AdvancedEditor_response = gql`
  fragment AdvancedEditor_response on VIVideoResponses {
    id
    assetUrl
    title
    tileImageUrl
    transcriptionStatus
    transcriptionType
    videos {
      ...AdvancedEditor_video
    }
    project {
      id
      permissions
    }
  }

  ${AdvancedEditor_video}
`
