/*
 * NOTE: Prior to React 16, props/attributes that were passed to HTML elements
 * would coerce a boolean value into an appropriate string/undefined value.
 * This has been removed from React. We use the HTML5 pattern with our
 * components; the presence of the prop/attribute indicates a `true` value,
 * while the omission indicates a `false` value. Since React is no longer
 * infering intent and treating any "truthy" value as `true`, `'false'` or
 * `null` would return a false positive. `undefined` gives us the expected
 * behavior. I use the string `'true'` to indicate intent, but `'any value'` is
 * equally valid.
 */

const hasAttr = prop => (prop ? 'true' : undefined)
export const notHasAttr = prop => (prop ? undefined : 'true')

export default hasAttr
