import React from 'react'
import {
  ErrorContainer,
  ErrorContent,
  ErrorFooter,
  ErrorPage
} from 'components/ErrorPage'
import { ButtonLink } from 'components/Button'

const Unauthorized = () => (
  <ErrorPage>
    <ErrorContainer>
      <h1>Unauthorized</h1>
      <ErrorContent>Please verify you have access and try again.</ErrorContent>
      <ErrorFooter>
        <ButtonLink ghost to="/">
          Home
        </ButtonLink>
      </ErrorFooter>
    </ErrorContainer>
  </ErrorPage>
)

export default Unauthorized
