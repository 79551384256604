import { useEffect, useContext } from 'react'
import NotificationsContext from 'components/Notifications/Context'

let offlineNotificationId = null

const Network = ({ network, setNetwork }) => {
  const { notify, removeNotification } = useContext(NotificationsContext)

  useEffect(() => {
    const updateOnlineStatus = event => {
      const isOnline = event.type === 'online'

      if (isOnline) {
        if (offlineNotificationId) {
          removeNotification(offlineNotificationId)
          offlineNotificationId = null
        }

        notify('success', 'You are connected to the internet')
      } else {
        const notificationId = notify(
          'danger',
          'You are not connected to the internet',
          {
            sticky: true,
            dismissable: false
          }
        )
        offlineNotificationId = notificationId
      }
      setNetwork({ ...network, online: isOnline })
    }

    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])

  return null
}

export const defaultState = {
  online: true
}

export default Network
