import React, { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { styled, marginTop } from '@fv/design-tokens'
import Loader from 'components/Loader'
import VI_REEL_QUERY from './reel.query'
import ReelBuilder from './ReelBuilder'
import ReelsIndex from './ReelsIndex'
import VI_WORKSPACES_QUERY from './vi_workspaces.query'
import VI_CURRENT_USER_REELS_QUERY from './ReelsIndex/CurrentUserReels.query'
import ClipsPreviewProvider from './ClipsPreviewProvider'
import CurrentUserContext from 'components/CurrentUser/Context'
import useCreateWorkspaceMutation from 'hooks/mutations/useCreateWorkspace'
import useQueryParams from 'hooks/useQueryParams'
import { FINALIZING_STATUSES } from './utils'

const POLL_INTERVAL = process.env.REACT_APP_ENV === 'development' ? 60000 : 5000

const StyledLoader = styled(Loader)`
  display: flex;
  justify-content: center;
  ${marginTop('s')};
`

const useFindOrCreateWorkspace = ({ userId, projectId, companyId }) => {
  const [
    createWorkspace,
    { loading: loadingCreateWorkspace }
  ] = useCreateWorkspaceMutation()

  const {
    data: { workspaces: [workspace] = [] } = {},
    loading: loadingWorkspaces
  } = useQuery(VI_WORKSPACES_QUERY, {
    variables: { userId, projectId }
  })

  useEffect(() => {
    if (!loadingWorkspaces && !workspace) {
      createWorkspace({ userId, projectId, companyId })
    }
  }, [loadingWorkspaces, workspace])

  return {
    workspace,
    loading: loadingCreateWorkspace || loadingWorkspaces
  }
}

const isFinalizing = reel =>
  reel && FINALIZING_STATUSES.includes(reel.publishStatus)

const ReelBuilderContainer = ({ projectId, companyId, ...props }) => {
  const { viewer } = useContext(CurrentUserContext)
  const [queryParams, setQueryParams] = useQueryParams()
  const reelId = queryParams.get('reel')
  const reelBuilderPanel = queryParams.get('reelBuilderPanel')

  const { workspace, loading: loadingWorkspace } = useFindOrCreateWorkspace({
    userId: viewer.id,
    projectId,
    companyId
  })

  const {
    data: { reels: { graphql: reels = [] } = {} } = {},
    loading: loadingCurrentUserReels
  } = useQuery(VI_CURRENT_USER_REELS_QUERY, {
    variables: { projectId, userId: viewer.id }
  })

  useEffect(() => {
    if (!reelId && !loadingCurrentUserReels && reels) {
      const [lastUpdatedReel] = reels

      if (lastUpdatedReel) {
        setQueryParams({ reel: lastUpdatedReel.id })
      }
    }
  }, [loadingCurrentUserReels])

  const {
    data: { reel } = {},
    loading: loadingReel,
    startPolling,
    stopPolling
  } = useQuery(VI_REEL_QUERY, {
    variables: { reelId },
    skip: !reelId || loadingWorkspace,
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (isFinalizing(reel)) {
      startPolling(POLL_INTERVAL)
    } else {
      stopPolling()
    }
  }, [reel])

  return loadingReel ||
    loadingWorkspace ||
    loadingCurrentUserReels ||
    !workspace ? (
    <StyledLoader data-testid="reelbuilder-loader" />
  ) : reelBuilderPanel === 'reels' ? (
    <ReelsIndex projectId={projectId} />
  ) : (
    <ClipsPreviewProvider>
      <ReelBuilder
        {...props}
        projectId={projectId}
        reel={reel}
        workspace={workspace}
      />
    </ClipsPreviewProvider>
  )
}

export default ReelBuilderContainer
