import React, { useState, useRef } from 'react'
import { useQuery } from '@apollo/react-hooks'
import {
  styled,
  background,
  fontWeight,
  padding,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight
} from '@fv/design-tokens'
import Popover from 'components/Popover'
import CreateMark from 'components/mutations/CreateMark'
import UpdateMark from 'components/mutations/UpdateMark'
import DeleteMark from 'components/mutations/DeleteMark'
import PopoverDialog from 'components/PopoverDialog'
import Mark from './Mark'
import MarkForm from './MarkForm'
import Button from 'components/Button'
import Plus from 'components/icons/Plus'
import VI_MARKS_QUERY, { VI_DYNAMIC_MARKS_QUERY } from './marks.query'
import { VideoControllerConsumer } from 'components/VideoPlayer/VideoControllerContext'
import { floor } from 'utils/math'
import InfoButton from 'components/InfoButton'
import Tooltip from 'components/Tooltip'
import { getNextPagePath } from 'utils'
import _Loader from 'components/Loader'
import { flexCenterContent } from 'styles/positioning'

const Loader = styled(_Loader)`
  ${flexCenterContent};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${paddingTop('s')};
  ${paddingRight('m')};
  ${paddingBottom('s')};
  ${paddingLeft('m')};
`

const MarksContainer = styled.div`
  flex: 1;
  overflow-y: scroll;
  ${paddingTop('m')};
  ${background('background')};
`

const MarksInnerWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
`

const Tip = styled.span`
  ${fontWeight('bold')};
  display: flex;
  align-items: center;
`

const NoMarksFound = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  ${padding('m')};
`

const Marks = ({ responseId, videoLength, canEdit }) => {
  const [newMark, setNewMark] = useState(null)
  const marksContainer = useRef(null)
  const {
    data: {
      marks: {
        graphql: marks = [],
        jsonapi: { links: { next } = {} } = {}
      } = {}
    } = {},
    loading,
    fetchMore
  } = useQuery(VI_MARKS_QUERY, {
    variables: { responseId },
    skip: !responseId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  })

  return (
    <Container>
      <Header>
        <Popover
          portal
          placement="bottom"
          render={({ hide }) => (
            <PopoverDialog
              hide={hide}
              popoverTitle="Marks"
              popoverBody="A collection of user-selected video segments. Marks can be used to flag parts of the video containing important or relevant information. "
            />
          )}
        >
          {({ toggle, ref }) => (
            <Tip>
              What is this?
              <InfoButton ghost square small ref={ref} onClick={toggle} />
            </Tip>
          )}
        </Popover>
        {canEdit && (
          <VideoControllerConsumer>
            {({ currentTime }) => (
              <Tooltip placement="left" render={() => 'Create mark'}>
                {({ show, hide, ref }) => (
                  <Button
                    small
                    square
                    disabled={loading}
                    onMouseOver={show}
                    onMouseOut={hide}
                    ref={ref}
                    onClick={() => {
                      setNewMark({
                        text: '',
                        secondsAfterStart: floor(currentTime)
                      })
                      marksContainer.current.scrollTop = 0
                    }}
                  >
                    <Plus />
                  </Button>
                )}
              </Tooltip>
            )}
          </VideoControllerConsumer>
        )}
      </Header>
      <MarksContainer
        ref={marksContainer}
        onScroll={e => {
          const { clientHeight, scrollHeight, scrollTop } = e.target

          if (scrollTop + clientHeight + 150 > scrollHeight) {
            if (!next || loading) {
              return
            }

            fetchMore({
              query: VI_DYNAMIC_MARKS_QUERY,
              variables: {
                path: getNextPagePath(next)
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev

                return {
                  ...prev,
                  marks: {
                    ...fetchMoreResult.marks,
                    graphql: [
                      ...prev.marks.graphql,
                      ...fetchMoreResult.marks.graphql
                    ]
                  }
                }
              }
            })
          }
        }}
      >
        <MarksInnerWrapper>
          {newMark && (
            <CreateMark responseId={responseId}>
              {({ createMark }) => (
                <MarkForm
                  isNew
                  videoLength={videoLength}
                  handleSubmit={args => {
                    createMark(args)
                    setNewMark(null)
                  }}
                  handleCancel={() => setNewMark(null)}
                  {...newMark}
                />
              )}
            </CreateMark>
          )}
          <DeleteMark responseId={responseId}>
            {({ deleteMark }) => (
              <UpdateMark responseId={responseId}>
                {({ updateMark }) =>
                  marks.map(mark => (
                    <Mark
                      key={mark.id}
                      videoLength={videoLength}
                      handleDelete={() => deleteMark(mark.id)}
                      handleSubmit={args =>
                        updateMark({ id: mark.id, ...args })
                      }
                      mark={mark}
                      canEdit={canEdit}
                    />
                  ))
                }
              </UpdateMark>
            )}
          </DeleteMark>
          {!marks.length && !loading && (
            <NoMarksFound>No marks provided</NoMarksFound>
          )}
          {loading && <Loader />}
        </MarksInnerWrapper>
      </MarksContainer>
    </Container>
  )
}

export default Marks
