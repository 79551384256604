import React from 'react'
import { styled, css } from '@fv/design-tokens'

const Tabs = styled('div')`
  position: relative;
`

Tabs.Controls = styled('div')`
  display: flex;
  margin-top: 1.25rem;
`

const TabLinksContainer = styled('div')`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Tab = styled('div')`
  width: 16px;
  height: 16px;
  margin: 0.5rem;
  background-color: #cbd2d6;
  border-radius: 50%;
  transition: background 0.15s linear, box-shadow 0.15s linear;

  &:hover {
    cursor: pointer;
    background-color: #124d89;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  }

  ${({ active }) =>
    active &&
    css`
      &,
      &:hover {
        background: #124d89;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      }
    `};
`

const TabLink = ({ currentState, state, children, ...rest }) => (
  <Tab active={currentState === state} {...rest}>
    {children}
  </Tab>
)

Tabs.Links = ({ currentState, stateMap, onSelect }) => (
  <TabLinksContainer>
    {stateMap.map((state, idx) => (
      <TabLink
        currentState={currentState}
        state={state}
        key={idx}
        onClick={() => onSelect(state)}
      />
    ))}
  </TabLinksContainer>
)

export default Tabs
