import React, { createContext, useState } from 'react'
import Network, { defaultState } from './Network'

export const MonitContext = createContext()

const Monit = ({ children }) => {
  const [network, setNetwork] = useState(defaultState)

  return (
    <MonitContext.Provider
      value={{
        network
      }}
    >
      <Network network={network} setNetwork={setNetwork} />
      {children}
    </MonitContext.Provider>
  )
}

export default Monit
